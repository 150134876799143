import React, { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable, { MTableAction } from 'material-table';
import { Dictionary } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { userSelectors } from 'features/users/usersSlice';
import { accessRightSelectors } from 'features/accessRights/accessRightsSlice';
import { resourceSelectors } from 'features/resources/resourcesSlice';
import ListTooltip from 'features/common/ListTooltip';
import { useRouteMatch } from 'react-router-dom';
import NavigationButton from 'features/common/NavigationButton';
import { Customer } from 'features/fleet/types';
import { locationSelectors } from 'features/fleet/fleetSlice';
import { parentGroupSelectors, createParentGroup } from '../groups/parentGroupsSlice';
import { groupSelectors, deleteGroup } from '../groups/groupsSlice';
import { Group, GroupFamilyEnum, GroupTypeEnum } from '../groups/types';
import GroupEditDialog from '../groups/GroupEditDialog';

type TableDataEntry = {
  externalId: string,
  name: string,
  description: string,
  permissions: Array<string>,
  users: Array<string>,
  groupType: GroupTypeEnum,
};

type EditDialogState = {
  open: boolean,
  group?: Group,
};

type CustomerGroupListProps = {
  customer: Customer
};

const CustomerGroupList = (props: CustomerGroupListProps) => {
  const { customer } = props;
  const { url } = useRouteMatch();

  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const groups = useSelector(groupSelectors.selectAll);
  const accessRightsLookup = useSelector(accessRightSelectors.selectEntities);
  const resourcesLookup = useSelector(resourceSelectors.selectEntities);
  const parentGroups = useSelector(parentGroupSelectors.selectAll);
  const users = useSelector(userSelectors.selectAll);
  const locationLookup = useSelector(locationSelectors.selectEntities);

  const parentGroup = useMemo(
    () => parentGroups.find((pg) => pg.companyCode === customer.code),
    [customer.code, parentGroups],
  );

  const customerGroups = useMemo(
    () => groups.filter((g) => g.parentGroupExternalId === parentGroup?.externalId),
    [groups, parentGroup],
  );

  // creating parent group if it's not created (on mount only)
  useEffect(() => {
    const createParentGroupFunc = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        dispatch(createParentGroup({
          companyCode: customer.code,
          name: customer.name,
          description: `${customer.name} groups.`,
        }, accessToken));
      } catch {
        // nvm...
      }
    };
    if (!parentGroup) {
      createParentGroupFunc();
    }
  }, [customer.name, customer.code, dispatch, getAccessTokenSilently, parentGroup]);

  const groupUsersLookup = useMemo(() => {
    const result: Dictionary<Array<string>> = {};

    // registering all groups in the lookup
    customerGroups.forEach((group) => {
      result[group.externalId] = [];
    });

    users.forEach((user) => {
      user.groups.forEach((group) => {
        result[group.externalId]?.push(user.name);
      });
    });

    return result;
  }, [customerGroups, users]);

  const tableData = useMemo(() => customerGroups.map((g) => {
    const now = new Date();
    const permissions = g.permissions
      .filter(({ validFrom, validTo }) => {
        // filter no longer valid permissions
        const fromValid = !validFrom || new Date(validFrom) < now;
        const toValid = !validTo || new Date(validTo) > now;
        return fromValid && toValid;
      })
      .map((p) => {
        // create permission string
        const accessRightName = accessRightsLookup[p.accessRightExternalId]?.name || 'Unknown';
        const resourceName = resourcesLookup[p.resourceExternalId]?.name || 'Unknown';
        const locationInfo = p.localityCode ? ` (${locationLookup[p.localityCode]?.name || ''})` : '';

        return `${resourceName}:${accessRightName}${locationInfo}`;
      });
    const result: TableDataEntry = {
      name: g.name,
      description: g.description,
      externalId: g.externalId,
      users: groupUsersLookup[g.externalId] || [],
      groupType: g.groupType,
      permissions,
    };
    return result;
  }), [customerGroups, groupUsersLookup, accessRightsLookup, resourcesLookup, locationLookup]);

  const [editDialogState, setEditDialogState] = useState<EditDialogState>({ open: false });
  const handleEditGroup = (rowData: TableDataEntry) => {
    const group = customerGroups.find((g) => g.externalId === rowData.externalId);
    if (group) {
      setEditDialogState({ open: true, group });
    }
  };
  const handleCreateGroup = () => setEditDialogState({ open: true });
  const handleEditDialogClosed = () => setEditDialogState({ open: false });

  const handleDeleteGroup = async (externalId: string) => {
    const accessToken = await getAccessTokenSilently();
    await dispatch(deleteGroup(externalId, accessToken));
  };

  return (
    <>
      {editDialogState.open && (
        <GroupEditDialog
          groupType={GroupFamilyEnum.CustomerGroup}
          onClose={handleEditDialogClosed}
          group={editDialogState.group}
          parentGroupExternalId={parentGroup?.externalId}
        />
      )}
      <MaterialTable
        title="Groups"
        data={tableData}
        columns={[
          {
            title: 'Name',
            field: 'name',
            type: 'string',
          },
          {
            title: 'Description',
            field: 'description',
            type: 'string',
            width: '40%',
          },
          {
            title: 'Type',
            field: 'groupType',
            type: 'string',
          },
          {
            title: 'Users',
            render: (rowData) => <ListTooltip values={rowData.users} />,
          },
          {
            title: 'Permissions',
            render: (rowData) => <ListTooltip values={rowData.permissions} />,
          },
        ]}
        options={{
          pageSize: tableData.length <= 5 ? 5 : 10,
          pageSizeOptions: [5, 10, 25, 50],
          padding: 'dense',
        }}
        actions={[
          {
            icon: 'add_box',
            tooltip: 'Add',
            isFreeAction: true,
            onClick: handleCreateGroup,
          },
          {
            icon: 'open_in_new',
            tooltip: 'Open',
            onClick: () => { },
          },
          (rowData) => ({
            icon: 'edit',
            tooltip: 'Edit',
            disabled: rowData.groupType !== GroupTypeEnum.Custom,
            onClick: () => handleEditGroup(rowData),
          }),
        ]}
        editable={{
          onRowDelete: (rowData) => handleDeleteGroup(rowData.externalId),
          isDeletable: (rowData) => rowData.users.length === 0,
        }}
        localization={{
          body: {
            editRow: {
              deleteText: 'Are you sure you want to delete this group?',
            },
          },
        }}
        components={{
          Action: (actionProps: any) => {
            // navigation links should work as links
            if (actionProps?.action?.icon === 'open_in_new') {
              return (
                <NavigationButton
                  to={`${url}/groups/${actionProps?.data?.externalId}`}
                  tooltip={actionProps?.action?.tooltip}
                />
              );
            }
            // eslint-disable-next-line react/jsx-props-no-spreading
            return <MTableAction {...actionProps} />;
          },
        }}
      />
    </>
  );
};

export default React.memo(CustomerGroupList);
