import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { Auth0Provider } from '@auth0/auth0-react';
import { SnackbarProvider } from 'notistack';
import theme from './theme';
import App from './App';
import store from './app/store';
import appSettings from './app/appSettings';

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={appSettings.auth0.domain}
      clientId={appSettings.auth0.clientId}
      audience={appSettings.auth0.audience}
      scope={appSettings.auth0.scope}
      redirectUri={window.location.origin}
    >
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <SnackbarProvider anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          >
            <CssBaseline />
            <App />
          </SnackbarProvider>
        </Provider>
      </ThemeProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
