import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import clsx from 'clsx';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';

import HomeContainer from 'features/home/HomeContainer';
import { fetchUsers } from 'features/users/usersSlice';
import { fetchAccessRights } from 'features/accessRights/accessRightsSlice';
import { fetchGroups } from 'features/groups/groupsSlice';
import { fetchParentGroups } from 'features/groups/parentGroupsSlice';
import { fetchResources } from 'features/resources/resourcesSlice';
import { fetchResourceGroups } from 'features/resources/resourceGroupsSlice';
import ResourcesComponent from 'features/resources/ResourcesComponent';
import StingraySection from 'features/stingray/StingraySection';
import { fetchFleet } from 'features/fleet/fleetSlice';
import CustomerSection from 'features/customer/CustomerSection';
import ThirdPartySection from 'features/thirdparty/ThirdPartySection';
import MachineSection from 'features/machine/MachineSection';
import TestUsersSection from 'features/testusers/TestUsersSection';
import ToolsSection from 'features/tools/ToolsSection';
import useLayoutStyles from './useLayoutStyles';
import Navigation, { routeMap } from './Navigation';
import LoadingScreen from './LoadingScreen';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  content: {
    width: '100%',
    height: '100vh',
    maxHeight: '100vh',
    minHeight: 0,
    overflow: 'auto',
  },
}));

const Layout = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const classes = useStyles();
  const layoutClasses = useLayoutStyles();

  // auth admin data is highly relational
  // and in order to write cleaner code in components we do all the initial data fetching here
  // components won't be rendered until everything is fetched
  // other C_UD operations are handled by the components
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const f = async () => {
      setIsFetching(true);
      const accessToken = await getAccessTokenSilently();
      const tasks = [
        dispatch(fetchAccessRights(accessToken)),
        dispatch(fetchGroups(accessToken)),
        dispatch(fetchParentGroups(accessToken)),
        dispatch(fetchResources(accessToken)),
        dispatch(fetchResourceGroups(accessToken)),
        dispatch(fetchUsers(accessToken)),
        dispatch(fetchFleet(accessToken)),
      ];
      await Promise.all(tasks);
      setIsFetching(false);
    };

    f();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetching) {
    return <LoadingScreen />;
  }

  return (
    <div className={classes.root}>
      <Router>
        <Navigation />
        <div className={clsx(classes.content, layoutClasses.styledScroll)}>
          <Switch>
            <Route path="/" exact>
              <HomeContainer />
            </Route>
            <Route path={routeMap.stingray.baseUrl}>
              <StingraySection />
            </Route>
            <Route path={routeMap.customer.baseUrl}>
              <CustomerSection />
            </Route>
            <Route path={routeMap.third_party.baseUrl}>
              <ThirdPartySection />
            </Route>
            <Route path={routeMap.machine.baseUrl}>
              <MachineSection />
            </Route>
            <Route path={routeMap.testusers.baseUrl}>
              <TestUsersSection />
            </Route>
            <Route path={routeMap.resources.baseUrl} exact>
              <ResourcesComponent />
            </Route>
            <Route path={routeMap.tools.baseUrl}>
              <ToolsSection />
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  );
};

export default Layout;
