import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import { Dictionary } from 'lodash';
import { groupSelectors } from 'features/groups/groupsSlice';
import { accessRightSelectors } from 'features/accessRights/accessRightsSlice';
import ListTooltip from 'features/common/ListTooltip';
import { resourceGroupSelectors } from './resourceGroupsSlice';
import { resourceSelectors } from './resourcesSlice';

type TableDataEntry = {
  externalId: string,
  name: string,
  description?: string,
  groups: Array<string>,
  localitySpecific: boolean,
  resourceGroup: string,
};

const ResourceList = () => {
  const resources = useSelector(resourceSelectors.selectAll);
  const accessRightsLookup = useSelector(accessRightSelectors.selectEntities);
  const resourceGroupsLookup = useSelector(resourceGroupSelectors.selectEntities);

  const groups = useSelector(groupSelectors.selectAll);

  const resourceAccessLookup = useMemo(() => {
    const result: Dictionary<Array<string>> = {};

    // register resources in the dictionary by externalId
    resources.forEach((r) => {
      result[r.externalId] = [];
    });

    const now = new Date();
    groups.forEach((group) => {
      const { name: groupName } = group;
      group.permissions.forEach((permission) => {
        const { accessRightExternalId, resourceExternalId, validTo } = permission;

        if (validTo && new Date(validTo) <= now) {
          return;
        }

        const accessRightName = accessRightsLookup[accessRightExternalId]?.name;
        result[resourceExternalId]?.push(`${groupName}:${accessRightName}`);
      });
    });

    return result;
  }, [accessRightsLookup, groups, resources]);

  const tableData = useMemo(
    () => resources.map<TableDataEntry>((r) => ({
      name: r.name,
      description: r.description,
      externalId: r.externalId,
      localitySpecific: r.localitySpecific,
      resourceGroup: resourceGroupsLookup[r.resourceGroupExternalId]?.name || '',
      groups: resourceAccessLookup[r.externalId] || [],
    })),
    [resourceAccessLookup, resourceGroupsLookup, resources],
  );

  return (
    <>
      <MaterialTable
        data={tableData}
        columns={[
          {
            title: 'Name',
            field: 'name',
            type: 'string',
          },
          {
            title: 'Locality specific',
            field: 'localitySpecific',
            type: 'boolean',
          },
          {
            title: 'Description',
            field: 'description',
            type: 'string',
            width: '50%',
          },
          {
            title: 'Groups',
            render: (rowData) => <ListTooltip values={rowData.groups} />,
          },
          {
            title: 'Group',
            field: 'resourceGroup',
            defaultGroupOrder: 0,
            defaultGroupSort: 'asc',
          },
        ]}
        options={{
          showTitle: false,
          searchFieldAlignment: 'left',
          pageSize: 10,
          pageSizeOptions: [5, 10, 25, 50, 100],
          defaultExpanded: true,
          padding: 'dense',
        }}
      />
    </>
  );
};

export default React.memo(ResourceList);
