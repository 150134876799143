import { get } from 'core/communication';
import appSettings from 'app/appSettings';
import { StingrayApp } from './types';

const BASE_URL = appSettings.apis.stingrayApps.url;

// eslint-disable-next-line import/prefer-default-export
export async function listStingrayApps(accessToken: string): Promise<Array<StingrayApp>> {
  const url = `${BASE_URL}/apps`;
  const result = await get<Array<StingrayApp>>(url, accessToken);
  return result;
}
