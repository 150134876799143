import React from 'react';
import {
  Tooltip, Box, makeStyles, Typography,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Schedule';
import moment from 'moment';

type RelativeTimestampProps = {
  date: Date,
};

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(0.5),
    fontSize: 16,
  },
}));

const RelativeTimestamp = ({ date }: RelativeTimestampProps) => {
  const classes = useStyles();

  return (
    <Tooltip
      placement="bottom-start"
      title={<Typography variant="body2">{date.toLocaleString()}</Typography>}
    >
      <Box display="flex" alignItems="center">
        {moment(date).fromNow()}
        <InfoIcon className={classes.icon} />
      </Box>
    </Tooltip>
  );
};

export default React.memo(RelativeTimestamp);
