import React, { useEffect } from 'react';
import {
  Paper, Grid, LinearProgress, makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import StingrayAppItem from './StingrayAppItem';
import { selectApps, fetchStingrayApps, selectIsFetching } from './stingrayAppsSlice';

const useStyles = makeStyles(() => ({
  paper: {
    height: 305,
    width: 350,
    padding: 5,
    backgroundColor: 'white',
    overflow: 'auto',
  },
}));

type AppsCardProps = {
  onItemClick: () => void,
};

const AppsCard = (props: AppsCardProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const stingrayApps = useSelector(selectApps);
  const isFetching = useSelector(selectIsFetching);

  const { onItemClick } = props;

  useEffect(() => {
    const fetch = async () => {
      const accessToken = await getAccessTokenSilently();
      dispatch(fetchStingrayApps(accessToken));
    };

    // fetch apps only if it not fetched already
    if (!stingrayApps.length && !isFetching) {
      fetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!stingrayApps.length && isFetching) {
    return (
      <Paper className={classes.paper}>
        <LinearProgress />
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={1}>
        {
          stingrayApps.map((app) => (
            <Grid item xs={4} key={app.id}>
              <StingrayAppItem onClick={onItemClick} stingrayApp={app} />
            </Grid>
          ))
        }
      </Grid>
    </Paper>
  );
};

export default React.memo(AppsCard);
