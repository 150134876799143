import React, { useState } from 'react';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircleOutline';
import PermissionOverview from 'features/users/PermissionOverview';
import { User, UserPermission } from 'features/users/types';
import ConfirmDialog from 'features/common/ConfirmDialog';
import PermissionDialog from './PermissionDialog';
import useSaveUserWithoutGroup from './useSaveUserWithoutGroup';
import { getDefaultUserInputs } from './helpers';

type TestUserPermissionsOverviewProps = {
  user: User;
};

type DeletePermissionSettings = {
  open:boolean,
  permission: UserPermission|null,
  permissionName: string
};

const TestUserPermissionsOverview = (props : TestUserPermissionsOverviewProps) => {
  const { user } = props;
  const [showNewPermissionDialog, setShowNewPermissionDialog] = useState(false);
  const [deleteSettings, setDeletePermissionSettings] = useState<DeletePermissionSettings>({
    open: false,
    permission: null,
    permissionName: '',
  });

  const handleResetDeletePermission = () => {
    setDeletePermissionSettings({ open: false, permission: null, permissionName: '' });
  };

  const [saving, handleUserSaving] = useSaveUserWithoutGroup('edit', handleResetDeletePermission);
  const saveUserPermissions = (userPermissions: Array<UserPermission>) => {
    handleUserSaving({ ...getDefaultUserInputs(user.userType, user, false), userSpecificPermissions: userPermissions });
  };

  const actions = [
    {
      icon: 'add_box',
      tooltip: 'Add new permission',
      isFreeAction: true,
      onClick: () => { setShowNewPermissionDialog(true); },
    },
    {
      icon: RemoveCircleIcon,
      tooltip: 'Remove permission',
      onClick: (event: any, rowData: any) => {
        const foundPermission = user.userSpecificPermissions.find((up) => up.externalId === rowData.externalId);
        if (foundPermission) {
          setDeletePermissionSettings({
            open: true,
            permission: foundPermission,
            permissionName: `${rowData.accessRightName}:${rowData.resourceName}`,
          });
        } else {
          handleResetDeletePermission();
        }
      },
    },
  ];

  const handleDeletePermission = () => {
    if (deleteSettings.open && deleteSettings.permission?.externalId) {
      const { externalId } = deleteSettings.permission;
      const savePermissons = user.userSpecificPermissions.filter((up) => up.externalId !== externalId);
      saveUserPermissions(savePermissons);
    }
  };

  const handleClosePermissionDialog = () => {
    setShowNewPermissionDialog(false);
  };

  return (
    <>
      {showNewPermissionDialog && (
        <PermissionDialog
          user={user}
          onClose={handleClosePermissionDialog}
        />
      )}
      <ConfirmDialog
        acceptButtonText="Remove permission"
        cancelButtonText="Cancel"
        title="Remove permission"
        loading={saving}
        dialogText={`Are you sure you want to remove '${deleteSettings.permissionName}' from ${user.name}?`}
        open={deleteSettings.open}
        onAccept={handleDeletePermission}
        onCancel={handleResetDeletePermission}
      />
      <PermissionOverview
        user={user}
        actions={actions}
        grouping
      />
    </>
  );
};

export default React.memo(TestUserPermissionsOverview);
