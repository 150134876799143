import React, { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import {
  Box, Button, Card, CardActions, CardContent, Grid, LinearProgress, makeStyles, Typography,
} from '@material-ui/core';

import ConfirmDialog from 'features/common/ConfirmDialog';
import { User, UserPermission } from 'features/users/types';
import { useSelector } from 'react-redux';
import { groupSelectors } from 'features/groups/groupsSlice';
import { getDefaultUserInputs } from './helpers';
import SearchCustomerUser, { SaveSearchCustomerUserParams } from './SearchCustomerUser';
import useSaveUserWithoutGroup from './useSaveUserWithoutGroup';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  chip: {
    marginRight: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
  },
  link: {
    textDecoration: 'none',
  },
}));

type TestUserCopyPermissionsProps = {
  user: User,
};

type TestUserCopyPermissionsState = {
  permissions: Array<UserPermission>,
  companyCode: number,
  user: User | null,
};

const TestUserCopyPermissions = ({ user }: TestUserCopyPermissionsProps) => {
  const classes = useStyles();
  const [formState, setFormState] = useState<TestUserCopyPermissionsState>({
    permissions: [],
    companyCode: 0,
    user: null,
  });
  const [clearPermissionsConfirmDialogOpen, setClearPermissionsConfirmDialogOpen] = useState(false);

  const handleAfterSave = () => {
    setFormState({ permissions: [], companyCode: 0, user: null });
  };
  const [saving, handleUserSaving] = useSaveUserWithoutGroup('edit', handleAfterSave);
  const allGroups = useSelector(groupSelectors.selectAll);

  const handleSearchCustomerUser = useCallback(({ user: searchUser, companyCode }: SaveSearchCustomerUserParams) => {
    if (searchUser) {
      const newPermissions: Array<UserPermission> = [];
      const dateNow = new Date();
      const endOfTheDay = moment().endOf('day').toDate();
      if (searchUser && user) {
        const groupIds = new Set(searchUser.groups.map((g) => g.externalId));
        const groups = allGroups.filter((g) => groupIds.has(g.externalId));
        groups.forEach((group) => {
          group.permissions.forEach((perm) => {
            if (!newPermissions.some((hp) => hp.localityType === perm.localityType
              && hp.accessRightExternalId === perm.accessRightExternalId
              && hp.localityCode === perm.localityCode
              && hp.resourceExternalId === perm.resourceExternalId)
            ) {
              newPermissions.push({
                userExternalId: user.externalId,
                externalId: uuidv4(),
                resourceExternalId: perm.resourceExternalId,
                accessRightExternalId: perm.accessRightExternalId,
                localityType: perm.localityType,
                localityCode: perm.localityCode,
                recTmsFrom: null,
                recTmsTo: null,
                validFrom: dateNow.toISOString(),
                validTo: endOfTheDay.toISOString(),
                regTms: dateNow.toISOString(),
              });
            }
          });
        });
      }
      setFormState({
        permissions: newPermissions,
        user: searchUser,
        // eslint-disable-next-line max-len
        companyCode: searchUser.companyCodes.includes(formState.companyCode) ? formState.companyCode : searchUser.companyCodes[0],
      });
    } else if (companyCode) {
      setFormState({ permissions: [], user: null, companyCode });
    } else {
      const newState = { ...formState };
      if (companyCode !== undefined) {
        newState.companyCode = 0;
      }
      if (searchUser !== undefined) {
        newState.user = null;
        newState.permissions = [];
      }
      setFormState(newState);
    }
  }, [user, formState, allGroups]);

  const handleClearPermissions = () => {
    setClearPermissionsConfirmDialogOpen(false);
    setFormState({ permissions: [], user: null, companyCode: 0 });
    handleUserSaving({
      ...getDefaultUserInputs(user.userType, user, false),
      userSpecificPermissions: [],
    });
  };

  return (
    <>
      <ConfirmDialog
        acceptButtonText="Clear permissions"
        cancelButtonText="Cancel"
        title="Clear permissions"
        dialogText={`Are you sure you want to clear all permissions from ${user.name} (${user.email})?`}
        open={clearPermissionsConfirmDialogOpen}
        onAccept={handleClearPermissions}
        onCancel={() => setClearPermissionsConfirmDialogOpen(false)}
      />
      <Card className={classes.root}>
        {saving && (<LinearProgress />)}
        <Box ml={2} mt={1}>
          <Typography variant="h6">Fast track permission copy</Typography>
        </Box>
        <CardContent className={classes.content}>
          <Grid container spacing={1}>
            <SearchCustomerUser
              permissions={formState.permissions ?? []}
              handleCustomerUser={handleSearchCustomerUser}
              companyCode={formState.companyCode}
              user={formState.user}
            />
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            variant="outlined"
            disabled={(formState.permissions ?? []).length === 0 || saving}
            onClick={() => {
              handleUserSaving({
                ...getDefaultUserInputs(user.userType, user, false),
                userSpecificPermissions: formState.permissions,
              });
            }}
          >
            Replace / copy
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            disabled={user.userSpecificPermissions.length === 0}
            onClick={() => setClearPermissionsConfirmDialogOpen(true)}
          >
            Clear all permissions
          </Button>
        </CardActions>
      </Card>
    </>
  );
};
export default React.memo(TestUserCopyPermissions);
