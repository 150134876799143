/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { FailurePayload } from 'core/types';
import { StingrayApp } from './types';
import { listStingrayApps } from './api';

type StingrayAppsState = {
  apps: Array<StingrayApp>,
  isFetching: boolean,
  error: string | null,
};

const initialState: StingrayAppsState = {
  apps: [],
  isFetching: false,
  error: null,
};

export const stingrayAppsSlice = createSlice({
  name: 'stingrayApps',
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.isFetching = true;
    },
    fetchFailure: (state, action: PayloadAction<FailurePayload>) => {
      state.isFetching = false;
      state.error = action.payload.text;
    },
    fetchSuccess: (state, action: PayloadAction<Array<StingrayApp>>) => {
      state.isFetching = false;
      state.error = null;
      state.apps = action.payload;
    },
  },
});

// actions
export const { fetchStart, fetchFailure, fetchSuccess } = stingrayAppsSlice.actions;

// selectors
export const selectApps = (state: RootState) => state.stingrayApps.apps;
export const selectIsFetching = (state: RootState) => state.stingrayApps.isFetching;
export const selectError = (state: RootState) => state.stingrayApps.error;

// action creators
export const fetchStingrayApps = (accessToken: string): AppThunk => async (dispatch, getState) => {
  const isFetching = selectIsFetching(getState());
  if (isFetching) {
    return;
  }

  dispatch(fetchStart());

  try {
    const user = await listStingrayApps(accessToken);
    dispatch(fetchSuccess(user));
  } catch (err) {
    // trying to get error message from exception.
    const text = typeof (err as any).message === 'string' ? (err as any).message : 'Unknown error';

    dispatch(fetchFailure({
      notify: true,
      title: 'Failed to load Stingray Apps',
      text,
    }));
  }
};

export default stingrayAppsSlice.reducer;
