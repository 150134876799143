import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable, { MTableActions, MTableAction } from 'material-table';
import { Dictionary } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { userSelectors } from 'features/users/usersSlice';
import { accessRightSelectors } from 'features/accessRights/accessRightsSlice';
import { resourceSelectors } from 'features/resources/resourcesSlice';
import ListTooltip from 'features/common/ListTooltip';
import { Box } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import NavigationButton from 'features/common/NavigationButton';
import { parentGroupSelectors } from '../groups/parentGroupsSlice';
import { groupSelectors, deleteGroup } from '../groups/groupsSlice';
import { Group, GroupFamilyEnum, GroupTypeEnum } from '../groups/types';
import GroupEditDialog from '../groups/GroupEditDialog';

type TableDataEntry = {
  externalId: string,
  name: string,
  description: string,
  permissions: Array<string>,
  users: Array<string>,
  parentGroup: string,
  groupType: GroupTypeEnum,
};

type EditDialogState = {
  open: boolean,
  group?: Group,
};

const StingrayGroupList = () => {
  const { url } = useRouteMatch();

  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const groups = useSelector(groupSelectors.selectAll);
  const accessRightsLookup = useSelector(accessRightSelectors.selectEntities);
  const resourcesLookup = useSelector(resourceSelectors.selectEntities);
  const parentGroupsLookup = useSelector(parentGroupSelectors.selectEntities);
  const users = useSelector(userSelectors.selectAll);

  // stingray groups is a subset of groups where the parent-group does not belong to a customer
  const stingrayGroups = useMemo(() => {
    const result = groups.filter((group) => {
      const { parentGroupExternalId } = group;
      const parentGroup = parentGroupsLookup[parentGroupExternalId];

      return !parentGroup || !parentGroup.companyCode;
    });
    return result;
  }, [groups, parentGroupsLookup]);

  const groupUsersLookup = useMemo(() => {
    const result: Dictionary<Array<string>> = {};

    // registering all groups in the lookup
    stingrayGroups.forEach((group) => {
      result[group.externalId] = [];
    });

    users.forEach((user) => {
      user.groups.forEach((group) => {
        result[group.externalId]?.push(user.name);
      });
    });

    return result;
  }, [stingrayGroups, users]);

  const tableData = useMemo(() => stingrayGroups.map((g) => {
    const now = new Date();
    const permissions = g.permissions
      .filter(({ validFrom, validTo }) => {
        // filter no longer valid permissions
        const fromValid = !validFrom || new Date(validFrom) < now;
        const toValid = !validTo || new Date(validTo) > now;
        return fromValid && toValid;
      })
      .map((p) => {
        // create permission string
        const accessRightName = accessRightsLookup[p.accessRightExternalId]?.name || 'Unknown';
        const resourceName = resourcesLookup[p.resourceExternalId]?.name || 'Unknown';
        const locationInfo = p.localityCode ? ` (${p.localityCode})` : '';

        return `${resourceName}:${accessRightName}${locationInfo}`;
      });
    const result: TableDataEntry = {
      name: g.name,
      description: g.description,
      externalId: g.externalId,
      parentGroup: parentGroupsLookup[g.parentGroupExternalId]?.name || '',
      users: groupUsersLookup[g.externalId] || [],
      groupType: g.groupType,
      permissions,
    };
    return result;
  }), [accessRightsLookup, groupUsersLookup, stingrayGroups, parentGroupsLookup, resourcesLookup]);

  const [editDialogState, setEditDialogState] = useState<EditDialogState>({ open: false });
  const handleEditGroup = (rowData: TableDataEntry) => {
    const group = stingrayGroups.find((g) => g.externalId === rowData.externalId);
    if (group) {
      setEditDialogState({ open: true, group });
    }
  };
  const handleCreateGroup = () => setEditDialogState({ open: true });
  const handleEditDialogClosed = () => setEditDialogState({ open: false });

  const handleDeleteGroup = async (externalId: string) => {
    const accessToken = await getAccessTokenSilently();
    await dispatch(deleteGroup(externalId, accessToken));
  };

  return (
    <>
      {editDialogState.open && (
        <GroupEditDialog
          onClose={handleEditDialogClosed}
          group={editDialogState.group}
          groupType={GroupFamilyEnum.NonCustomerGroup}
        />
      )}
      <MaterialTable
        data={tableData}
        columns={[
          {
            title: 'Name',
            field: 'name',
            type: 'string',
          },
          {
            title: 'Description',
            field: 'description',
            type: 'string',
            width: '40%',
          },
          {
            title: 'Type',
            field: 'groupType',
            type: 'string',
            width: '40%',
          },
          {
            title: 'Users',
            render: (rowData) => <ListTooltip values={rowData.users} />,
          },
          {
            title: 'Permissions',
            render: (rowData) => <ListTooltip values={rowData.permissions} />,
          },
          {
            title: 'Parent',
            field: 'parentGroup',
            defaultGroupOrder: 0,
            defaultGroupSort: 'asc',
          },
        ]}
        options={{
          showTitle: false,
          searchFieldAlignment: 'left',
          pageSize: 10,
          pageSizeOptions: [5, 10, 25, 50, 100],
          defaultExpanded: true,
          padding: 'dense',
        }}
        actions={[
          {
            icon: 'add_box',
            tooltip: 'Add',
            isFreeAction: true,
            onClick: handleCreateGroup,
          },
          {
            icon: 'open_in_new',
            tooltip: 'Open',
            onClick: () => { },
          },
          (rowData) => ({
            icon: 'edit',
            tooltip: 'Edit',
            onClick: () => handleEditGroup(rowData),
          }),
        ]}
        editable={{
          onRowDelete: (rowData) => handleDeleteGroup(rowData.externalId),
          isDeletable: (rowData) => rowData.users.length === 0,
        }}
        localization={{
          body: {
            editRow: {
              deleteText: 'Are you sure you want to delete this group?',
            },
          },
        }}
        components={{
          Actions: (props) => (
            <Box display="flex" flexGrow="1" justifyContent="center">
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <MTableActions {...props} />
            </Box>
          ),
          Action: (props: any) => {
            // navigation links should work as links
            if (props?.action?.icon === 'open_in_new') {
              return (
                <NavigationButton
                  to={`${url}/${props?.data?.externalId}`}
                  tooltip={props?.action?.tooltip}
                />
              );
            }
            // eslint-disable-next-line react/jsx-props-no-spreading
            return <MTableAction {...props} />;
          },
        }}
      />
    </>
  );
};

export default React.memo(StingrayGroupList);
