import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { accessRightSelectors } from './accessRightsSlice';
import { AccessRightName } from './types';

export default function useAccessRights() {
  const accessRights = useSelector(accessRightSelectors.selectAll);
  const { ReadAccessRight, ReadWriteAccessRight } = useMemo(() => ({
    ReadAccessRight: accessRights.find((ar) => ar.name === AccessRightName.Read),
    ReadWriteAccessRight: accessRights.find((ar) => ar.name === AccessRightName.ReadWrite),
  }), [accessRights]);

  if (!ReadAccessRight || !ReadWriteAccessRight) {
    throw new Error('Access rights are missing');
  }
  return [ReadAccessRight, ReadWriteAccessRight] as const;
}
