import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Paper } from '@material-ui/core';
import { customerSelectors } from './fleetSlice';
import { Customer } from './types';

type GroupSelectorProps = {
  companyCodes: Array<number>,
  onChange: (value: Array<number>) => void,
};

const CustomerSelect = (props: GroupSelectorProps) => {
  const { companyCodes, onChange } = props;
  const customers = useSelector(customerSelectors.selectAll);
  const customerLookup = useSelector(customerSelectors.selectEntities);

  const handleChange = (_: any, value: Array<Customer>) => {
    onChange(value.map((customer) => customer.code));
  };

  const selectedOptions = useMemo(() => {
    const result: Array<Customer> = [];
    companyCodes.forEach((soCode) => {
      const customer = customerLookup[soCode];
      if (customer) {
        result.push(customer);
      }
    });
    return result;
  }, [customerLookup, companyCodes]);

  return (
    <Autocomplete
      options={customers}
      getOptionLabel={(option) => option.name}
      fullWidth
      multiple
      value={selectedOptions}
      onChange={handleChange}
      // eslint-disable-next-line react/jsx-props-no-spreading
      PaperComponent={(paperProps) => <Paper {...paperProps} elevation={8} />}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label="Customers"
          variant="outlined"
        />
      )}
    />
  );
};

export default React.memo(CustomerSelect);
