/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import {
  createSlice, PayloadAction, createEntityAdapter, EntityState,
} from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { FailurePayload } from 'core/types';
import { Group, GroupInput, GroupTemplateInput } from './types';
import * as api from './api';

const groupsAdapter = createEntityAdapter<Group>({
  selectId: (group) => group.externalId,
  sortComparer: (a, b) => (a.name > b.name ? 1 : -1),
});

type GroupsState = EntityState<Group> & {
  isFetching: boolean,
  error: string | null,
  updatingGroupIds: Array<string>,
};

const initialState: GroupsState = groupsAdapter.getInitialState({
  isFetching: false,
  error: null,
  updatingGroupIds: [],
});

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.isFetching = true;
    },
    fetchFailure: (state, action: PayloadAction<FailurePayload>) => {
      state.isFetching = false;
      state.error = action.payload.text;
    },
    fetchSuccess: (state, action: PayloadAction<Array<Group>>) => {
      state.isFetching = false;
      state.error = null;
      groupsAdapter.setAll(state, action.payload);
    },
    createSuccess: (state, action: PayloadAction<Group>) => {
      state.error = null;
      groupsAdapter.addOne(state, action.payload);
    },
    createFailure: (state, action: PayloadAction<FailurePayload>) => {
      state.error = action.payload.text;
    },
    updateStart: (state, action: PayloadAction<GroupInput>) => {
      const { externalId } = action.payload;
      if (externalId) {
        state.updatingGroupIds.push(externalId);
      }
    },
    updateSuccess: (state, action: PayloadAction<Group>) => {
      state.error = null;
      state.updatingGroupIds = state.updatingGroupIds.filter((id) => id !== action.payload.externalId);
      groupsAdapter.upsertOne(state, action.payload);
    },
    updateFailure: (state, action: PayloadAction<FailurePayload & { groupExternalId?: string }>) => {
      state.updatingGroupIds.filter((id) => id !== action.payload.groupExternalId);
      state.error = action.payload.text;
    },
    deleteSuccess: (state, action: PayloadAction<string>) => {
      state.error = null;
      groupsAdapter.removeOne(state, action.payload);
    },
    deleteFailure: (state, action: PayloadAction<FailurePayload>) => {
      state.error = action.payload.text;
    },
  },
});

// actions
const {
  fetchStart, fetchFailure, fetchSuccess,
  createSuccess, createFailure,
  updateStart, updateSuccess, updateFailure,
  deleteSuccess, deleteFailure,
} = groupsSlice.actions;

// selectors
const sequencesSelectors = groupsAdapter.getSelectors((state: RootState) => state.groups);
const selectIsFetching = (state: RootState) => state.groups.isFetching;
const selectError = (state: RootState) => state.groups.error;
const selectUpdatingById = (state: RootState, id: string) => state.groups.updatingGroupIds.includes(id);

export const groupSelectors = {
  ...sequencesSelectors,
  selectUpdatingById,
  selectIsFetching,
  selectError,
};

// action creators
export const fetchGroups = (accessToken: string): AppThunk => async (dispatch, getState) => {
  // avoid race conditions
  const isFetching = selectIsFetching(getState());
  if (isFetching) {
    return;
  }

  dispatch(fetchStart());

  try {
    const groups = await api.getGroups(accessToken);
    dispatch(fetchSuccess(groups));
  } catch (err) {
    // trying to get error message from exception.
    const text = typeof (err as any).message === 'string' ? (err as any).message : 'Unknown error';

    dispatch(fetchFailure({
      notify: true,
      title: 'Failed to load groups',
      text,
    }));
  }
};

export const createGroup = (
  groupIn: GroupInput,
  accessToken: string,
): AppThunk => async (dispatch) => {
  try {
    const group = await api.createGroup(groupIn, accessToken);
    dispatch(createSuccess(group));
  } catch (err) {
    // trying to get error message from exception.
    const text = typeof (err as any).message === 'string' ? (err as any).message : 'Unknown error';

    dispatch(createFailure({
      notify: true,
      title: 'Failed to create group',
      text,
    }));
  }
};

export const createGroupFromTemplate = (
  groupIn: GroupTemplateInput,
  accessToken: string,
): AppThunk => async (dispatch) => {
  try {
    const group = await api.createGroupFromTemplate(groupIn, accessToken);
    dispatch(createSuccess(group));
  } catch (err) {
    // trying to get error message from exception.
    const text = typeof (err as any).message === 'string' ? (err as any).message : 'Unknown error';

    dispatch(createFailure({
      notify: true,
      title: 'Failed to create group',
      text,
    }));
  }
};

export const updateGroup = (
  groupIn: GroupInput,
  accessToken: string,
): AppThunk => async (dispatch, getState) => {
  // avoid race conditions
  if (!groupIn.externalId || selectUpdatingById(getState(), groupIn.externalId)) {
    return;
  }

  dispatch(updateStart(groupIn));

  try {
    const group = await api.updateGroup(groupIn, accessToken);
    dispatch(updateSuccess(group));
  } catch (err) {
    // trying to get error message from exception.
    const text = typeof (err as any).message === 'string' ? (err as any).message : 'Unknown error';

    dispatch(updateFailure({
      notify: true,
      title: 'Failed to update group',
      text,
      groupExternalId: groupIn.externalId,
    }));
  }
};

export const deleteGroup = (
  externalId: string,
  accessToken: string,
): AppThunk => async (dispatch) => {
  try {
    await api.deleteGroup(externalId, accessToken);
    dispatch(deleteSuccess(externalId));
  } catch (err) {
    // trying to get error message from exception.
    const text = typeof (err as any).message === 'string' ? (err as any).message : 'Unknown error';

    dispatch(deleteFailure({
      notify: true,
      title: 'Failed to delete group',
      text,
    }));
  }
};

export default groupsSlice.reducer;
