import { useParams } from 'react-router-dom';
import React, { useMemo } from 'react';
import {
  Box, Typography, makeStyles, Chip, Grid,
} from '@material-ui/core';
import { routeMap } from 'features/layout/Navigation';
import { customerSelectors } from 'features/fleet/fleetSlice';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import NavigateBackNotFound from 'features/common/NavigateBackNotFound';
import NavigateBackHeader from 'features/common/NavigateBackHeader';
import UserList from 'features/users/UserList';
import { UserTypeEnum } from 'features/users/types';
import CustomerGroupList from './CustomerGroupList';
import CustomerGroupMembershipMatrix from './CustomerGroupMembershipMatrix';

const useStyles = makeStyles((theme) => ({
  locationChip: {
    marginRight: theme.spacing(0.5),
  },
}));

const navigateBackUrl = routeMap.customer.baseUrl;
const navigateBackText = 'Back to Customers';

const CustomerDetails = () => {
  const classes = useStyles();
  const { companyCode: companyCodeParam } = useParams<{ companyCode: string }>();
  const companyCode = useMemo(() => Number(companyCodeParam) || 0, [companyCodeParam]);

  const customer = useSelector((state: RootState) => customerSelectors.selectById(state, companyCode));

  const sortedLocations = useMemo(() => {
    if (!customer) {
      return [];
    }
    return [...customer?.locations].sort((a, b) => (a.name > b.name ? 1 : -1));
  }, [customer]);

  if (!customer) {
    return (
      <NavigateBackNotFound
        navigateBackText={navigateBackText}
        navigateBackUrl={navigateBackUrl}
        notFoundText="Customer not found"
      />
    );
  }

  return (
    <div>
      <NavigateBackHeader navigateBackText={navigateBackText} navigateBackUrl={navigateBackUrl}>
        <Box display="flex" alignItems="center">
          <Typography variant="h4">{customer.name}</Typography>
        </Box>
        <Box display="flex" flexWrap="wrap">
          {sortedLocations.map((l) => <Chip size="small" className={classes.locationChip} key={l.code} label={l.name} />)}
        </Box>
      </NavigateBackHeader>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <UserList
            userType={UserTypeEnum.Customer}
            companyCode={customer.code}
            dynamicPageSize
            showTitle
          />
        </Grid>
        <Grid item sm={12}><CustomerGroupList customer={customer} /></Grid>
        <Grid item sm={12}><CustomerGroupMembershipMatrix companyCode={customer.code} /></Grid>
      </Grid>
    </div>
  );
};

export default React.memo(CustomerDetails);
