import AppSettingsTemplate, { EnvironmentEnum } from './appSettings.template';

const stagingAppSettings: AppSettingsTemplate = {
  auth0: {
    domain: 'stingray-staging.eu.auth0.com',
    clientId: 'kAf99Kt41i3BWvmWfIC1I0RjaTHwq1eQ',
    audience: 'https://stingrayapis.skywater.io/',
    scope: `Read:AuthServiceResources
            ReadWrite:AuthServiceGroups
            ReadWrite:AuthServiceUsers
            ReadWrite:AuthServiceLocationUsers
            Read:EquipmentPlacementFleet`,
  },
  apis: {
    authService: {
      url: 'https://lcclienttest.skywater.io/api/authservice',
    },
    stingrayApps: {
      url: 'https://hq.skywater.io/webapp-registry/api/v1.0',
    },
    hqApi: {
      url: 'https://staging.equipmentplacement-api.skywater.io/api/v1/p1api',
    },
  },
  authAdmin2Url: 'https://staging-authadmin2.stingray.local',
  testusers: {
    emailSuffix: 'auth0test',
  },
  environmnet: EnvironmentEnum.staging,
};

export default stagingAppSettings;
