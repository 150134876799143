export enum AccessRightName {
  'Read' = 'Read',
  'ReadWrite' = 'ReadWrite',
}

export type AccessRight = {
  id: string,
  externalId: string,
  name: AccessRightName,
  description?: string,
  regTms: string
};
