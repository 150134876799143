import React from 'react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import { makeStyles, Container } from '@material-ui/core';
import GroupDetails from 'features/groups/GroupDetails';
import { GroupFamilyEnum } from 'features/groups/types';
import UserDetails from 'features/users/UserDetails';
import { UserTypeEnum } from 'features/users/types';
import CustomerList from './CustomerList';
import CustomerDetails from './CustomerDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
}));

const CustomerSection = () => {
  const classes = useStyles();

  const { path } = useRouteMatch();

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Switch>
        <Route exact path={path}>
          <CustomerList />
        </Route>
        <Route exact path={`${path}/:companyCode`}>
          <CustomerDetails />
        </Route>
        <Route exact path={`${path}/:companyCode/users/:userId`}>
          <UserDetails navigateBackContext={UserTypeEnum.Customer} />
        </Route>
        <Route exact path={`${path}/:companyCode/groups/:groupExternalId`}>
          <GroupDetails groupType={GroupFamilyEnum.CustomerGroup} />
        </Route>
      </Switch>
    </Container>
  );
};

export default CustomerSection;
