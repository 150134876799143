import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { v4 as uuidv4 } from 'uuid';
import {
  Typography, ButtonGroup, Button, Box,
} from '@material-ui/core';
import ReadIcon from '@material-ui/icons/Visibility';
import WriteIcon from '@material-ui/icons/Edit';
import NoAccessIcon from '@material-ui/icons/NotInterested';
import { RootState } from 'app/store';

import { Group, GroupInput } from 'features/groups/types';
import { Resource } from 'features/resources/types';
import useAccessRights from 'features/accessRights/useAccessRights';
import { resourceGroupSelectors } from 'features/resources/resourceGroupsSlice';
import { updateGroup } from 'features/groups/groupsSlice';
import InformationTooltip from './InformationTooltip';
import { LocalityTypeEnum } from './types';

type PermissionSwitchProps = {
  group: Group,
  resource: Resource,
  localityType: LocalityTypeEnum,
  localityCode: number | null,
  hideName?: boolean,
  disabled?: boolean,
};

type AccessRightType = 'Read' | 'ReadWrite' | 'NoAccess' | 'Unknown';

const PermissionSwitch = (props: PermissionSwitchProps) => {
  const {
    group,
    resource,
    localityType,
    localityCode,
    hideName,
    disabled,
  } = props;

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const [ReadAccessRight, ReadWriteAccessRight] = useAccessRights();

  const resourceGroup = useSelector(
    (state: RootState) => resourceGroupSelectors.selectById(state, resource.resourceGroupExternalId),
  );

  // removing resource group prefix if possible
  const resourceTitle = useMemo(() => {
    if (!resourceGroup || !resource.name.startsWith(resourceGroup.name)) {
      return resource.name;
    }

    return resource.name.replace(resourceGroup.name, '');
  }, [resourceGroup, resource.name]);

  const currentPermission = useMemo(
    () => group.permissions.find((permission) => permission.resourceExternalId === resource.externalId
      && permission.localityType === localityType
      && permission.localityCode === localityCode),
    [group.permissions, localityType, localityCode, resource.externalId],
  );

  const currentAccessRight = useMemo<AccessRightType>(() => {
    const now = new Date();

    if (!currentPermission) {
      return 'NoAccess';
    }

    const { validTo } = currentPermission;
    if (validTo && new Date(validTo) <= now) {
      return 'NoAccess';
    }

    if (currentPermission.accessRightExternalId === ReadAccessRight?.externalId) {
      return 'Read';
    }

    if (currentPermission.accessRightExternalId === ReadWriteAccessRight?.externalId) {
      return 'ReadWrite';
    }

    return 'Unknown';
  }, [ReadAccessRight, ReadWriteAccessRight, currentPermission]);

  const handleAccessRightChanged = (newAccessRight: AccessRightType) => async () => {
    if (newAccessRight === currentAccessRight) {
      return;
    }

    let newPermissions = [...group.permissions];

    if (currentPermission) {
      // there's a permission created already, update accordingly
      const updatedPermission = { ...currentPermission };

      switch (newAccessRight) {
        case 'NoAccess':
          updatedPermission.validTo = new Date().toISOString();
          break;
        case 'Read':
          updatedPermission.validTo = null;
          updatedPermission.accessRightExternalId = ReadAccessRight.externalId;
          break;
        case 'ReadWrite':
          updatedPermission.validTo = null;
          updatedPermission.accessRightExternalId = ReadWriteAccessRight.externalId;
          break;
        default:
          break;
      }

      // replace the permission
      newPermissions = newPermissions.map((p) => (p.externalId === updatedPermission.externalId ? updatedPermission : p));
    } else {
      // there's no permission, create one
      const newAccessRightExternalId = newAccessRight === 'Read'
        ? ReadAccessRight?.externalId
        : ReadWriteAccessRight?.externalId;

      newPermissions = [
        ...newPermissions,
        {
          externalId: uuidv4(),
          accessRightExternalId: newAccessRightExternalId,
          groupExternalId: group.externalId,
          localityType,
          localityCode,
          recTmsFrom: null,
          recTmsTo: null,
          resourceExternalId: resource.externalId,
          validFrom: null,
          validTo: null,
          regTms: new Date().toISOString(),
        },
      ];
    }

    const updatedGroup: GroupInput = {
      externalId: group.externalId,
      name: group.name,
      description: group.description,
      parentGroupExternalId: group.parentGroupExternalId,
      groupType: group.groupType,
      permissions: newPermissions,
    };

    try {
      const accessToken = await getAccessTokenSilently();
      await dispatch(updateGroup(updatedGroup, accessToken));
    } catch {
      // ok...
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {!hideName && (
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1">{resourceTitle}</Typography>
          <InformationTooltip text={resource.description} />
        </Box>
      )}
      <ButtonGroup size="small" disabled={disabled}>
        <Button
          onClick={handleAccessRightChanged('NoAccess')}
          variant={currentAccessRight === 'NoAccess' ? 'contained' : 'outlined'}
        >
          <NoAccessIcon fontSize="small" />
        </Button>
        <Button
          onClick={handleAccessRightChanged('Read')}
          variant={currentAccessRight === 'Read' ? 'contained' : 'outlined'}
          color={currentAccessRight === 'Read' ? 'primary' : undefined}
        >
          <ReadIcon fontSize="small" />
        </Button>
        <Button
          onClick={handleAccessRightChanged('ReadWrite')}
          variant={currentAccessRight === 'ReadWrite' ? 'contained' : 'outlined'}
          color={currentAccessRight === 'ReadWrite' ? 'secondary' : undefined}
        >
          <WriteIcon fontSize="small" />
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default React.memo(PermissionSwitch);
