import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userSelectors, removeUserFromGroup } from 'features/users/usersSlice';
import { Group, GroupFamilyEnum } from 'features/groups/types';
import MaterialTable, { MTableAction } from 'material-table';
import ListTooltip from 'features/common/ListTooltip';
import { useAuth0 } from '@auth0/auth0-react';
import { routeMap } from 'features/layout/Navigation';
import NavigationButton from 'features/common/NavigationButton';
import AddGroupMembersDialog from './AddGroupMembersDialog';
import { stingrayPages } from '../stingray/StingraySection';

type GroupMembersProps = {
  group: Group,
  groupType: GroupFamilyEnum,
  companyCode: number | null,
  minHeight?: string,
  maxHeight?: string,
  paging?: boolean,
};

type TableDataEntry = {
  externalId: string,
  name: string,
  email: string,
  phone: string,
  groups: Array<string>,
  enabled: boolean,
};

const GroupMembers = (props: GroupMembersProps) => {
  const {
    group, groupType, companyCode,
    minHeight, maxHeight, paging,
  } = props;

  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const userDetailsBaseUrl = useMemo(() => {
    if (groupType === GroupFamilyEnum.NonCustomerGroup) {
      return `${routeMap.stingray.baseUrl}/${stingrayPages.users.value}`;
    }

    if (groupType === GroupFamilyEnum.CustomerGroup && companyCode) {
      return `${routeMap.customer.baseUrl}/${companyCode}/users`;
    }

    return '';
  }, [companyCode, groupType]);

  const users = useSelector(userSelectors.selectAll);
  const groupMembers = useMemo(() => {
    const result = users.filter((user) => {
      const { groups } = user;
      const isMember = groups.findIndex((g) => g.externalId === group.externalId) >= 0;
      return isMember;
    });
    return result;
  }, [group.externalId, users]);

  const tableData = useMemo(() => groupMembers.map<TableDataEntry>((member) => ({
    externalId: member.externalId,
    name: member.name,
    email: member.email,
    phone: member.phone,
    groups: member.groups.map((g) => g.name),
    enabled: member.enabled,
  })), [groupMembers]);

  const handleUserRemoved = async (rowData: TableDataEntry) => {
    const accessToken = await getAccessTokenSilently();
    await dispatch(removeUserFromGroup(rowData.externalId, group.externalId, accessToken));
  };

  const [isAddMembersDialogOpen, setIsAddMembersDialogOpen] = useState(false);

  return (
    <>
      {isAddMembersDialogOpen && (
        <AddGroupMembersDialog
          onClose={() => setIsAddMembersDialogOpen(false)}
          groupExternalId={group.externalId}
          groupType={groupType}
          companyCode={companyCode}
        />
      )}
      <MaterialTable
        title="Members"
        data={tableData}
        columns={[
          {
            title: 'Name',
            field: 'name',
            type: 'string',
            cellStyle: (_, { enabled }) => ({
              textDecoration: enabled ? 'initial' : 'line-through',
            }),
          },
          {
            title: 'Email',
            field: 'email',
            type: 'string',
          },
          {
            title: 'Phone',
            field: 'phone',
            type: 'string',
          },
          {
            title: 'Enabled',
            field: 'enabled',
            type: 'boolean',
            initialEditValue: true,
          },
          {
            title: 'Groups',
            render: (d) => <ListTooltip values={d.groups} />,
          },
        ]}
        options={{
          paging: !!paging,
          pageSizeOptions: [5, 10, 25, 50],
          pageSize: tableData.length <= 5 ? 5 : 10,
          padding: 'dense',
          maxBodyHeight: maxHeight,
          minBodyHeight: minHeight,
        }}
        localization={{
          body: {
            editRow: {
              deleteText: 'Are you sure you want to remove this user from the group?',
              saveTooltip: 'Remove',
            },
          },
        }}
        editable={{
          onRowDelete: handleUserRemoved,
          deleteTooltip: () => 'Remove user from group',
        }}
        actions={[
          {
            icon: 'open_in_new',
            tooltip: 'Open',
            onClick: () => { },
          },
          {
            icon: 'add_box',
            tooltip: 'Add',
            isFreeAction: true,
            onClick: () => setIsAddMembersDialogOpen(true),
          },
        ]}
        components={{
          Action: (actionProps: any) => {
            // navigation links should work as links
            if (actionProps?.action?.icon === 'open_in_new') {
              return (
                <NavigationButton
                  to={`${userDetailsBaseUrl}/${actionProps?.data?.externalId}`}
                  tooltip={actionProps?.action?.tooltip}
                />
              );
            }
            // eslint-disable-next-line react/jsx-props-no-spreading
            return <MTableAction {...actionProps} />;
          },
        }}
      />
    </>
  );
};

export default React.memo(GroupMembers);
