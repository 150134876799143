import { useSelector } from 'react-redux';
import { customerSelectors } from 'features/fleet/fleetSlice';
import React, { useMemo } from 'react';
import MaterialTable from 'material-table';
import { Container } from '@material-ui/core';
import { userSelectors } from 'features/users/usersSlice';
import { groupSelectors } from 'features/groups/groupsSlice';
import { parentGroupSelectors } from 'features/groups/parentGroupsSlice';
import { Dictionary } from '@reduxjs/toolkit';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { downloadCustomerUsersCsvFormatted } from 'features/users/api';
import { useAuth0 } from '@auth0/auth0-react';

type TableDataEntry = {
  companyCode: number,
  name: string,
  locations: number,
  users: number,
  groups: number,
};

const CustomerList = () => {
  const customers = useSelector(customerSelectors.selectAll);
  const users = useSelector(userSelectors.selectAll);
  const groups = useSelector(groupSelectors.selectAll);
  const parentGroupLookup = useSelector(parentGroupSelectors.selectEntities);
  const { getAccessTokenSilently } = useAuth0();

  const userCountPerLocation = useMemo(() => {
    const result: Dictionary<number> = {};

    users.forEach((user) => {
      user.companyCodes.forEach((companyCode) => {
        const currCount = result[companyCode] || 0;
        result[companyCode] = currCount + 1;
      });
    });

    return result;
  }, [users]);

  const groupCountPerLocation = useMemo(() => {
    const result: Dictionary<number> = {};

    groups.forEach((group) => {
      const parentGroup = parentGroupLookup[group.parentGroupExternalId];

      if (parentGroup && parentGroup.companyCode) {
        const currentCount = result[parentGroup.companyCode] || 0;
        result[parentGroup.companyCode] = currentCount + 1;
      }
    });

    return result;
  }, [groups, parentGroupLookup]);

  const tableData = useMemo(() => {
    const result: Array<TableDataEntry> = customers.map((c) => ({
      companyCode: c.code,
      name: c.name,
      locations: c.locations.length,
      groups: groupCountPerLocation[c.code] || 0,
      users: userCountPerLocation[c.code] || 0,
    }));
    return result;
  }, [customers, groupCountPerLocation, userCountPerLocation]);

  const history = useHistory();
  const { url } = useRouteMatch();
  const handleOpenCustomer = (event?: React.MouseEvent<Element, MouseEvent>, rowData?: TableDataEntry) => {
    if (!rowData) {
      return;
    }

    // ctrl + click: new tab
    if (event?.ctrlKey) {
      window.open(`${url}/${rowData.companyCode}`);
    } else {
      history.push(`${url}/${rowData.companyCode}`);
    }
  };

  const downloadCustomerUsersCsvFormattedAction = async () => {
    const accessToken = await getAccessTokenSilently();
    await downloadCustomerUsersCsvFormatted(accessToken);
  };

  return (
    <Container>
      <MaterialTable
        title="Customers"
        data={tableData}
        columns={[
          {
            title: 'Name',
            field: 'name',
            type: 'string',
          },
          {
            title: 'Locations',
            field: 'locations',
          },
          {
            title: 'Users',
            field: 'users',
          },
          {
            title: 'Groups',
            field: 'groups',
          },
        ]}
        options={{
          paging: false,
          padding: 'dense',
        }}
        actions={[
          {
            icon: 'download',
            tooltip: 'Download customer users',
            isFreeAction: true,
            onClick: downloadCustomerUsersCsvFormattedAction,
          },
        ]}
        onRowClick={handleOpenCustomer}
      />
    </Container>
  );
};

export default React.memo(CustomerList);
