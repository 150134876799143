import {
  get, post, put, del, getAndDownload,
} from 'core/communication';
import appSettings from 'app/appSettings';
import {
  User, UserInput, UserPasswordResetInput, UserPasswordResetResponse,
} from './types';

const BASE_URL = appSettings.apis.authService.url;

export async function downloadCustomerUsersCsvFormatted(accessToken: string) {
  const url = `${BASE_URL}/users/CustomerUsersCsvFormatted`;
  await getAndDownload(url, 'CustomerList.csv', accessToken);
}

export async function getUsers(accessToken: string): Promise<Array<User>> {
  const url = `${BASE_URL}/users?IncludePermissionsOnGroups=false`;
  const response = await get<Array<User>>(url, accessToken);
  return response;
}

export async function createUser(userIn: UserInput, accessToken: string): Promise<User> {
  const url = `${BASE_URL}/users`;
  const response = await post<UserInput, User>(url, userIn, accessToken);
  return response;
}

export async function updateUser(userIn: UserInput, accessToken: string): Promise<User> {
  const url = `${BASE_URL}/users`;
  const response = await put<UserInput, User>(url, userIn, accessToken);
  return response;
}

export async function deleteUser(externalId: string, accessToken: string): Promise<void> {
  const url = `${BASE_URL}/users`;
  await del(url, accessToken, { externalId });
}

export async function resetUserPassword(email: string, accessToken: string): Promise<UserPasswordResetResponse> {
  const url = `${BASE_URL}/users/triggerchangepassword`;
  const response = await post<UserPasswordResetInput, UserPasswordResetResponse>(url, { email }, accessToken);
  return response;
}

export async function resetUserMFA(userExternalId: string, accessToken: string): Promise<void> {
  const url = `${BASE_URL}/users/${userExternalId}/resetgooglemfa`;
  await del(url, accessToken);
}
