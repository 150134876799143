import React, { useMemo, useState, useCallback } from 'react';
import { parentGroupSelectors } from 'features/groups/parentGroupsSlice';
import { useSelector, useDispatch } from 'react-redux';
import { groupSelectors } from 'features/groups/groupsSlice';
import { userSelectors, addUserToGroup, removeUserFromGroup } from 'features/users/usersSlice';
import MaterialTable, { Column } from 'material-table';
import {
  Button,
  Checkbox, Dialog, DialogContent, DialogTitle,
  Paper,
  TextField,
} from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import { UserTypeEnum, User } from 'features/users/types';
import { Group } from 'features/groups/types';
import { Autocomplete } from '@material-ui/lab';

type CustomerGroupMembershipMatrixProps = {
  companyCode: number
};

type TableDataEntry = {
  userName: string,
  userExternalId: string,
  userEnabled: boolean,
  userGroupExternalIds: Array<string>
};

const createUserName = (user: User) => {
  if (user.userType === UserTypeEnum.Customer) {
    return user.name;
  }

  return `${user.name} [${user.userType}]`;
};

const CustomerGroupMembershipMatrix = (props: CustomerGroupMembershipMatrixProps) => {
  const { companyCode } = props;

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const parentGroups = useSelector(parentGroupSelectors.selectAll);
  const groups = useSelector(groupSelectors.selectAll);
  const users = useSelector(userSelectors.selectAll);
  const userLookup = useSelector(userSelectors.selectEntities);
  const groupLookup = useSelector(groupSelectors.selectEntities);

  const customerParentGroup = useMemo(
    () => parentGroups.find((pg) => pg.companyCode === companyCode),
    [companyCode, parentGroups],
  );
  const customerGroups = useMemo(
    () => groups.filter((g) => g.parentGroupExternalId === customerParentGroup?.externalId || ''),
    [groups, customerParentGroup],
  );

  const customerUsers = useMemo(
    () => users.filter((u) => u.companyCodes.includes(companyCode)),
    [companyCode, users],
  );

  const userIdOptions = useMemo(() => customerUsers.map((u) => u.externalId), [customerUsers]);
  const groupIdOptions = useMemo(() => customerGroups.map((g) => g.externalId), [customerGroups]);
  const [selectedGroupIds, setSelectedGroupIds] = useState<Array<string>>([]);
  const [selectedUserIds, setSelectedUserIds] = useState<Array<string>>([]);

  const selectedUsers = useMemo(() => {
    const result: Array<User> = [];

    selectedUserIds.forEach((id) => {
      const user = userLookup[id];
      if (user) {
        result.push(user);
      }
    });

    return result;
  }, [userLookup, selectedUserIds]);

  const selectedGroups = useMemo(() => {
    const result: Array<Group> = [];

    selectedGroupIds.forEach((id) => {
      const group = groupLookup[id];
      if (group) {
        result.push(group);
      }
    });

    return result;
  }, [groupLookup, selectedGroupIds]);

  const tableData = useMemo<Array<TableDataEntry>>(
    () => selectedUsers.map((u) => ({
      userName: createUserName(u),
      userExternalId: u.externalId,
      userEnabled: u.enabled,
      userGroupExternalIds: u.groups.map((g) => g.externalId),
    })),
    [selectedUsers],
  );

  const [updating, setUpdating] = useState(false);
  const handleGroupMembershipChanged = useCallback(
    async (userExternalId: string, groupExternalId: string, isMember: boolean) => {
      setUpdating(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (isMember) {
          await dispatch(addUserToGroup(userExternalId, groupExternalId, accessToken));
        } else {
          await dispatch(removeUserFromGroup(userExternalId, groupExternalId, accessToken));
        }
      } catch {
        // ...ok
      } finally {
        setUpdating(false);
      }
    }, [getAccessTokenSilently, dispatch],
  );

  const tableColumns = useMemo(() => {
    let result: Array<Column<TableDataEntry>> = selectedGroups.map((group) => ({
      title: group.name,
      type: 'boolean',
      render: (rowData) => (
        <Checkbox
          disabled={updating}
          size="small"
          checked={rowData.userGroupExternalIds.includes(group.externalId)}
          onChange={(_, v) => handleGroupMembershipChanged(rowData.userExternalId, group.externalId, v)}
        />
      ),
    }));

    result = [
      {
        title: 'User',
        type: 'string',
        field: 'userName',
        cellStyle: (_, { userEnabled }) => ({
          textDecoration: userEnabled ? 'initial' : 'line-through',
        }),
      },
      ...result,
    ];
    return result;
  }, [selectedGroups, handleGroupMembershipChanged, updating]);

  const [configureDialogOpen, setConfigureDialogOpen] = useState(false);

  return (
    <>
      <Dialog open={configureDialogOpen} onClose={() => setConfigureDialogOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Configure</DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
            <Autocomplete
              options={userIdOptions}
              getOptionLabel={(id) => userLookup[id]?.name || 'Unknown'}
              fullWidth
              multiple
              value={selectedUserIds}
              disableCloseOnSelect
              onChange={(_, v) => setSelectedUserIds(v || [])}
              // eslint-disable-next-line react/jsx-props-no-spreading
              // PaperComponent={(paperProps) => <Paper {...paperProps} elevation={8} />}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="Users"
                  variant="outlined"
                />
              )}
            />
            <div>
              <Button color="primary" variant="contained" onClick={() => setSelectedUserIds(userIdOptions)}>
                Add all users
              </Button>
            </div>
            <Autocomplete
              options={groupIdOptions}
              getOptionLabel={(id) => groupLookup[id]?.name || 'Unknown'}
              fullWidth
              multiple
              value={selectedGroupIds}
              onChange={(_, v) => setSelectedGroupIds(v || [])}
              disableCloseOnSelect
              // eslint-disable-next-line react/jsx-props-no-spreading
              PaperComponent={(paperProps) => <Paper {...paperProps} elevation={8} />}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="Groups"
                  variant="outlined"
                />
              )}
            />
            <div>
              <Button color="primary" variant="contained" onClick={() => setSelectedGroupIds(groupIdOptions)}>
                Add all groups
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <MaterialTable
        title="Group membership"
        actions={[
          {
            icon: 'settings',
            tooltip: 'Configure',
            isFreeAction: true,
            onClick: () => setConfigureDialogOpen(true),
          },
        ]}
        columns={tableColumns}
        data={tableData}
        options={{
          padding: 'dense',
          paging: false,
        }}
      />
    </ >
  );
};

export default React.memo(CustomerGroupMembershipMatrix);
