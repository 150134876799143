import React, { useMemo } from 'react';
import {
  useRouteMatch, Route, Switch, useHistory, matchPath,
} from 'react-router-dom';
import {
  makeStyles, Container, Paper, Tabs, Tab,
} from '@material-ui/core';
import TokenGenerator from './TokenGenerator';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
}));

export const toolsPages = {
  tokenGenerator: {
    label: 'Token generator',
    value: 'token-generator',
  },
};

const ToolsSection = () => {
  const classes = useStyles();

  const history = useHistory();
  const { path } = useRouteMatch();

  const handleTabChanged = (value: string) => {
    history.push({
      pathname: `${path}/${value}`,
    });
  };

  const activePageTab = useMemo(() => {
    const page = Object.values(toolsPages).find(
      (p) => matchPath(history.location.pathname, { path: `${path}/${p.value}`, exact: false }),
    );
    if (!page) {
      return toolsPages.tokenGenerator.value;
    }

    return page.value;
  }, [history.location.pathname, path]);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Paper className={classes.tabs} square>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={activePageTab}
          onChange={(_, value) => handleTabChanged(value)}
        >
          <Tab value={toolsPages.tokenGenerator.value} label={toolsPages.tokenGenerator.label} />
        </Tabs>
      </Paper>
      <Switch>
        <Route exact path={`${path}/${toolsPages.tokenGenerator.value}`}>
          <TokenGenerator />
        </Route>
      </Switch>
    </Container>
  );
};

export default ToolsSection;
