import { get } from 'core/communication';
import appSettings from 'app/appSettings';
import { Resource, ResourceGroup } from './types';

const BASE_URL = appSettings.apis.authService.url;

export async function getResources(accessToken: string): Promise<Array<Resource>> {
  const url = `${BASE_URL}/resources`;
  const response = await get<Array<Resource>>(url, accessToken);
  return response;
}

export async function getResourceGroups(accessToken: string): Promise<Array<ResourceGroup>> {
  const url = `${BASE_URL}/resourceGroups`;
  const response = await get<Array<ResourceGroup>>(url, accessToken);
  return response;
}
