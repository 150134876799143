import {
  Box, Button, Typography,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { useHotkeys } from '@mantine/hooks';
import GlobalSearchDialog from './GlobalSearchDialog';

const GlobalSearch = () => {
  const [open, setOpen] = useState(false);

  const handleToggle = useCallback(() => setOpen((p) => !p), []);
  const handleClose = useCallback(() => setOpen(false), []);

  useHotkeys([
    ['ctrl+K', () => handleToggle()],
  ]);

  return (
    <>
      <Button variant="text" size="small" onClick={handleToggle}>
        <Box>
          <SearchIcon color="inherit" />
          <Box>
            <Typography variant="caption">
              Search (⌘+K)
            </Typography>
          </Box>
        </Box>
      </Button>
      {open && <GlobalSearchDialog onClose={handleClose} />}
    </>
  );
};

export default React.memo(GlobalSearch);
