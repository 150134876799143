import React from 'react';
import { makeStyles, IconButton, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

type NavigationButtonProps = {
  to: string,
  tooltip: string,
};

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
    color: 'white',
  },
}));

const NavigationButton = ({ to, tooltip }: NavigationButtonProps) => {
  const classes = useStyles();
  return (
    <Link to={to} className={classes.link}>
      <Tooltip title={tooltip ?? ''}>
        <IconButton size="small"><OpenInNewIcon /></IconButton>
      </Tooltip>
    </Link>
  );
};

export default React.memo(NavigationButton);
