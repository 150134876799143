export enum LocalityTypeEnum {
  Global = 'Global',
  Customer = 'Customer',
  Location = 'Location',
  NotApplicable = 'NotApplicable',
}

export type Permission = {
  externalId: string,
  resourceExternalId: string,
  accessRightExternalId: string,
  localityType: LocalityTypeEnum,
  localityCode: number | null,
  recTmsFrom: string | null,
  recTmsTo: string | null,
  validFrom: string | null,
  validTo: string | null,
  regTms: string,
};
