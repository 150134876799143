import { Group } from 'features/groups/types';
import { Permission } from 'features/common/types';

export enum CountryEnum {
  Norway = 'Norway',
  Scotland = 'Scotland',
  FaroeIslands = 'FaroeIslands',
  Chile = 'Chile',
  Canada = 'Canada',
  Denmark = 'Denmark',
  Ukraine = 'Ukraine',
  Iceland = 'Iceland',
}

export enum LanguageEnum {
  nb = 'nb',
  en = 'en',
}

export enum UserTypeEnum {
  Stingray = 'Stingray',
  Customer = 'Customer',
  ThirdParty = 'ThirdParty',
  Machine = 'Machine',
  Test = 'Test'
}

export type UserPermission = Permission & {
  userExternalId: string,
};

export type UserGroup = Omit<Group, 'permissions'>;
export type User = {
  auth0Id: string
  externalId: string,
  email: string,
  name: string,
  phone: string,
  userType: UserTypeEnum,
  country: CountryEnum,
  language: LanguageEnum,
  address: string,
  mfaRequired: boolean,
  enabled: boolean,
  regTms: string,
  companyCodes: Array<number>,
  groups: Array<UserGroup>,
  userSpecificPermissions: Array<UserPermission>
};

export type UserInput = {
  externalId?: string,
  email: string,
  name: string,
  phone: string,
  userType: UserTypeEnum,
  country: CountryEnum,
  language: LanguageEnum,
  address: string,
  mfaRequired: boolean,
  enabled: boolean,
  companyCodes: Array<number>,
  groupExternalIds: Array<string>,
  userSpecificPermissions?: Array<UserPermission>;
};

export type UserPasswordResetInput = {
  email: string
};

export type UserPasswordResetResponse = {
  text: string
};
