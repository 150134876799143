import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import MaterialTable, { Action } from 'material-table';
import { customerSelectors, locationSelectors } from 'features/fleet/fleetSlice';
import { LocalityTypeEnum, Permission } from 'features/common/types';
import { resourceSelectors } from 'features/resources/resourcesSlice';
import { resourceGroupSelectors } from 'features/resources/resourceGroupsSlice';
import { accessRightSelectors } from 'features/accessRights/accessRightsSlice';
import { parentGroupSelectors } from 'features/groups/parentGroupsSlice';
import { groupSelectors } from 'features/groups/groupsSlice';
import { User } from './types';

type TableDataEntry = {
  resourceGroupName: string,
  resourceName: string,
  groupName: string,
  localityType: LocalityTypeEnum,
  localityName: string,
  accessRightName: string,
};

type PermissionOverviewProps = {
  user: User,
  actions?: Array<Action<TableDataEntry>>,
  grouping?: boolean
};

const PermissionOverview = (props: PermissionOverviewProps) => {
  const { user, actions, grouping } = props;

  const groupLookup = useSelector(groupSelectors.selectEntities);
  const parentGroupLookup = useSelector(parentGroupSelectors.selectEntities);
  const resourceLookup = useSelector(resourceSelectors.selectEntities);
  const resourceGroupLookup = useSelector(resourceGroupSelectors.selectEntities);
  const customerLookup = useSelector(customerSelectors.selectEntities);
  const locationLookup = useSelector(locationSelectors.selectEntities);
  const accessRightLookup = useSelector(accessRightSelectors.selectEntities);

  const tableData = useMemo(() => {
    const result: Array<TableDataEntry> = [];

    const permissionToDataEntry = (
      permission: Permission,
      groupName: string,
    ) => {
      const {
        accessRightExternalId, localityType, localityCode, resourceExternalId,
      } = permission;

      let localityName = '';
      if (localityType === LocalityTypeEnum.NotApplicable || localityType === LocalityTypeEnum.Global) {
        localityName = '-';
      } else if (!localityCode) {
        localityName = 'Unknown';
      } else if (localityType === LocalityTypeEnum.Customer) {
        localityName = customerLookup[localityCode]?.name || 'Unknown';
      } else if (localityType === LocalityTypeEnum.Location) {
        localityName = locationLookup[localityCode]?.name || 'Unknown';
      }

      const resource = resourceLookup[resourceExternalId];
      const resourceGroup = resource ? resourceGroupLookup[resource.resourceGroupExternalId] : undefined;

      return {
        groupName,
        localityType,
        localityName,
        accessRightName: accessRightLookup[accessRightExternalId]?.name || 'Unknown',
        resourceName: resource?.name || 'Unknown',
        resourceGroupName: resourceGroup?.name || 'Unknown',
        externalId: permission.externalId,
      };
    };

    const now = new Date();
    user.groups.forEach(({ externalId }) => {
      const group = groupLookup[externalId];
      if (!group) {
        return;
      }

      const groupName = group.name;
      const parentGroupName = parentGroupLookup[group.parentGroupExternalId]?.name || 'Unknown';

      group.permissions
        .filter((p) => !p.validTo || new Date(p.validTo) > now)
        .forEach((permission) => {
          result.push(permissionToDataEntry(permission, `${parentGroupName} > ${groupName}`));
        });
    });

    user.userSpecificPermissions.filter((p) => !p.validTo || new Date(p.validTo) > now).forEach((permission) => {
      result.push(permissionToDataEntry(permission, 'User permission'));
    });
    return result;
  }, [
    accessRightLookup, customerLookup, groupLookup, locationLookup,
    parentGroupLookup, resourceGroupLookup, resourceLookup, user.groups, user.userSpecificPermissions,
  ]);
  return (
    <MaterialTable
      title="Permission overview"
      data={tableData}
      columns={[
        { title: 'Resource group', field: 'resourceGroupName', defaultGroupOrder: 0 },
        { title: 'Resource', field: 'resourceName' },
        { title: 'Locality type', field: 'localityType' },
        { title: 'Locality', field: 'localityName' },
        { title: 'Access right', field: 'accessRightName' },
        { title: 'Group', field: 'groupName' },
      ]}
      options={{
        paging: false,
        padding: 'dense',
        grouping: !!grouping,
        actionsColumnIndex: -1,
      }}
      actions={actions}
    />
  );
};
export default React.memo(PermissionOverview);
