import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  TextField, Paper, Box, Menu, MenuItem, IconButton,
} from '@material-ui/core';
import { Dictionary, sortBy } from 'lodash';
import { User, UserTypeEnum, UserInput } from 'features/users/types';
import { Add } from '@material-ui/icons';
import { groupSelectors } from './groupsSlice';
import { parentGroupSelectors } from './parentGroupsSlice';
import { Group } from './types';

type GroupOption = Group & { parentGroup: string };

type GroupSelectorProps = {
  externalIds: Array<string>,
  onChange: (value: Array<string>) => void,
  user: User | UserInput,
};

const GroupSelect = (props: GroupSelectorProps) => {
  const { externalIds, onChange, user } = props;
  const groups = useSelector(groupSelectors.selectAll);
  const parentGroupLookup = useSelector(parentGroupSelectors.selectEntities);

  const selectOptions = useMemo<Array<GroupOption>>(() => {
    let filteredGroups = groups;

    if (user.userType === UserTypeEnum.Stingray) {
      // stingray user, show only non-customer groups
      filteredGroups = filteredGroups.filter((group) => {
        const { parentGroupExternalId } = group;
        const parentGroup = parentGroupLookup[parentGroupExternalId];
        return !parentGroup?.companyCode;
      });
    } else if (user.userType === UserTypeEnum.Customer || user.userType === UserTypeEnum.ThirdParty) {
      // customer and third-party users
      // showing linked customers' groups
      filteredGroups = filteredGroups.filter((group) => {
        const { parentGroupExternalId } = group;
        const parentGroup = parentGroupLookup[parentGroupExternalId];
        return parentGroup?.companyCode && user.companyCodes.includes(parentGroup.companyCode);
      });
    } else if (user.userType === UserTypeEnum.Machine) {
      // machine users
      // non-customer groups + linked customers' groups
      filteredGroups = filteredGroups.filter((group) => {
        const { parentGroupExternalId } = group;
        const parentGroup = parentGroupLookup[parentGroupExternalId];

        const isNonCustomerGroup = !parentGroup?.companyCode;
        const isLinkedCustomerGroup = parentGroup?.companyCode
          && user.companyCodes.includes(parentGroup.companyCode);

        return isNonCustomerGroup || isLinkedCustomerGroup;
      });
    }

    const options = filteredGroups.map((g) => ({
      ...g,
      parentGroup: parentGroupLookup[g.parentGroupExternalId]?.name || 'Unknown',
    }));
    const sorted = sortBy(options, [(o) => o.parentGroup, (o) => o.name]);
    return sorted;
  }, [groups, parentGroupLookup, user]);

  const selectOptionLookup = useMemo(() => {
    const result: Dictionary<GroupOption> = {};
    selectOptions.forEach((o) => {
      result[o.externalId] = o;
    });

    return result;
  }, [selectOptions]);

  // converting input: [externalId] to [Group]
  const selectedOptions = useMemo(() => {
    const result: Array<GroupOption> = [];
    externalIds.forEach((externalId) => {
      const option = selectOptionLookup[externalId];
      if (option) {
        result.push(option);
      }
    });

    return result;
  }, [externalIds, selectOptionLookup]);

  const handleChange = (_: any, value: Array<Group>) => {
    onChange(value.map((group) => group.externalId));
  };

  const [anchorEl, setAnchorEl] = React.useState<EventTarget & HTMLButtonElement | null>(null);
  const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget), []);

  const handleCloseMenu = useCallback(() => setAnchorEl(null), []);

  const handleAddItems = useCallback((option: 'all' | 'basic') => {
    if (option === 'all') {
      onChange(selectOptions.map((o) => o.externalId));
    }

    if (option === 'basic') {
      onChange(selectOptions
        .filter((o) => !o.name.toLowerCase().endsWith('nav'))
        .map((o) => o.externalId));
    }

    handleCloseMenu();
  }, [handleCloseMenu, onChange, selectOptions]);

  return (
    <Box display="flex" alignItems="center" width="100%">
      <Autocomplete
        options={selectOptions}
        getOptionLabel={(option) => option.name}
        groupBy={(option) => option.parentGroup}
        fullWidth
        multiple
        disableCloseOnSelect
        value={selectedOptions}
        onChange={handleChange}
        // eslint-disable-next-line react/jsx-props-no-spreading
        PaperComponent={(paperProps) => <Paper {...paperProps} elevation={8} />}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            label="Groups"
            variant="outlined"
          />
        )}
      />
      <Box mx={1}>
        <IconButton size="small" onClick={handleOpenMenu}>
          <Add fontSize="small" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={() => handleAddItems('all')}>All groups</MenuItem>
          <MenuItem onClick={() => handleAddItems('basic')}>Basic groups</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default React.memo(GroupSelect);
