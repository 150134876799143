/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { VariantType } from 'notistack';
import { FailurePayload } from 'core/types';

export type Notification = {
  key: string,
  title: string,
  text: string,
  variant: VariantType,
};

type NotificationsState = {
  notifications: Array<Notification>,
};

const initialState: NotificationsState = {
  notifications: [],
};

function notifyFailureActionMatcher(action: AnyAction): action is PayloadAction<FailurePayload> {
  try {
    const payload = action.payload as FailurePayload;
    return Boolean(payload.text && payload.title && payload.notify);
  } catch {
    return false;
  }
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    enqueueNotification: (state, action: PayloadAction<Notification>) => {
      state.notifications.push(action.payload);
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter((n) => n.key !== action.payload);
    },
  },
  // creating notifications for actions with 'FailurePayload' payload and with 'notify = true'
  extraReducers: (builder) => builder
    .addMatcher(
      notifyFailureActionMatcher,
      (state, action) => {
        state.notifications.push({
          key: `${action.type}_${Date.now()}`,
          title: action.payload.title,
          text: action.payload.text,
          variant: 'error',
        });
      },
    ),
});

export const { enqueueNotification, removeNotification } = notificationsSlice.actions;

export const selectNotifications = (state: RootState) => state.notifications.notifications;

export default notificationsSlice.reducer;
