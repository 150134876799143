import React from 'react';
import {
  makeStyles, Button,
} from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingScreen from 'features/layout/LoadingScreen';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
  },
  brandingWrapper: {
    height: 100,
    marginBottom: theme.spacing(3),
  },
  image: {
    height: '100%',
  },
}));

const SignIn = () => {
  const classes = useStyles();

  const { isLoading, loginWithPopup } = useAuth0();

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.brandingWrapper}>
        <img className={classes.image} src="/branding.png" alt="stingray logo" />
      </div>
      <Button variant="contained" color="primary" onClick={() => { loginWithPopup(); }}>Sign In</Button>
    </div>
  );
};

export default SignIn;
