import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import MaterialTable, { Column } from 'material-table';
import { resourceSelectors } from 'features/resources/resourcesSlice';
import { resourceGroupSelectors } from 'features/resources/resourceGroupsSlice';
import { customerSelectors } from 'features/fleet/fleetSlice';
import { RootState } from 'app/store';
import { Box } from '@material-ui/core';
import useAccessRights from 'features/accessRights/useAccessRights';
import { Location } from 'features/fleet/types';
import nameof from 'core/nameof';
import PermissionSwitch from 'features/common/PermissionSwitch';
import { LocalityTypeEnum } from 'features/common/types';
import { Resource } from 'features/resources/types';
import { Dictionary } from '@reduxjs/toolkit';
import { groupBy } from 'lodash';
import { groupSelectors } from '../groupsSlice';
import { Group, GroupTypeEnum } from '../types';
import PermissionCount from '../PermissionCount';

type TableDataEntry = {
  resourceName: string,
  resourceExternalId: string,
  resourceGroupName: string,
  resourceGroupExternalId: string,
  resource: Resource,
};

type CustomerGroupPermissionsProps = {
  group: Group,
  companyCode: number,
};

const CustomerGroupPermissions = (props: CustomerGroupPermissionsProps) => {
  const { group, companyCode } = props;
  const { groupType } = group;
  const disabled = groupType !== GroupTypeEnum.Custom;

  const resources = useSelector(resourceSelectors.selectAll);
  const resourceGroupsLookup = useSelector(resourceGroupSelectors.selectEntities);

  const [ReadAccessRight, ReadWriteAccessRight] = useAccessRights();

  const customer = useSelector((state: RootState) => customerSelectors.selectById(state, companyCode));

  // displaying only 'location specific' resources.
  const tableData = useMemo(
    () => resources.filter((r) => r.localitySpecific).map<TableDataEntry>((r) => {
      const parentGroupName = resourceGroupsLookup[r.resourceGroupExternalId]?.name || 'Unknown';
      return {
        resourceName: r.name.replace(parentGroupName, ''),
        resourceExternalId: r.externalId,
        resourceGroupName: parentGroupName,
        resourceGroupExternalId: r.resourceGroupExternalId,
        resource: r,
      };
    }),
    [resourceGroupsLookup, resources],
  );

  const accessStatisticsByResourceGroup = useMemo(() => {
    const customerLocations = customer?.locations.length || 0;
    const maxNumberOfPermission = customerLocations + 1;

    const result: Dictionary<{ noAccess: number, read: number, readWrite: number }> = {};

    const now = new Date();
    const groupPermissionsByResource = groupBy(
      group.permissions.filter((p) => !p.validTo || new Date(p.validTo) > now),
      (p) => p.resourceExternalId,
    );

    resources.forEach((resource) => {
      const { resourceGroupExternalId } = resource;
      const groupPermissions = groupPermissionsByResource[resource.externalId] || [];

      const readCount = groupPermissions.filter((p) => p.accessRightExternalId === ReadAccessRight.externalId).length;
      const readWriteCount = groupPermissions.filter(
        (p) => p.accessRightExternalId === ReadWriteAccessRight.externalId,
      ).length;
      const noAccessCount = maxNumberOfPermission - readCount - readWriteCount;

      const statGroup = result[resourceGroupExternalId];
      if (!statGroup) {
        result[resourceGroupExternalId] = { noAccess: noAccessCount, read: readCount, readWrite: readWriteCount };
      } else {
        statGroup.noAccess += noAccessCount;
        statGroup.read += readCount;
        statGroup.readWrite += readWriteCount;
      }
    });
    return result;
  }, [ReadAccessRight.externalId, ReadWriteAccessRight.externalId, customer, group.permissions, resources]);

  const tableColumns = useMemo(() => {
    const locations: Array<Location> = customer?.locations || [];
    let result: Array<Column<TableDataEntry>> = locations.map((location) => ({
      title: location.name,
      type: 'string',
      headerStyle: { whiteSpace: 'nowrap' },
      render: (rowData) => (
        <Box display="flex">
          <PermissionSwitch
            disabled={disabled}
            hideName
            group={group}
            localityType={LocalityTypeEnum.Location}
            resource={rowData.resource}
            localityCode={location.code}
          />
        </Box>
      ),
    }));

    result = [
      {
        title: 'Group',
        type: 'string',
        field: nameof<TableDataEntry>('resourceGroupExternalId'),
        render: (rowData: any) => (
          <>
            <span>{resourceGroupsLookup[rowData]?.name}</span>
            <PermissionCount
              read={accessStatisticsByResourceGroup[rowData]?.read || 0}
              readWrite={accessStatisticsByResourceGroup[rowData]?.readWrite || 0}
            />
          </>
        ),
        defaultGroupOrder: 1,
      },
      {
        title: 'Resource',
        type: 'string',
        field: nameof<TableDataEntry>('resourceName'),
      },
      {
        title: 'All locations',
        type: 'string',
        render: (rowData) => (
          <Box display="flex">
            <PermissionSwitch
              disabled={disabled}
              hideName
              group={group}
              localityType={LocalityTypeEnum.Customer}
              resource={rowData.resource}
              localityCode={companyCode}
            />
          </Box>
        ),
      },
      ...result,
    ];
    return result;
  }, [accessStatisticsByResourceGroup, customer, companyCode, disabled, group, resourceGroupsLookup]);

  const isGroupUpdating = useSelector((state: RootState) => groupSelectors.selectUpdatingById(state, group.externalId));
  return (
    <MaterialTable
      isLoading={isGroupUpdating}
      title="Permissions"
      data={tableData}
      columns={tableColumns}
      options={{
        paging: false,
        padding: 'dense',
      }}
    />
  );
};

export default React.memo(CustomerGroupPermissions);
