import { Permission } from 'features/common/types';

export type ParentGroup = {
  externalId: string,
  name: string,
  description: string,
  companyCode: number | null,
  regTms: string
};

export type ParentGroupInput = {
  name: string,
  description: string,
  companyCode: number | null,
  externalId?: string,
};

export type GroupPermission = Permission & {
  groupExternalId: string,
};

export enum GroupTypeEnum {
  Undefined = 'Undefined',
  Custom = 'Custom',
  AcademyBasic = 'AcademyBasic',
  AcademyStingrayOnline = 'AcademyStingrayOnline',
  AcademyNavigator = 'AcademyNavigator',
  AcademyFishHealth = 'AcademyFishHealth',
  StingrayOnlineBasic = 'StingrayOnlineBasic',
  StingrayOnlineFishHealth = 'StingrayOnlineFishHealth',
  Navigator = 'Navigator',
}

export type Group = {
  externalId: string,
  name: string,
  description: string,
  parentGroupExternalId: string,
  permissions: Array<GroupPermission>,
  groupType: GroupTypeEnum,
  regTms: string,
};

export type GroupInput = {
  externalId?: string,
  name: string,
  description: string,
  parentGroupExternalId: string,
  permissions: Array<GroupPermission>,
  groupType: GroupTypeEnum,
};

export type GroupTemplateInput = {
  externalId?: string,
  parentGroupExternalId: string,
  groupType: GroupTypeEnum,
  locationCode: number,
};

export enum GroupFamilyEnum {
  CustomerGroup = 'CustomerGroup',
  NonCustomerGroup = 'NonCustomerGroup',
}
