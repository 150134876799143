import React, { useState, useEffect } from 'react';
import {
  Button, Dialog, DialogContent, DialogTitle, DialogActions,
} from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';

type ParsedAccessTokenDialogProps = {
  open: boolean,
  onClose: () => void,
};

const parseJwt = (token: string) => {
  try {
    const json = JSON.parse(atob(token.split('.')[1]));
    return JSON.stringify(json, null, 2);
  } catch (e) {
    return 'Failed to parse token.';
  }
};

const AccessTokenInfoDialog = (props: ParsedAccessTokenDialogProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const { open, onClose } = props;

  // refreshing access token when dialog is opened
  // clearing when dialog is closed
  const [accessTokenInfo, setAccessTokenInfo] = useState('');
  useEffect(() => {
    const refreshAccessTokenInfo = async () => {
      const accessToken = await getAccessTokenSilently();
      setAccessTokenInfo(parseJwt(accessToken));
    };
    if (open) {
      refreshAccessTokenInfo();
    } else {
      setAccessTokenInfo('');
    }
  }, [getAccessTokenSilently, open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
    >
      <DialogTitle color="inherit">Access token info</DialogTitle>
      <DialogContent>
        <pre style={{ fontSize: 16 }}>{accessTokenInfo}</pre>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccessTokenInfoDialog;
