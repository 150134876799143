import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import NavigateBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  navigateBackIcon: {
    fontSize: 16,
    marginRight: theme.spacing(0.5),
  },
}));

type NotFoundContentProps = {
  navigateBackUrl: string,
  navigateBackText: string,
  notFoundText: string
};

const NavigateBackNotFound = (props: NotFoundContentProps) => {
  const { navigateBackText, navigateBackUrl, notFoundText } = props;
  const classes = useStyles();
  return (
    <div>
      <Box display="flex" flexDirection="column" mb={3}>
        <Link to={navigateBackUrl} className={classes.link}>
          <Box display="flex" alignItems="center">
            <NavigateBackIcon className={classes.navigateBackIcon} />
            <Typography variant="body2">{navigateBackText}</Typography>
          </Box>
        </Link>
      </Box>
      <Box display="flex" justifyContent="center" width="100%">
        <Typography variant="h2">{notFoundText}</Typography>
      </Box>
    </div>
  );
};

export default React.memo(NavigateBackNotFound);
