/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import {
  createSlice, PayloadAction, createEntityAdapter, EntityState,
} from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { FailurePayload } from 'core/types';
import { User, UserInput } from './types';
import * as api from './api';
import { mapUserToUserInput } from './helpers';

const usersAdapter = createEntityAdapter<User>({
  selectId: (user) => user.externalId,
  sortComparer: (a, b) => (a.name > b.name ? 1 : -1),
});

type UsersState = EntityState<User> & {
  isFetching: boolean,
  error: string | null,
};

const initialState: UsersState = usersAdapter.getInitialState({
  isFetching: false,
  error: null,
});

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.isFetching = true;
    },
    fetchFailure: (state, action: PayloadAction<FailurePayload>) => {
      state.isFetching = false;
      state.error = action.payload.text;
    },
    fetchSuccess: (state, action: PayloadAction<Array<User>>) => {
      state.isFetching = false;
      state.error = null;
      usersAdapter.setAll(state, action.payload);
    },
    createSuccess: (state, action: PayloadAction<User>) => {
      state.error = null;
      usersAdapter.addOne(state, action.payload);
    },
    createFailure: (state, action: PayloadAction<FailurePayload>) => {
      state.error = action.payload.text;
    },
    updateSuccess: (state, action: PayloadAction<User>) => {
      state.error = null;
      usersAdapter.upsertOne(state, action.payload);
    },
    updateFailure: (state, action: PayloadAction<FailurePayload>) => {
      state.error = action.payload.text;
    },
    deleteSuccess: (state, action: PayloadAction<string>) => {
      state.error = null;
      usersAdapter.removeOne(state, action.payload);
    },
    deleteFailure: (state, action: PayloadAction<FailurePayload>) => {
      state.error = action.payload.text;
    },
  },
});

// actions
export const {
  fetchStart, fetchFailure, fetchSuccess,
  createSuccess, createFailure,
  updateSuccess, updateFailure,
  deleteSuccess, deleteFailure,
} = usersSlice.actions;

// selectors
const sequencesSelectors = usersAdapter.getSelectors((state: RootState) => state.users);
const selectIsFetching = (state: RootState) => state.users.isFetching;
const selectError = (state: RootState) => state.users.error;

export const userSelectors = {
  ...sequencesSelectors,
  selectIsFetching,
  selectError,
};

// action creators
export const fetchUsers = (accessToken: string): AppThunk => async (dispatch, getState) => {
  // avoid race conditions
  const isFetching = selectIsFetching(getState());
  if (isFetching) {
    return;
  }

  dispatch(fetchStart());

  try {
    const users = await api.getUsers(accessToken);
    dispatch(fetchSuccess(users));
  } catch (err) {
    // trying to get error message from exception.
    const text = typeof (err as any).message === 'string' ? (err as any).message : 'Unknown error';

    dispatch(fetchFailure({
      notify: true,
      title: 'Failed to load users',
      text,
    }));
  }
};

export const createUser = (
  userIn: UserInput,
  accessToken: string,
): AppThunk => async (dispatch) => {
  try {
    const user = await api.createUser(userIn, accessToken);
    dispatch(createSuccess(user));
  } catch (err) {
    // trying to get error message from exception.
    const text = typeof (err as any).message === 'string' ? (err as any).message : 'Unknown error';

    dispatch(createFailure({
      notify: true,
      title: 'Failed to create user',
      text,
    }));
  }
};

export const updateUser = (
  userIn: UserInput,
  accessToken: string,
): AppThunk => async (dispatch) => {
  try {
    const user = await api.updateUser(userIn, accessToken);
    dispatch(updateSuccess(user));
  } catch (err) {
    // trying to get error message from exception.
    const text = typeof (err as any).message === 'string' ? (err as any).message : 'Unknown error';

    dispatch(updateFailure({
      notify: true,
      title: 'Failed to update user',
      text,
    }));
  }
};

export const deleteUser = (
  externalId: string,
  accessToken: string,
): AppThunk => async (dispatch) => {
  try {
    await api.deleteUser(externalId, accessToken);
    dispatch(deleteSuccess(externalId));
  } catch (err) {
    // trying to get error message from exception.
    const text = typeof (err as any).message === 'string' ? (err as any).message : 'Unknown error';

    dispatch(deleteFailure({
      notify: true,
      title: 'Failed to delete user',
      text,
    }));
  }
};

export const removeUserFromGroup = (
  userExternalId: string,
  groupExternalId: string,
  accessToken: string,
): AppThunk => async (dispatch, getState) => {
  try {
    const existingUser = userSelectors.selectById(getState(), userExternalId);

    // user does not exist
    if (!existingUser) {
      return;
    }

    // user is not a group member
    if (existingUser.groups.findIndex((g) => g.externalId === groupExternalId) < 0) {
      return;
    }

    // mapping user to userInput and removing group
    let userInput = mapUserToUserInput(existingUser);
    userInput = {
      ...userInput,
      groupExternalIds: userInput.groupExternalIds.filter((id) => id !== groupExternalId),
    };

    const updatedUser = await api.updateUser(userInput, accessToken);
    dispatch(updateSuccess(updatedUser));
  } catch (err) {
    // trying to get error message from exception.
    const text = typeof (err as any).message === 'string' ? (err as any).message : 'Unknown error';

    dispatch(updateFailure({
      notify: true,
      title: 'Failed to update user',
      text,
    }));
  }
};

export const addUserToGroup = (
  userExternalId: string,
  groupExternalId: string,
  accessToken: string,
): AppThunk => async (dispatch, getState) => {
  try {
    const existingUser = userSelectors.selectById(getState(), userExternalId);

    // user does not exist
    if (!existingUser) {
      return;
    }

    // user is already a group member
    if (existingUser.groups.findIndex((g) => g.externalId === groupExternalId) >= 0) {
      return;
    }

    // mapping user to userInput and adding the new group
    let userInput = mapUserToUserInput(existingUser);
    userInput = {
      ...userInput,
      groupExternalIds: [
        ...userInput.groupExternalIds,
        groupExternalId,
      ],
    };

    const updatedUser = await api.updateUser(userInput, accessToken);
    dispatch(updateSuccess(updatedUser));
  } catch (err) {
    // trying to get error message from exception.
    const text = typeof (err as any).message === 'string' ? (err as any).message : 'Unknown error';

    dispatch(updateFailure({
      notify: true,
      title: 'Failed to update user',
      text,
    }));
  }
};

export const addUserToCustomer = (
  userExternalId: string,
  companyCode: number,
  accessToken: string,
): AppThunk => async (dispatch, getState) => {
  try {
    const existingUser = userSelectors.selectById(getState(), userExternalId);

    // user does not exist
    if (!existingUser) {
      return;
    }

    // user is already linked to customer
    if (existingUser.companyCodes.findIndex((c) => c === companyCode) >= 0) {
      return;
    }

    // mapping user to userInput and adding the new group
    let userInput = mapUserToUserInput(existingUser);
    userInput = {
      ...userInput,
      companyCodes: [
        ...userInput.companyCodes,
        companyCode,
      ],
    };

    const updatedUser = await api.updateUser(userInput, accessToken);
    dispatch(updateSuccess(updatedUser));
  } catch (err) {
    // trying to get error message from exception.
    const text = typeof (err as any).message === 'string' ? (err as any).message : 'Unknown error';

    dispatch(updateFailure({
      notify: true,
      title: 'Failed to update user',
      text,
    }));
  }
};

export default usersSlice.reducer;
