import React from 'react';
import {
  makeStyles, Container, Typography,
} from '@material-ui/core';
import ResourceList from './ResourceList';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
}));

const ResourcesComponent = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Typography variant="h4" gutterBottom>Resources</Typography>
      <ResourceList />
    </Container>
  );
};

export default React.memo(ResourcesComponent);
