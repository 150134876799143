import React, { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  Button,
  ButtonGroup,
  Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, Grid, LinearProgress, makeStyles, TextField,
} from '@material-ui/core';
import ReadIcon from '@material-ui/icons/Visibility';
import WriteIcon from '@material-ui/icons/Edit';
import { Autocomplete } from '@material-ui/lab';

import { LocalityTypeEnum, Permission } from 'features/common/types';
import { User, UserPermission } from 'features/users/types';
import { resourceSelectors } from 'features/resources/resourcesSlice';
import { Resource, ResourceGroup } from 'features/resources/types';
import { resourceGroupSelectors } from 'features/resources/resourceGroupsSlice';

import useAccessRights from 'features/accessRights/useAccessRights';
import { customerSelectors } from 'features/fleet/fleetSlice';
import { Location } from 'features/fleet/types';
import { AccessRightName } from 'features/accessRights/types';
import CustomerSelectSingle from 'features/fleet/CustomerSelectSingle';
import useSaveUserWithoutGroup from './useSaveUserWithoutGroup';
import { getDefaultUserInputs } from './helpers';

type PermissionDialogProps = {
  user: User,
  onClose: () => void;
};

type TmpPermissionDialogState = {
  resourceGroup: string;
  companyCode: number;
  localitySpecific: boolean;
};

const useStyles = makeStyles((theme) => ({
  content: {
    width: theme.breakpoints.values.sm,
  },
}));

const getEmptyUserPermission = (userExternalId: string, readAccessId: string): UserPermission => {
  const dateNow = new Date();
  const endOfTheDay = moment().endOf('day').toDate();

  return {
    userExternalId,
    externalId: uuidv4(),
    resourceExternalId: '',
    accessRightExternalId: readAccessId,
    localityType: LocalityTypeEnum.Global,
    localityCode: null,
    recTmsFrom: null,
    recTmsTo: null,
    validFrom: dateNow.toISOString(),
    validTo: endOfTheDay.toISOString(),
    regTms: new Date().toISOString(),
  };
};

const PermissionDialog = (props: PermissionDialogProps) => {
  const classes = useStyles();
  const { onClose, user } = props;

  const resourceGroups = useSelector(resourceGroupSelectors.selectAll);
  const resources = useSelector(resourceSelectors.selectAll);
  const customerLookup = useSelector(customerSelectors.selectEntities);

  const [ReadAccessRight, ReadWriteAccessRight] = useAccessRights();

  const [inputs, setInputs] = useState(getDefaultUserInputs(user.userType, user));
  // eslint-disable-next-line max-len
  const [tmpPermission, setPermission] = useState<UserPermission>(getEmptyUserPermission(user.externalId, ReadAccessRight.externalId));
  const [tmpState, setTmpState] = useState<TmpPermissionDialogState>({
    resourceGroup: '',
    companyCode: 0,
    localitySpecific: false,
  });
  const [saving, handleSavingUser] = useSaveUserWithoutGroup('edit', onClose);

  const { localityType } = tmpPermission;
  const { companyCode } = tmpState;
  const locations = useMemo(() => {
    if (localityType === LocalityTypeEnum.Global || localityType === LocalityTypeEnum.NotApplicable) {
      return [];
    }
    return customerLookup[companyCode]?.locations ?? [];
  }, [localityType, companyCode, customerLookup]);

  const validNewPermission = useMemo(() => {
    if (tmpPermission.resourceExternalId === '') {
      return false;
    }
    setInputs((u) => ({
      ...u,
      userSpecificPermissions:
        (user.userSpecificPermissions ? [...user.userSpecificPermissions, tmpPermission] : [tmpPermission]),
    }));
    return true;
  }, [tmpPermission, user]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && e.ctrlKey) {
      e.preventDefault();
      handleSavingUser(inputs);
    } else if (e.key === 'Escape') {
      e.preventDefault();
      onClose();
    }
  };

  const handleInputPermissionChange = (
    k: keyof (Permission),
    value: string | Array<string> | boolean,
  ) => {
    setPermission((p) => ({
      ...p,
      [k]: value,
    }));
  };

  const onTmpStateChange = (
    k: keyof (TmpPermissionDialogState),
    value: string | boolean,
  ) => {
    setTmpState((p) => ({
      ...p,
      [k]: value,
    }));
  };

  const handleCustomerChange = (companyCodeValue: number) => {
    const value = customerLookup[companyCodeValue];
    if (value) {
      setTmpState((s) => ({
        ...s,
        companyCode: companyCodeValue,
      }));
      setPermission((p) => ({
        ...p,
        localityType: LocalityTypeEnum.Customer,
        localityCode: value.code,
      }));
    } else {
      setTmpState((s) => ({
        ...s,
        companyCode: 0,
      }));
      setPermission((p) => ({
        ...p,
        localityType: LocalityTypeEnum.Global,
        localityCode: null,
      }));
    }
  };

  const handleAddPermission = () => {
    if (!validNewPermission || saving) {
      return;
    }
    handleSavingUser(inputs);
  };

  const handleChangeResource = (event: object, value: Resource | null) => {
    setPermission((p) => ({
      ...p,
      resourceExternalId: value?.externalId ?? '',
      localityCode: (value?.localitySpecific ?? false) ? p.localityCode : null,
      localityType: (value?.localitySpecific ?? false) ? p.localityType : LocalityTypeEnum.Global,
    }));
    setTmpState((s) => ({
      ...s,
      localitySpecific: value?.localitySpecific ?? false,
      companyCode: (value?.localitySpecific ?? false) ? s.companyCode : 0,
    }));
  };

  const handleLocationChange = (locationValue: Location | null) => {
    if (locationValue) {
      setPermission((p) => ({
        ...p,
        localityType: LocalityTypeEnum.Location,
        localityCode: locationValue.code,
      }));
    } else {
      const customerValue = customerLookup[tmpState.companyCode];
      handleCustomerChange(customerValue?.code ?? 0);
    }
  };

  return (
    <Dialog onClose={onClose} open maxWidth="sm" onKeyDown={handleKeyDown}>
      {saving && (<LinearProgress />)}
      <DialogTitle>Add user permission</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <Autocomplete
              id="search-resourcegroups"
              fullWidth
              options={resourceGroups}
              value={tmpState.resourceGroup ? resourceGroups.find((r) => r.externalId === tmpState.resourceGroup) : null}
              getOptionLabel={(option) => `${option.name}`}
              onChange={(_, value: ResourceGroup | null) => { onTmpStateChange('resourceGroup', value?.externalId ?? ''); }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="Search resource group"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item sm={6}>
            <Autocomplete
              id="search-resource"
              fullWidth
              disabled={!tmpState.resourceGroup}
              options={resources.filter((r) => r.resourceGroupExternalId === tmpState.resourceGroup)}
              getOptionLabel={(option) => `${option.name}`}
              onChange={handleChangeResource}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="Search resource"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item sm={6}>
            <CustomerSelectSingle
              companyCode={tmpState.companyCode}
              disabled={!tmpState.localitySpecific}
              onChange={(selectedSoCode) => { handleCustomerChange(selectedSoCode); }}
            />
          </Grid>
          <Grid item sm={6}>
            <Autocomplete
              id="search-location"
              fullWidth
              disabled={!tmpState.companyCode}
              options={locations}
              getOptionLabel={(option) => `${option.name}`}
              value={(tmpPermission.localityType === LocalityTypeEnum.Location)
                ? locations.find((loc) => loc.code === tmpPermission.localityCode)
                : null}
              getOptionSelected={(option) => tmpPermission.localityType === LocalityTypeEnum.Location
                && tmpPermission.localityCode === option.code}
              onChange={(event: object, value: Location | null) => { handleLocationChange(value); }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="Location"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <FormLabel>
              Access right
            </FormLabel>
          </Grid>
          <Grid item sm={12}>
            <ButtonGroup>
              <Button
                title={AccessRightName.Read}
                onClick={() => { handleInputPermissionChange('accessRightExternalId', ReadAccessRight.externalId); }}
                variant={tmpPermission.accessRightExternalId === ReadAccessRight.externalId ? 'contained' : 'outlined'}
                color={tmpPermission.accessRightExternalId === ReadAccessRight.externalId ? 'primary' : undefined}
              >
                <ReadIcon />
              </Button>
              <Button
                title={AccessRightName.ReadWrite}
                onClick={() => { handleInputPermissionChange('accessRightExternalId', ReadWriteAccessRight.externalId); }}
                variant={tmpPermission.accessRightExternalId === ReadWriteAccessRight.externalId ? 'contained' : 'outlined'}
                color={tmpPermission.accessRightExternalId === ReadWriteAccessRight.externalId ? 'primary' : undefined}
              >
                <WriteIcon />
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button color="primary" disabled={!validNewPermission} onClick={handleAddPermission}>
          {`Add ${tmpPermission.localityType} permission`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PermissionDialog;
