import React from 'react';
import {
  Button, Avatar, Divider, makeStyles,
} from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import ProfileCardMenu from './ProfileCardMenu';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 250,
  },
  menuWrapper: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    margin: theme.spacing(1),
    width: 64,
    height: 64,
    alignSelf: 'center',
  },
  info: {
    marginRight: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  divider: {
    margin: theme.spacing(1),
  },
  signOut: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  name: {
    fontWeight: 550,
    fontSize: 14,
  },
  email: {
    fontSize: 13,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}));

const ProfileCard = () => {
  const { user, logout } = useAuth0();
  const { name, email, picture } = user;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.menuWrapper}>
        <ProfileCardMenu />
      </div>
      <div className={classes.content}>
        <Avatar alt={name} src={picture} className={classes.avatar} />
        <div className={classes.info}>
          <span className={classes.name}>{name}</span>
          <span className={classes.email}>{email}</span>
        </div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.actions}>
        <Button
          variant="text"
          onClick={() => logout({ returnTo: window.origin })}
          size="small"
          className={classes.signOut}
        >
          Sign out
        </Button>
      </div>
    </div>
  );
};

export default React.memo(ProfileCard);
