/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import {
  createSlice, PayloadAction, createEntityAdapter, EntityState,
} from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { FailurePayload } from 'core/types';
import { AccessRight } from './types';
import { getAccessRights } from './api';

const accessRightsAdapter = createEntityAdapter<AccessRight>({
  selectId: (accessRight) => accessRight.externalId,
  sortComparer: (a, b) => (a.name > b.name ? 1 : -1),
});

type AccessRightsState = EntityState<AccessRight> & {
  isFetching: boolean,
  error: string | null,
};

const initialState: AccessRightsState = accessRightsAdapter.getInitialState({
  isFetching: false,
  error: null,
});

export const accessRightsSlice = createSlice({
  name: 'accessRights',
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.isFetching = true;
    },
    fetchFailure: (state, action: PayloadAction<FailurePayload>) => {
      state.isFetching = false;
      state.error = action.payload.text;
    },
    fetchSuccess: (state, action: PayloadAction<Array<AccessRight>>) => {
      state.isFetching = false;
      state.error = null;
      accessRightsAdapter.setAll(state, action.payload);
    },
  },
});

// actions
export const { fetchStart, fetchFailure, fetchSuccess } = accessRightsSlice.actions;

// selectors
const sequencesSelectors = accessRightsAdapter.getSelectors((state: RootState) => state.accessRights);
const selectIsFetching = (state: RootState) => state.accessRights.isFetching;
const selectError = (state: RootState) => state.accessRights.error;

export const accessRightSelectors = {
  ...sequencesSelectors,
  selectIsFetching,
  selectError,
};

// action creators
export const fetchAccessRights = (accessToken: string): AppThunk => async (dispatch, getState) => {
  // avoid race conditions
  const isFetching = selectIsFetching(getState());
  if (isFetching) {
    return;
  }

  dispatch(fetchStart());

  try {
    const accessRights = await getAccessRights(accessToken);
    dispatch(fetchSuccess(accessRights));
  } catch (err) {
    // trying to get error message from exception.
    const text = typeof (err as any).message === 'string' ? (err as any).message : 'Unknown error';

    dispatch(fetchFailure({
      notify: true,
      title: 'Failed to load access rights',
      text,
    }));
  }
};

export default accessRightsSlice.reducer;
