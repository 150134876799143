import React from 'react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import UserList from 'features/users/UserList';
import { makeStyles, Container } from '@material-ui/core';
import { UserTypeEnum } from 'features/users/types';
import UserDetails from 'features/users/UserDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
}));

const ThirdPartySection = () => {
  const classes = useStyles();

  const { path } = useRouteMatch();

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Switch>
        <Route exact path={`${path}`}>
          <UserList userType={UserTypeEnum.ThirdParty} showTitle />
        </Route>
        <Route exact path={`${path}/:userId`}>
          <UserDetails navigateBackContext={UserTypeEnum.ThirdParty} />
        </Route>
      </Switch>
    </Container>
  );
};

export default ThirdPartySection;
