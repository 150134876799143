import React from 'react';
import {
  Grow, Popper, ClickAwayListener, Paper, IconButton,
} from '@material-ui/core';
import AppsIcon from '@material-ui/icons/Apps';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import AppsCard from './AppsCard';

const useStyles = makeStyles(() => ({
  appsIcon: {
    fontSize: 45,
    color: grey[300],
  },
  button: {
    alignSelf: 'center',
  },
}));

const MenuContainer = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleItemClick = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        size="small"
        className={classes.button}
        ref={anchorRef}
        aria-owns={open ? 'apps-menu-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="inherit"
      >
        <AppsIcon className={classes.appsIcon} />
      </IconButton>

      <Popper open={open} anchorEl={anchorRef.current} transition placement="top-end">
        {({ TransitionProps }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Grow {...TransitionProps}>
            <Paper id="apps-menu-grow">
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <AppsCard onItemClick={handleItemClick} />
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default React.memo(MenuContainer);
