import { useParams } from 'react-router-dom';
import React, { useMemo } from 'react';
import { RootState } from 'app/store';
import { useSelector } from 'react-redux';
import {
  Box, Typography, Grid, Chip,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CrossIcon from '@material-ui/icons/Close';
import NavigateBackNotFound from 'features/common/NavigateBackNotFound';
import NavigateBackHeader from 'features/common/NavigateBackHeader';
import { routeMap } from 'features/layout/Navigation';
import { stingrayPages } from 'features/stingray/StingraySection';
import TestUserCopyPermissions from 'features/testusers/TestUserCopyPermissions';
import TestUserPermissionsOverview from 'features/testusers/TestUserPermissionsOverview';
import { userSelectors } from './usersSlice';
import UserProfile from './UserProfile';
import UserGroups from './UserGroups';
import PermissionOverview from './PermissionOverview';
import { UserTypeEnum } from './types';

type UserDetailsProps = {
  navigateBackContext: UserTypeEnum,
};

const UserDetails = (props: UserDetailsProps) => {
  const { userId, companyCode } = useParams<{ userId: string, companyCode?: string }>();

  const { navigateBackUrl, navigateBackText } = useMemo(() => {
    const { navigateBackContext } = props;
    if (navigateBackContext === UserTypeEnum.Stingray) {
      return {
        navigateBackUrl: `${routeMap.stingray.baseUrl}/${stingrayPages.users.value}`,
        navigateBackText: 'Back to Users',
      };
    }

    if (navigateBackContext === UserTypeEnum.Customer) {
      return {
        navigateBackUrl: `${routeMap.customer.baseUrl}/${companyCode}`,
        navigateBackText: 'Back to Customer',
      };
    }

    if (navigateBackContext === UserTypeEnum.ThirdParty) {
      return {
        navigateBackUrl: `${routeMap.third_party.baseUrl}`,
        navigateBackText: 'Back to Third-party users',
      };
    }

    if (navigateBackContext === UserTypeEnum.Machine) {
      return {
        navigateBackUrl: `${routeMap.machine.baseUrl}`,
        navigateBackText: 'Back to Machine users',
      };
    }

    if (navigateBackContext === UserTypeEnum.Test) {
      return {
        navigateBackUrl: `${routeMap.testusers.baseUrl}`,
        navigateBackText: 'Back to Test users',
      };
    }

    return {
      navigateBackUrl: '',
      navigateBackText: '',
    };
  }, [companyCode, props]);

  const user = useSelector((state: RootState) => userSelectors.selectById(state, userId));

  const userTypeInfo = useMemo(() => {
    switch (user?.userType) {
      case UserTypeEnum.Stingray:
        return routeMap.stingray;
      case UserTypeEnum.Customer:
        return routeMap.customer;
      case UserTypeEnum.ThirdParty:
        return routeMap.third_party;
      case UserTypeEnum.Test:
        return routeMap.testusers;
      default:
        return routeMap.machine;
    }
  }, [user]);

  if (!user) {
    return (
      <NavigateBackNotFound
        navigateBackText={navigateBackText}
        navigateBackUrl={navigateBackUrl}
        notFoundText="User not found"
      />
    );
  }

  const { enabled, mfaRequired } = user;

  return (
    <div>
      <NavigateBackHeader navigateBackText={navigateBackText} navigateBackUrl={navigateBackUrl}>
        <Box display="flex" alignItems="center">
          <Typography variant="h4">{user?.name}</Typography>
          <Box ml={1} mr={1}>
            <Chip icon={<userTypeInfo.icon />} size="small" label={userTypeInfo.title} color="primary" />
          </Box>
          <Box mr={1}>
            {enabled
              ? <Chip icon={<CheckIcon />} size="small" color="primary" label="Enabled" />
              : <Chip icon={<CrossIcon />} size="small" color="secondary" label="Disabled" />}
          </Box>
          <Box mr={1}>
            {mfaRequired
              ? <Chip icon={<CheckIcon />} size="small" color="primary" label="MFA enabled" />
              : <Chip icon={<CrossIcon />} size="small" color="secondary" label="MFA disabled" />}
          </Box>
        </Box>
        <Typography variant="body2">{user.email}</Typography>
      </NavigateBackHeader>

      <Grid container spacing={2}>
        <Grid item xl={4}><UserProfile user={user} /></Grid>
        <Grid item xl={4}>
          {user.userType === UserTypeEnum.Test
            ? <TestUserCopyPermissions user={user} />
            : <UserGroups user={user} />}
        </Grid>
        <Grid item xl={4} />
        <Grid item xl={12}>
          {user.userType === UserTypeEnum.Test
            ? <TestUserPermissionsOverview user={user} />
            : <PermissionOverview user={user} />}
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(UserDetails);
