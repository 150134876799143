import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import NavigateBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';

type HeaderWithNavigationProps = {
  navigateBackUrl: string,
  navigateBackText: string,
  children: React.ReactNode,
};

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  navigateBackIcon: {
    fontSize: 16,
    marginRight: theme.spacing(0.5),
  },
}));

const NavigateBackHeader = (props: HeaderWithNavigationProps) => {
  const classes = useStyles();
  const { navigateBackText, navigateBackUrl, children } = props;

  return (
    <Box display="flex" flexDirection="column" mb={3}>
      <Link to={navigateBackUrl} className={classes.link}>
        <Box display="flex" alignItems="center">
          <NavigateBackIcon className={classes.navigateBackIcon} />
          <Typography variant="body2">{navigateBackText}</Typography>
        </Box>
      </Link>
      {children}
    </Box>
  );
};

export default React.memo(NavigateBackHeader);
