import React, { useState, useMemo } from 'react';
import {
  Typography, makeStyles, Box, Card,
  CardContent, Button, CardActions, Chip,
} from '@material-ui/core';

import { useSelector } from 'react-redux';
import { parentGroupSelectors } from 'features/groups/parentGroupsSlice';
import { groupBy } from 'lodash';
import { Link } from 'react-router-dom';
import { Group, ParentGroup } from 'features/groups/types';
import { routeMap } from 'features/layout/Navigation';
import { stingrayPages } from 'features/stingray/StingraySection';
import { User, UserGroup } from './types';
import UserEditDialog from './UserEditDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  chip: {
    marginRight: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
  },
  link: {
    textDecoration: 'none',
  },
}));

const createGroupLink = (group: Group | UserGroup, parentGroup: ParentGroup) => {
  if (parentGroup.companyCode) {
    return `${routeMap.customer.baseUrl}/${parentGroup.companyCode}/groups/${group.externalId}`;
  }

  return `${routeMap.stingray.baseUrl}/${stingrayPages.groups.value}/${group.externalId}`;
};

type UserGroupsProps = {
  user: User
};

const UserGroups = (props: UserGroupsProps) => {
  const classes = useStyles();
  const { user } = props;
  const { groups } = user;

  const parentGroupLookup = useSelector(parentGroupSelectors.selectEntities);
  const groupsByParentGroup = useMemo(() => {
    const grouped = groupBy(groups, (g) => g.parentGroupExternalId);

    const result: Array<{ parentGroup: ParentGroup, groups: Array<UserGroup> }> = [];

    Object.keys(grouped)
      .sort() // sort first by parent group name
      .forEach((parentGroupExternalId) => {
        const parentGroup = parentGroupLookup[parentGroupExternalId];
        if (parentGroup) {
          // sort groups of a parent group
          const sortedGroups = [...grouped[parentGroupExternalId]].sort((a, b) => (a.name > b.name ? 1 : -1));
          result.push({
            parentGroup,
            groups: sortedGroups,
          });
        }
      });

    return result;
  }, [groups, parentGroupLookup]);

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  return (
    <>
      {isEditDialogOpen && (
        <UserEditDialog
          user={user}
          userType={user.userType}
          hideProfile
          hideCustomers
          onClose={() => setIsEditDialogOpen(false)}
        />
      )}
      <Card className={classes.root}>
        <Box ml={2} mt={1}>
          <Typography variant="h6">Groups</Typography>
        </Box>
        <CardContent className={classes.content}>
          {
            groupsByParentGroup.map(({ parentGroup, groups: parentGroupGroups }) => (
              <Box key={parentGroup.externalId} display="flex" flexDirection="column" mb={2}>
                <Typography variant="subtitle2" gutterBottom>{parentGroup.name}</Typography>
                <Box display="flex" flexWrap="wrap">
                  {parentGroupGroups.map((group) => (
                    <Link className={classes.link} key={group.externalId} to={createGroupLink(group, parentGroup)}>
                      <Chip
                        className={classes.chip}
                        size="small"
                        label={group.name}
                      />
                    </Link>
                  ))}
                </Box>
              </Box>
            ))
          }

          {!groups.length && (
            <Box className={classes.centered}>
              <Typography variant="h4">User is not a member of any groups</Typography>
            </Box>
          )}
        </CardContent>
        <CardActions>
          <Button variant="outlined" onClick={() => setIsEditDialogOpen(true)}>Edit</Button>
        </CardActions>
      </Card>
    </>
  );
};

export default React.memo(UserGroups);
