/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import {
  createSlice, PayloadAction, createEntityAdapter, EntityState,
} from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { FailurePayload } from 'core/types';
import { ParentGroup, ParentGroupInput } from './types';
import * as api from './api';

const parentGroupsAdapter = createEntityAdapter<ParentGroup>({
  selectId: (parentGroup) => parentGroup.externalId,
  sortComparer: (a, b) => (a.name > b.name ? 1 : -1),
});

type ParentGroupsState = EntityState<ParentGroup> & {
  isFetching: boolean,
  error: string | null,
};

const initialState: ParentGroupsState = parentGroupsAdapter.getInitialState({
  isFetching: false,
  error: null,
});

export const parentGroupsSlice = createSlice({
  name: 'parentGroups',
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.isFetching = true;
    },
    fetchFailure: (state, action: PayloadAction<FailurePayload>) => {
      state.isFetching = false;
      state.error = action.payload.text;
    },
    fetchSuccess: (state, action: PayloadAction<Array<ParentGroup>>) => {
      state.isFetching = false;
      state.error = null;
      parentGroupsAdapter.setAll(state, action.payload);
    },
    createSuccess: (state, action: PayloadAction<ParentGroup>) => {
      state.error = null;
      parentGroupsAdapter.addOne(state, action.payload);
    },
    createFailure: (state, action: PayloadAction<FailurePayload>) => {
      state.error = action.payload.text;
    },
    updateSuccess: (state, action: PayloadAction<ParentGroup>) => {
      state.error = null;
      parentGroupsAdapter.upsertOne(state, action.payload);
    },
    updateFailure: (state, action: PayloadAction<FailurePayload>) => {
      state.error = action.payload.text;
    },
    deleteSuccess: (state, action: PayloadAction<string>) => {
      state.error = null;
      parentGroupsAdapter.removeOne(state, action.payload);
    },
    deleteFailure: (state, action: PayloadAction<FailurePayload>) => {
      state.error = action.payload.text;
    },
  },
});

// actions
const {
  fetchStart, fetchFailure, fetchSuccess,
  createSuccess, createFailure,
  updateSuccess, updateFailure,
  deleteSuccess, deleteFailure,
} = parentGroupsSlice.actions;

// selectors
const sequencesSelectors = parentGroupsAdapter.getSelectors((state: RootState) => state.parentGroups);
const selectIsFetching = (state: RootState) => state.parentGroups.isFetching;
const selectError = (state: RootState) => state.parentGroups.error;

export const parentGroupSelectors = {
  ...sequencesSelectors,
  selectIsFetching,
  selectError,
};

// action creators
export const fetchParentGroups = (accessToken: string): AppThunk => async (dispatch, getState) => {
  // avoid race conditions
  const isFetching = selectIsFetching(getState());
  if (isFetching) {
    return;
  }

  dispatch(fetchStart());

  try {
    const parentGroups = await api.getParentGroups(accessToken);
    dispatch(fetchSuccess(parentGroups));
  } catch (err) {
    // trying to get error message from exception.
    const text = typeof (err as any).message === 'string' ? (err as any).message : 'Unknown error';

    dispatch(fetchFailure({
      notify: true,
      title: 'Failed to load parent groups',
      text,
    }));
  }
};

export const createParentGroup = (
  parentGroupIn: ParentGroupInput,
  accessToken: string,
): AppThunk => async (dispatch) => {
  try {
    const parentGroup = await api.createParentGroup(parentGroupIn, accessToken);
    dispatch(createSuccess(parentGroup));
  } catch (err) {
    // trying to get error message from exception.
    const text = typeof (err as any).message === 'string' ? (err as any).message : 'Unknown error';

    dispatch(createFailure({
      notify: true,
      title: 'Failed to create parent group',
      text,
    }));
  }
};

export const updateParentGroup = (
  parentGroupIn: ParentGroupInput,
  accessToken: string,
): AppThunk => async (dispatch) => {
  try {
    const parentGroup = await api.updateParentGroup(parentGroupIn, accessToken);
    dispatch(updateSuccess(parentGroup));
  } catch (err) {
    // trying to get error message from exception.
    const text = typeof (err as any).message === 'string' ? (err as any).message : 'Unknown error';

    dispatch(updateFailure({
      notify: true,
      title: 'Failed to update parent group',
      text,
    }));
  }
};

export const deleteParentGroup = (
  externalId: string,
  accessToken: string,
): AppThunk => async (dispatch) => {
  try {
    await api.deleteParentGroup(externalId, accessToken);
    dispatch(deleteSuccess(externalId));
  } catch (err) {
    // trying to get error message from exception.
    const text = typeof (err as any).message === 'string' ? (err as any).message : 'Unknown error';

    dispatch(deleteFailure({
      notify: true,
      title: 'Failed to delete parent group',
      text,
    }));
  }
};

export default parentGroupsSlice.reducer;
