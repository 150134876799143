import { makeStyles } from '@material-ui/core';

const useLayoutStyles = makeStyles((theme) => ({
  styledScroll: {
    // firefox
    scrollbarColor: `${theme.palette.primary.main} ${theme.palette.background.paper}`,
    scrollbarWidth: 'thin',

    // webkit (Chrome, Safari,...)
    '&::-webkit-scrollbar': {
      height: 10,
      width: 10,
      borderRadius: 10,
      backgroundColor: theme.palette.background.paper,
    },
    '&::-webkit-scrollbar-track': {
      WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      borderRadius: 10,
      backgroundColor: theme.palette.background.paper,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export default useLayoutStyles;
