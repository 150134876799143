import React, { useCallback } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Permission } from 'features/common/types';
import CustomerSelectSingle from 'features/fleet/CustomerSelectSingle';
import { User, UserTypeEnum } from 'features/users/types';
import UserSearch from 'features/users/UserSearch';

type SaveSearchUserParams = {
  user: User | null,
  companyCode?: never
};

type SaveSearchCustomerParams = {
  user?: never,
  companyCode: number | null
};

export type SaveSearchCustomerUserParams = SaveSearchCustomerParams | SaveSearchUserParams;

type SearchCustomerUserProps = {
  permissions: Array<Permission>;
  companyCode: number | null,
  user: User | null,
  handleCustomerUser: ({ user, companyCode }: SaveSearchCustomerUserParams) => void;
};

const SearchCustomerUser = ({
  permissions, companyCode, user, handleCustomerUser,
}: SearchCustomerUserProps) => {
  const handleUser = useCallback((selectedUser: User | null) => {
    handleCustomerUser({ user: selectedUser });
  }, [handleCustomerUser]);

  const handleCustomer = useCallback((selectedCompanyCode: number | null) => {
    handleCustomerUser({ companyCode: selectedCompanyCode });
  }, [handleCustomerUser]);

  return (
    <>
      <Grid item sm={12}>
        <CustomerSelectSingle
          companyCode={companyCode ?? 0}
          onChange={handleCustomer}
        />
      </Grid>
      <Grid item sm={12}>
        <UserSearch
          companyCode={companyCode ?? 0}
          selectedUser={user}
          searchUserType={UserTypeEnum.Customer}
          setUser={handleUser}
        />
      </Grid>
      {(permissions.length ?? 0) > 0 && (
        <Grid item sm={12}>
          <Typography variant="subtitle1">
            {`Has ${permissions.length ?? 0} unique permissions`}
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default React.memo(SearchCustomerUser);
