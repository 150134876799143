import React from 'react';
import { makeStyles } from '@material-ui/core';
import { StingrayApp } from './types';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: 'black',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    alignSelf: 'center',
    width: 50,
    height: 50,
  },
  name: {
    alignSelf: 'center',
    fontSize: '12px',
  },
});

type StingrayAppItemProps = {
  stingrayApp: StingrayApp,
  onClick: () => void,
};

const StingrayAppItem = (props: StingrayAppItemProps) => {
  const classes = useStyles();
  const { stingrayApp: { name, url, icon }, onClick } = props;

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" className={classes.link} onClick={onClick}>
      <div className={classes.container}>
        <img className={classes.image} alt="" src={icon} />
        <span className={classes.name}>{name}</span>
      </div>
    </a>
  );
};

export default React.memo(StingrayAppItem);
