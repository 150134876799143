import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import MaterialTable, { MTableAction } from 'material-table';
import ListTooltip from 'features/common/ListTooltip';
import RelativeTimestamp from 'features/common/RelativeTimestamp';
import { customerSelectors } from 'features/fleet/fleetSlice';
import NavigationButton from 'features/common/NavigationButton';
import { routeMap } from 'features/layout/Navigation';
import { stingrayPages } from 'features/stingray/StingraySection';
import { userSelectors } from './usersSlice';
import {
  LanguageEnum, CountryEnum, User, UserTypeEnum,
} from './types';
import UserEditDialog from './UserEditDialog';
import TestUserEditDialog from '../testusers/TestUserEditDialog';
import ImportUserDialog from './ImportUserDialog';

type TableDataEntry = {
  externalId: string,
  name: string,
  email: string,
  phone: string,
  userType: UserTypeEnum,
  country: string,
  language: string,
  address: string,
  groups: Array<string>,
  customers: Array<string>,
  enabled: boolean,
  mfaRequired: boolean,
  regTms: Date,
};

type EditDialogState = {
  open: boolean,
  user?: User,
};

type UserListProps = {
  userType: UserTypeEnum,
  companyCode?: number,
  dynamicPageSize?: boolean,
  showTitle?: boolean,
};

const getPageSize = (dynamicPageSize: boolean, count: number) => {
  if (!dynamicPageSize) {
    return 25;
  }

  if (count <= 5) {
    return 5;
  }

  return 10;
};

const UserList = (props: UserListProps) => {
  const {
    userType, showTitle, dynamicPageSize, companyCode,
  } = props;

  const userDetailsBaseUrl = useMemo(() => {
    if (userType === UserTypeEnum.Stingray) {
      return `${routeMap.stingray.baseUrl}/${stingrayPages.users.value}`;
    }

    if (userType === UserTypeEnum.Customer && companyCode) {
      return `${routeMap.customer.baseUrl}/${companyCode}/users`;
    }

    if (userType === UserTypeEnum.ThirdParty) {
      return `${routeMap.third_party.baseUrl}`;
    }

    if (userType === UserTypeEnum.Machine) {
      return `${routeMap.machine.baseUrl}`;
    }

    if (userType === UserTypeEnum.Test) {
      return `${routeMap.testusers.baseUrl}`;
    }

    return '';
  }, [companyCode, userType]);

  const users = useSelector(userSelectors.selectAll);
  const customerLookup = useSelector(customerSelectors.selectEntities);

  const tableData = useMemo(() => {
    const filteredUsers = users.filter((user) => {
      // customer users: customer listed in user.customers array
      if (userType === UserTypeEnum.Customer && companyCode) {
        return user.companyCodes.includes(companyCode);
      }

      // stingray / machine / third-party users: checking user type only
      return user.userType === userType;
    });
    const result = filteredUsers.map<TableDataEntry>((user) => ({
      externalId: user.externalId,
      name: user.name,
      email: user.email,
      phone: user.phone,
      userType: user.userType,
      country: user.country,
      language: user.language,
      address: user.address,
      groups: user.groups.map((g) => g.name),
      customers: user.companyCodes.map((c) => customerLookup[c]?.name || 'Unknown').sort(),
      enabled: user.enabled,
      mfaRequired: user.mfaRequired,
      regTms: new Date(user.regTms),
    }));
    return result;
  }, [customerLookup, companyCode, userType, users]);

  // edit / create actions and dialog state
  const [editDialogState, setEditDialogState] = useState<EditDialogState>({ open: false });
  const handleEditUser = (rowData: TableDataEntry) => {
    const user = users.find((u) => u.externalId === rowData.externalId);
    if (user) {
      setEditDialogState({ open: true, user });
    }
  };
  const handleCreateUser = () => setEditDialogState({ open: true });
  const handleEditDialogClosed = () => setEditDialogState({ open: false });

  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const handleImportUser = () => setImportDialogOpen(true);
  const handleImportUserDialogClosed = () => setImportDialogOpen(false);

  return (
    <>
      {editDialogState.open && userType !== UserTypeEnum.Test && (
        <UserEditDialog
          onClose={handleEditDialogClosed}
          userType={userType}
          user={editDialogState.user}
          companyCode={companyCode}
        />
      )}
      {editDialogState.open && userType === UserTypeEnum.Test && (
        <TestUserEditDialog
          onClose={handleEditDialogClosed}
          userType={userType}
          user={editDialogState.user}
          companyCode={companyCode}
        />
      )}
      {importDialogOpen && companyCode && (
        <ImportUserDialog
          companyCode={companyCode}
          onClose={handleImportUserDialogClosed}
        />
      )}
      <MaterialTable
        title="Users"
        data={tableData}
        columns={[
          {
            title: 'Name',
            field: 'name',
            type: 'string',
            cellStyle: (_, { enabled }) => ({
              textDecoration: enabled ? 'initial' : 'line-through',
            }),
          },
          {
            title: 'Email',
            field: 'email',
            type: 'string',
          },
          {
            title: 'User type',
            field: 'userType',
            lookup: UserTypeEnum,
            hidden: userType !== UserTypeEnum.Customer,
          },
          {
            title: 'Phone',
            field: 'phone',
            type: 'string',
          },
          {
            title: 'Address',
            field: 'address',
            type: 'string',
            width: 300,
          },
          {
            title: 'Country',
            field: 'country',
            lookup: CountryEnum,
          },
          {
            title: 'Language',
            field: 'language',
            lookup: LanguageEnum,
          },
          {
            title: 'MFA',
            field: 'mfaRequired',
            type: 'boolean',
            initialEditValue: true,
          },
          {
            title: 'Enabled',
            field: 'enabled',
            type: 'boolean',
            initialEditValue: true,
          },
          {
            title: 'Customers',
            render: (d) => <ListTooltip values={d.customers} />,
            hidden: userType === UserTypeEnum.Stingray,
          },
          {
            title: 'Groups',
            render: (d) => <ListTooltip values={d.groups} />,
          },
          {
            title: 'Registered',
            type: 'datetime',
            field: 'regTms',
            render: (rowData) => <RelativeTimestamp date={rowData.regTms} />,
          },
        ]}
        options={{
          pageSize: getPageSize(!!dynamicPageSize, tableData.length),
          showTitle: !!showTitle,
          searchFieldAlignment: showTitle ? 'right' : 'left',
          pageSizeOptions: [5, 10, 25, 50, 100],
          defaultExpanded: true,
          padding: 'dense',
        }}
        actions={[
          {
            icon: 'input',
            tooltip: 'Import users',
            isFreeAction: true,
            onClick: handleImportUser,
            hidden: userType !== UserTypeEnum.Customer,
          },
          {
            icon: 'add_box',
            tooltip: 'Create user',
            isFreeAction: true,
            onClick: handleCreateUser,
          },
          {
            icon: 'open_in_new',
            tooltip: 'Open',
            onClick: () => { },
          },
          (rowData) => ({
            icon: 'edit',
            tooltip: 'Edit',
            onClick: () => handleEditUser(rowData),
          }),
        ]}
        editable={{
          // onRowDelete is not implemented but isDeletable check is kept because
          // the code is useful if one day we'd like to support deleting users
          isDeletable: (userToDelete) => {
            // userType of the table and the user must be the same
            // e.g.: Machine users can't be deleted from Stingray / Customer page
            if (userType !== userToDelete.userType) {
              return false;
            }

            // customer users with more than one customer can't be deleted
            if (userToDelete.userType === UserTypeEnum.Customer && userToDelete.customers.length > 1) {
              return false;
            }

            return true;
          },
        }}
        localization={{
          body: {
            editRow: {
              deleteText: 'Are you sure you want to delete this user?',
            },
          },
        }}
        components={{
          Action: (actionProps: any) => {
            // navigation links should work as links
            if (actionProps?.action?.icon === 'open_in_new') {
              return (
                <NavigationButton
                  to={`${userDetailsBaseUrl}/${actionProps?.data?.externalId}`}
                  tooltip={actionProps?.action?.tooltip}
                />
              );
            }
            // eslint-disable-next-line react/jsx-props-no-spreading
            return <MTableAction {...actionProps} />;
          },
        }}
      />
    </>
  );
};
export default React.memo(UserList);
