import React from 'react';
import {
  Box,
  ListItem, ListItemIcon, makeStyles, Typography,
} from '@material-ui/core';
import { Email, PermIdentity, Phone } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import StingrayUserIcon from '@material-ui/icons/Business';
import CustomerIcon from '@material-ui/icons/SupervisedUserCircle';
import ThirdPartyIcon from '@material-ui/icons/Extension';
import MachineIcon from '@material-ui/icons/DesktopMac';
import TestUsersIcon from '@material-ui/icons/AssignmentInd';
import { UserTypeEnum } from 'features/users/types';
import { UserHit } from './types';

type Props = {
  user: UserHit
};

const useStyles = makeStyles((theme) => ({
  listItem: {
    color: theme.palette.text.primary,
  },
}));

const UserLink = ({ user }: Props) => {
  const classes = useStyles();
  return (
    <Link to={user.href} style={{ textDecoration: 'none' }}>
      <ListItem button className={classes.listItem}>
        <ListItemIcon>
          <PermIdentity />
        </ListItemIcon>
        <Box display="flex" flexDirection="column" gridGap={2}>
          <Typography variant="h6">{user.name}</Typography>
          <Box display="flex" gridGap={4} alignItems="center">
            {user.userType === UserTypeEnum.Stingray && <StingrayUserIcon fontSize="small" />}
            {user.userType === UserTypeEnum.Customer && <CustomerIcon fontSize="small" />}
            {user.userType === UserTypeEnum.ThirdParty && <ThirdPartyIcon fontSize="small" />}
            {user.userType === UserTypeEnum.Machine && <MachineIcon fontSize="small" />}
            {user.userType === UserTypeEnum.Test && <TestUsersIcon fontSize="small" />}
            {`${user.userType} user`}
          </Box>
          <Box display="flex" alignItems="center" gridGap={4}>
            <Email fontSize="small" />
            {user.email}
          </Box>
          {!!user.phone?.length && (
            <Box display="flex" gridGap={4} alignItems="center">
              <Phone fontSize="small" />
              {user.phone}
            </Box>
          )}
          {user.companies.length > 0 && (
            <Box display="flex" gridGap={4} alignItems="center">
              <CustomerIcon fontSize="small" />
              {user.companies.join(', ')}
            </Box>
          )}
        </Box>

      </ListItem>
    </Link>
  );
};

export default React.memo(UserLink);
