import React from 'react';
import {
  makeStyles, LinearProgress,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
  },
  brandingWrapper: {
    height: 100,
    marginBottom: theme.spacing(3),
  },
  image: {
    height: '100%',
  },
  loadingIndicator: {
    width: 160,
  },
}));

const LoadingScreen = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.brandingWrapper}>
        <img className={classes.image} src="/branding.png" alt="stingray logo" />
      </div>
      <LinearProgress className={classes.loadingIndicator} />
    </div>
  );
};

export default LoadingScreen;
