import React from 'react';
import {
  makeStyles, Container, Box, Typography, Button, Divider,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { userManagementRoutes, otherRoutes } from 'features/layout/Navigation';

const useStyles = makeStyles((theme) => ({
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  brandingWrapper: {
    height: 100,
    marginBottom: theme.spacing(10),
  },
  image: {
    height: '100%',
  },
  link: {
    textDecoration: 'none',
  },
  button: {
    width: 220,
    marginRight: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
}));
const HomeContainer = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="xl" className={classes.content}>
      <div className={classes.brandingWrapper}>
        <img src="/branding.png" alt="Stingray branding" className={classes.image} />
      </div>
      <Box display="flex">
        {userManagementRoutes.map((r) => {
          if (r.title === 'Customer') {
            return (
              <a key={r.title} href={r.href} target="_blank" rel="noopener noreferrer">
                <Button color="primary" variant="contained" className={classes.button}>
                  <r.icon className={classes.icon} />
                  <Typography variant="subtitle1">{r.title}</Typography>
                </Button>
              </a>
            );
          }
          return (
            <Link key={r.href} to={r.href} className={classes.link}>
              <Button color="primary" variant="contained" className={classes.button}>
                <r.icon className={classes.icon} />
                <Typography variant="subtitle1">{r.title}</Typography>
              </Button>
            </Link>
          );
        })}
      </Box>
      <Box m={3} width="45%">
        <Divider />
      </Box>
      <Box display="flex">
        {otherRoutes.map((r) => (
          <Link key={r.href} to={r.href} className={classes.link}>
            <Button color="primary" variant="contained" className={classes.button}>
              <r.icon className={classes.icon} />
              <Typography variant="subtitle1">{r.title}</Typography>
            </Button>
          </Link>
        ))}
      </Box>
    </Container>
  );
};

export default HomeContainer;
