import React from 'react';
import {
  Tooltip, Box, Typography, makeStyles,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

type TooltipCellProps = {
  values: Array<string>,
};

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(0.25),
    fontSize: 16,
  },
}));

const ListTooltip = ({ values }: TooltipCellProps) => {
  const classes = useStyles();
  return (
    <Tooltip
      placement="bottom-start"
      title={values.length
        ? (
          <Box display="flex" flexDirection="column">
            {values.map((v) => <Typography key={v} variant="caption">{v}</Typography>)}
          </Box>
        )
        : ''}
    >
      <Box display="flex" alignItems="center">
        {values.length}
        {!!values.length && <InfoIcon className={classes.icon} />}
      </Box>
    </Tooltip>
  );
};

export default React.memo(ListTooltip);
