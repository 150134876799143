export enum EnvironmentEnum {
  development = 'development',
  staging = 'staging',
  production = 'production',
}

type AppSettingsTemplate = {
  auth0: {
    domain: string;
    clientId: string;
    audience: string;
    scope: string;
  };
  apis: {
    authService: {
      url: string;
    };
    stingrayApps: {
      url: string;
    };
    hqApi: {
      url: string;
    };
  };
  testusers: {
    emailSuffix: string;
  };
  authAdmin2Url: string;
  environmnet: EnvironmentEnum;
};

export default AppSettingsTemplate;
