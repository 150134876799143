/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import {
  createSlice, PayloadAction, createEntityAdapter, EntityState,
} from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { FailurePayload } from 'core/types';
import { ResourceGroup } from './types';
import * as api from './api';

const resourceGroupsAdapter = createEntityAdapter<ResourceGroup>({
  selectId: (resourceGroup) => resourceGroup.externalId,
  sortComparer: (a, b) => (a.name > b.name ? 1 : -1),
});

type ResourceGroupsState = EntityState<ResourceGroup> & {
  isFetching: boolean,
  error: string | null,
};

const initialState: ResourceGroupsState = resourceGroupsAdapter.getInitialState({
  isFetching: false,
  error: null,
});

export const resourceGroupsSlice = createSlice({
  name: 'resourceGroups',
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.isFetching = true;
    },
    fetchSuccess: (state, action: PayloadAction<Array<ResourceGroup>>) => {
      state.isFetching = false;
      state.error = null;
      resourceGroupsAdapter.setAll(state, action.payload);
    },
    fetchFailure: (state, action: PayloadAction<FailurePayload>) => {
      state.isFetching = false;
      state.error = action.payload.text;
    },
  },
});

// actions
export const { fetchStart, fetchFailure, fetchSuccess } = resourceGroupsSlice.actions;

// selectors
const sequencesSelectors = resourceGroupsAdapter.getSelectors((state: RootState) => state.resourceGroups);
const selectIsFetching = (state: RootState) => state.resourceGroups.isFetching;
const selectError = (state: RootState) => state.resourceGroups.error;

export const resourceGroupSelectors = {
  ...sequencesSelectors,
  selectIsFetching,
  selectError,
};

// action creators
export const fetchResourceGroups = (accessToken: string): AppThunk => async (dispatch, getState) => {
  // avoid race conditions
  const isFetching = selectIsFetching(getState());
  if (isFetching) {
    return;
  }

  dispatch(fetchStart());

  try {
    const resourceGroups = await api.getResourceGroups(accessToken);
    dispatch(fetchSuccess(resourceGroups));
  } catch (err) {
    // trying to get error message from exception.
    const text = typeof (err as any).message === 'string' ? (err as any).message : 'Unknown error';

    dispatch(fetchFailure({
      notify: true,
      title: 'Failed to load resource groups',
      text,
    }));
  }
};

export default resourceGroupsSlice.reducer;
