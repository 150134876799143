import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Notifier from 'features/notifications/Notifier';
import Layout from 'features/layout/Layout';
import SignIn from 'features/layout/SignInScreen';

const App = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <>
      <Notifier />
      {isAuthenticated ? <Layout /> : <SignIn />}
    </>
  );
};

export default App;
