import { User, UserInput } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mapUserToUserInput = (user: User): UserInput => ({
  externalId: user.externalId,
  email: user.email,
  name: user.name,
  phone: user.phone,
  userType: user.userType,
  country: user.country,
  language: user.language,
  address: user.address,
  mfaRequired: user.mfaRequired,
  enabled: user.enabled,
  companyCodes: user.companyCodes,
  groupExternalIds: user.groups.map((g) => g.externalId),
});
