import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { UserInput } from 'features/users/types';
import { createUser, updateUser } from 'features/users/usersSlice';

export default function useSaveUserWithoutGroup(mode: 'create'|'edit', callback?: () => void) {
  const [saving, setSaving] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const handleSave = async (input: UserInput) => {
    if (saving) {
      return;
    }

    const updatedInputs: UserInput = {
      ...input,
      groupExternalIds: [],
    };
    setSaving(true);

    try {
      const accessToken = await getAccessTokenSilently();
      if (mode === 'create') {
        await dispatch(createUser(updatedInputs, accessToken));
      } else if (mode === 'edit') {
        await dispatch(updateUser(updatedInputs, accessToken));
      }
    } finally {
      setSaving(false);
    }
    if (callback) {
      callback();
    }
  };

  return [saving, handleSave] as const;
}
