import React from 'react';
import {
  Tooltip, Box, makeStyles, Typography,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

type InformationTooltipProps = {
  text: string,
};

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(0.5),
    fontSize: 16,
  },
}));

const InformationTooltip = ({ text }: InformationTooltipProps) => {
  const classes = useStyles();

  return (
    <Tooltip
      placement="bottom-start"
      title={<Typography variant="body2">{text}</Typography>}
    >
      <Box display="flex" alignItems="center">
        <InfoIcon className={classes.icon} />
      </Box>
    </Tooltip>
  );
};

export default React.memo(InformationTooltip);
