import { get } from 'core/communication';
import appSettings from 'app/appSettings';
import { AccessRight } from './types';

const BASE_URL = appSettings.apis.authService.url;

// eslint-disable-next-line import/prefer-default-export
export async function getAccessRights(accessToken: string): Promise<Array<AccessRight>> {
  const url = `${BASE_URL}/accessRights`;
  const response = await get<Array<AccessRight>>(url, accessToken);
  return response;
}
