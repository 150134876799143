import React, { useState, useMemo } from 'react';
import {
  Typography, makeStyles, Box, Card,
  CardContent, Button, CardActions, Chip,
} from '@material-ui/core';
import NameIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import AddressIcon from '@material-ui/icons/Home';
import CountryIcon from '@material-ui/icons/Public';
import LanguageIcon from '@material-ui/icons/Language';
import CustomerIcon from '@material-ui/icons/SupervisedUserCircle';
import { customerSelectors } from 'features/fleet/fleetSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Customer } from 'features/fleet/types';
import { routeMap } from 'features/layout/Navigation';
import TestUserEditDialog from 'features/testusers/TestUserEditDialog';
import { useAuth0 } from '@auth0/auth0-react';
import { enqueueNotification } from 'features/notifications/notificationsSlice';
import ConfirmDialog from 'features/common/ConfirmDialog';
import { User, UserTypeEnum } from './types';
import UserEditDialog from './UserEditDialog';
import { resetUserPassword, resetUserMFA } from './api';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
  customerChip: {
    marginRight: theme.spacing(0.5),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
  },
  link: {
    textDecoration: 'none',
  },
}));

type UserInformationProps = {
  user: User
};

const UserProfile = (props: UserInformationProps) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const { user } = props;
  const {
    name, email, phone, address, language, country, userType,
  } = user;

  const customerLookup = useSelector(customerSelectors.selectEntities);

  const sortedCustomers = useMemo(() => {
    const result: Array<Customer> = [];
    user.companyCodes.forEach((companyCode) => {
      const customer = customerLookup[companyCode];
      if (customer) {
        result.push(customer);
      }
    });

    result.sort((a, b) => (a.name > b.name ? 1 : -1));
    return result;
  }, [customerLookup, user.companyCodes]);

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const [resetPasswordConfirmDialogOpen, setResetPasswordConfirmDialogOpen] = useState(false);
  const handlePasswordResetConfirmed = async () => {
    const accessToken = await getAccessTokenSilently();
    try {
      const response = await resetUserPassword(user.email, accessToken);
      dispatch(enqueueNotification({
        key: `user_password_reset_${Date.now()}`,
        title: '',
        text: response.text,
        variant: 'success',
      }));
    } catch {
      dispatch(enqueueNotification({
        key: `user_password_reset_${Date.now()}`,
        title: '',
        text: 'Password reset failed.',
        variant: 'error',
      }));
    }

    setResetPasswordConfirmDialogOpen(false);
  };

  const [resetMFAConfirmDialogOpen, setResetMFAConfirmDialogOpen] = useState(false);
  const handleMFAResetConfirmed = async () => {
    const accessToken = await getAccessTokenSilently();
    try {
      await resetUserMFA(user.externalId, accessToken);
      dispatch(enqueueNotification({
        key: `user_mfa_reset_${Date.now()}`,
        title: '',
        text: 'MFA reset',
        variant: 'success',
      }));
    } catch {
      dispatch(enqueueNotification({
        key: `user_mfa_reset_${Date.now()}`,
        title: '',
        text: 'MFA reset failed.',
        variant: 'error',
      }));
    }

    setResetMFAConfirmDialogOpen(false);
  };

  return (
    <>
      {isEditDialogOpen && user.userType !== UserTypeEnum.Test && (
        <UserEditDialog
          user={user}
          userType={user.userType}
          hideGroups
          onClose={() => setIsEditDialogOpen(false)}
        />
      )}
      {isEditDialogOpen && user.userType === UserTypeEnum.Test && (
        <TestUserEditDialog
          user={user}
          userType={user.userType}
          onClose={() => setIsEditDialogOpen(false)}
        />
      )}
      <ConfirmDialog
        acceptButtonText="Reset password"
        cancelButtonText="Cancel"
        title="Password reset"
        dialogText={`Are you sure you want reset password for ${user.name} (${user.email})?`}
        open={resetPasswordConfirmDialogOpen}
        onAccept={handlePasswordResetConfirmed}
        onCancel={() => setResetPasswordConfirmDialogOpen(false)}
      />
      <ConfirmDialog
        acceptButtonText="Reset MFA"
        cancelButtonText="Cancel"
        title="MFA reset"
        dialogText={`Are you sure you want reset MFA for ${user.name} (${user.email})?`}
        open={resetMFAConfirmDialogOpen}
        onAccept={handleMFAResetConfirmed}
        onCancel={() => setResetMFAConfirmDialogOpen(false)}
      />
      <Card>
        <Box ml={2} mt={1}>
          <Typography variant="h6">Profile</Typography>
        </Box>
        <CardContent>
          <Box display="flex">
            <NameIcon className={classes.icon} />
            <Typography>{name}</Typography>
          </Box>
          {userType !== UserTypeEnum.Machine && (
            <>
              <Box display="flex">
                <EmailIcon className={classes.icon} />
                <Typography>{email}</Typography>
              </Box>
              <Box display="flex">
                <PhoneIcon className={classes.icon} />
                <Typography>{phone}</Typography>
              </Box>
              <Box display="flex">
                <AddressIcon className={classes.icon} />
                <Typography>{address}</Typography>
              </Box>
              <Box display="flex">
                <CountryIcon className={classes.icon} />
                <Typography>{country}</Typography>
              </Box>
              <Box display="flex">
                <LanguageIcon className={classes.icon} />
                <Typography>{language}</Typography>
              </Box>
            </>
          )}
          {userType !== UserTypeEnum.Stingray && (
            <Box display="flex">
              <CustomerIcon className={classes.icon} />
              <Box display="flex" flexWrap="wrap">
                {sortedCustomers.map((customer) => (
                  <Link
                    className={classes.link}
                    key={customer.code}
                    to={`${routeMap.customer.baseUrl}/${customer.code}`}
                  >
                    <Chip
                      size="small"
                      label={customer.name}
                      className={classes.customerChip}
                    />
                  </Link>
                ))}
              </Box>
            </Box>
          )}
        </CardContent>
        <CardActions>
          <Button variant="outlined" onClick={() => setIsEditDialogOpen(true)}>Edit</Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setResetPasswordConfirmDialogOpen(true)}
          >
            Reset password
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setResetMFAConfirmDialogOpen(true)}
          >
            Reset MFA
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default React.memo(UserProfile);
