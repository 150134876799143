/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import {
  createSlice, PayloadAction, createEntityAdapter, EntityState,
} from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { FailurePayload } from 'core/types';
import { Resource } from './types';
import * as api from './api';

const resourcesAdapter = createEntityAdapter<Resource>({
  selectId: (resource) => resource.externalId,
  sortComparer: (a, b) => (a.name > b.name ? 1 : -1),
});

type ResourcesState = EntityState<Resource> & {
  isFetching: boolean,
  error: string | null,
};

const initialState: ResourcesState = resourcesAdapter.getInitialState({
  isFetching: false,
  error: null,
});

export const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.isFetching = true;
    },
    fetchFailure: (state, action: PayloadAction<FailurePayload>) => {
      state.isFetching = false;
      state.error = action.payload.text;
    },
    fetchSuccess: (state, action: PayloadAction<Array<Resource>>) => {
      state.isFetching = false;
      state.error = null;
      resourcesAdapter.setAll(state, action.payload);
    },
  },
});

// actions
export const { fetchStart, fetchFailure, fetchSuccess } = resourcesSlice.actions;

// selectors
const sequencesSelectors = resourcesAdapter.getSelectors((state: RootState) => state.resources);
const selectIsFetching = (state: RootState) => state.resources.isFetching;
const selectError = (state: RootState) => state.resources.error;

export const resourceSelectors = {
  ...sequencesSelectors,
  selectIsFetching,
  selectError,
};

// action creators
export const fetchResources = (accessToken: string): AppThunk => async (dispatch, getState) => {
  // avoid race conditions
  const isFetching = selectIsFetching(getState());
  if (isFetching) {
    return;
  }

  dispatch(fetchStart());

  try {
    const resources = await api.getResources(accessToken);
    dispatch(fetchSuccess(resources));
  } catch (err) {
    // trying to get error message from exception.
    const text = typeof (err as any).message === 'string' ? (err as any).message : 'Unknown error';

    dispatch(fetchFailure({
      notify: true,
      title: 'Failed to load resources',
      text,
    }));
  }
};

export default resourcesSlice.reducer;
