import React from 'react';
import {
  Box,
  ListItem, ListItemIcon, makeStyles, Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import CustomerIcon from '@material-ui/icons/SupervisedUserCircle';
import { ArrowForwardIos } from '@material-ui/icons';
import { CustomerHit } from './types';

type Props = {
  customer: CustomerHit
};

const useStyles = makeStyles((theme) => ({
  listItem: {
    color: theme.palette.text.primary,
  },
}));

const CustomerLink = ({ customer }: Props) => {
  const classes = useStyles();
  return (
    <Link to={customer.href} style={{ textDecoration: 'none' }}>
      <ListItem button className={classes.listItem}>
        <ListItemIcon>
          <CustomerIcon />
        </ListItemIcon>
        <Typography variant="h6">
          <Box display="flex" alignItems="center">
            {customer.customer}
            {customer.location && (
              <>
                <ArrowForwardIos fontSize="small" />
                {customer.location}
              </>
            )}
          </Box>
        </Typography>
      </ListItem>
    </Link>
  );
};

export default React.memo(CustomerLink);
