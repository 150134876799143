import React, { useMemo, useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Box, Button, TextField, Paper,
  makeStyles,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { userSelectors, addUserToGroup } from 'features/users/usersSlice';
import { UserTypeEnum, User } from 'features/users/types';
import { GroupFamilyEnum } from './types';

export type AddGroupMembersDialogProps = {
  groupExternalId: string,
  onClose: () => void,
  groupType: GroupFamilyEnum,
  companyCode: number | null,
};

const useStyles = makeStyles(() => ({
  content: {
    width: 600,
  },
}));

const AddGroupMembersDialog = (props: AddGroupMembersDialogProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { getAccessTokenSilently } = useAuth0();

  const {
    groupExternalId, groupType, companyCode, onClose,
  } = props;

  const users = useSelector(userSelectors.selectAll);
  const relevantUsers = useMemo(() => {
    const result = users.filter((user) => {
      const { userType, companyCodes, groups } = user;

      // already group member
      if (groups.find((g) => g.externalId === groupExternalId)) {
        return false;
      }

      // only stingray and machine users can be members of non-customer groups
      if (groupType === GroupFamilyEnum.NonCustomerGroup) {
        return userType === UserTypeEnum.Stingray || userType === UserTypeEnum.Machine;
      }

      // only customer users can be member of customer groups
      if (groupType === GroupFamilyEnum.CustomerGroup && companyCode) {
        return companyCodes.includes(companyCode);
      }

      return false;
    });
    return result;
  }, [companyCode, groupExternalId, groupType, users]);

  const [usersToAdd, setUsersToAdd] = useState<Array<User>>([]);

  const [saving, setSaving] = useState(false);

  const handleApproved = async () => {
    if (saving || !usersToAdd.length) {
      return;
    }

    setSaving(true);

    try {
      const accessToken = await getAccessTokenSilently();
      const tasks = usersToAdd.map((u) => dispatch(addUserToGroup(u.externalId, groupExternalId, accessToken)));
      await Promise.all(tasks);
    } finally {
      setSaving(false);
    }

    onClose();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && e.ctrlKey) {
      e.preventDefault();
      handleApproved();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      onClose();
    }
  };

  return (
    <Dialog onClose={onClose} open maxWidth="lg" onKeyDown={handleKeyDown}>
      <DialogTitle>Add group members</DialogTitle>
      <DialogContent className={classes.content}>
        <Box display="flex" flexDirection="column">
          <Autocomplete
            multiple
            options={relevantUsers}
            getOptionLabel={(option: User) => `${option.name} (${option.email})${option.enabled ? '' : ' [DISABLED]'}`}
            fullWidth
            value={usersToAdd}
            onChange={
              (_, value: Array<User>) => setUsersToAdd(value)
            }
            // eslint-disable-next-line react/jsx-props-no-spreading
            PaperComponent={(paperProps) => <Paper {...paperProps} elevation={8} />}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                autoFocus
                label="Members"
                variant="outlined"
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button disabled={usersToAdd.length <= 0 || saving} onClick={handleApproved} color="primary">
          Add members
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(AddGroupMembersDialog);
