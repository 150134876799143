import React from 'react';
import {
  List, ListItem, makeStyles, Typography, SvgIconTypeMap, Divider, Box,
} from '@material-ui/core';
import StingrayUserIcon from '@material-ui/icons/Business';
import CustomerIcon from '@material-ui/icons/SupervisedUserCircle';
import ThirdPartyIcon from '@material-ui/icons/Extension';
import MachineIcon from '@material-ui/icons/DesktopMac';
import TestUsersIcon from '@material-ui/icons/AssignmentInd';
import ResourcesIcon from '@material-ui/icons/InsertDriveFile';
import ToolsIcon from '@material-ui/icons/Build';
import clsx from 'clsx';
import UserMenu from 'features/userMenu/UserMenu';
import {
  useLocation, matchPath, Link,
} from 'react-router-dom';
import StingrayApps from 'features/stingrayApps';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { toolsPages } from 'features/tools/ToolsSection';
import { stingrayPages } from 'features/stingray/StingraySection';
import appSettings from 'app/appSettings';
import { EnvironmentEnum } from 'app/appSettings/appSettings.template';
import GlobalSearch from 'features/GlobalSearch';
import useLayoutStyles from './useLayoutStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 160,
    height: '100vh',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderRight: '1px solid rgba(255, 255, 255, 0.12)',
  },
  branding: {
    alignSelf: 'center',
  },
  image: {
    height: '100%',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.text.disabled,
  },
  selectedListItem: {
    color: 'white',
    borderRight: '2px solid white',
  },
  selectedIcon: {
    fontSize: 30,
  },
  spacer: {
    flexGrow: 1,
  },
  link: {
    textDecoration: 'none',
  },
  bgRed: {
    backgroundColor: '#6b000b',
  },
}));

type Route = {
  title: string,
  baseUrl: string,
  href: string,
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>,
};

type MainRoutes = 'stingray' | 'customer' | 'third_party' | 'machine' | 'resources' | 'tools' | 'testusers' | 'customerNew';
export const routeMap: { [k in MainRoutes]: Route } = {
  stingray: {
    title: 'Stingray',
    baseUrl: '/stingray',
    href: `/stingray/${stingrayPages.users.value}`,
    icon: StingrayUserIcon,
  },
  customerNew: {
    title: 'Customer',
    baseUrl: `${appSettings.authAdmin2Url}/companies`,
    href: `${appSettings.authAdmin2Url}/companies`,
    icon: CustomerIcon,
  },
  customer: {
    title: 'Customer (old)',
    baseUrl: '/customer',
    href: '/customer',
    icon: CustomerIcon,
  },
  third_party: {
    title: 'Third-party',
    baseUrl: '/thirdparty',
    href: '/thirdparty',
    icon: ThirdPartyIcon,
  },
  machine: {
    title: 'Machine',
    baseUrl: '/machines',
    href: '/machines',
    icon: MachineIcon,
  },
  testusers: {
    title: 'Test users',
    baseUrl: '/testusers',
    href: '/testusers',
    icon: TestUsersIcon,
  },
  resources: {
    title: 'Resources',
    baseUrl: '/resources',
    href: '/resources',
    icon: ResourcesIcon,
  },
  tools: {
    title: 'Tools',
    baseUrl: '/tools',
    href: `/tools/${toolsPages.tokenGenerator.value}`,
    icon: ToolsIcon,
  },
};

export const userManagementRoutes = [
  routeMap.stingray,
  routeMap.customerNew,
  routeMap.customer,
  routeMap.third_party,
  routeMap.machine,
  routeMap.testusers,
];

export const otherRoutes = [
  routeMap.resources,
  routeMap.tools,
];

const Navigation = () => {
  const classes = useStyles();
  const layoutClasses = useLayoutStyles();

  const { pathname } = useLocation();

  const isRouteActive = (path: string) => matchPath(pathname, { path, exact: false });

  const notProductionEnv = appSettings.environmnet !== EnvironmentEnum.production;

  return (
    <div className={clsx(classes.root, notProductionEnv && classes.bgRed)}>
      <div className={classes.branding}>
        <Link to="/" className={classes.link}>
          <Box height={70} position="relative">
            <img className={classes.image} src="/branding.png" alt="Stingray branding" />
            <Box position="absolute" bottom={0} right={0}>
              {notProductionEnv && <Typography color="textPrimary" variant="caption">{appSettings.environmnet}</Typography>}
            </Box>
          </Box>
        </Link>
      </div>
      <Box
        display="flex"
        flexDirection="column"
        overflow="auto"
        flexGrow={1}
        className={layoutClasses.styledScroll}
      >
        <List>
          {userManagementRoutes.map((r) => {
            const isActive = isRouteActive(r.baseUrl);
            if (r.title === 'Customer') {
              return (
                <a key={r.title} href={r.href} target="_blank" rel="noopener noreferrer" className={classes.link}>
                  <ListItem button className={clsx(classes.listItem, isActive && classes.selectedListItem)}>
                    <r.icon className={clsx(isActive && classes.selectedIcon)} />
                    <Typography variant="subtitle1">{r.title}</Typography>
                  </ListItem>
                </a>
              );
            }
            return (
              <Link to={r.href} key={r.title} className={classes.link}>
                <ListItem button className={clsx(classes.listItem, isActive && classes.selectedListItem)}>
                  <r.icon className={clsx(isActive && classes.selectedIcon)} />
                  <Typography variant="subtitle1">{r.title}</Typography>
                </ListItem>
              </Link>
            );
          })}
          <Box mt={1} mb={1}>
            <Divider />
          </Box>
          {otherRoutes.map((r) => {
            const isActive = isRouteActive(r.baseUrl);
            return (
              <Link to={r.href} key={r.title} className={classes.link}>
                <ListItem button className={clsx(classes.listItem, isActive && classes.selectedListItem)}>
                  <r.icon className={clsx(isActive && classes.selectedIcon)} />
                  <Typography variant="subtitle1">{r.title}</Typography>
                </ListItem>
              </Link>
            );
          })}
        </List>
        <div className={classes.spacer} />
        <GlobalSearch />
        <StingrayApps />
        <UserMenu />
      </Box>
    </div>
  );
};

export default Navigation;
