import React, { useMemo, useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, TextField,
  makeStyles,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@material-ui/core';
import { Location } from 'features/fleet/types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import { customerSelectors } from 'features/fleet/fleetSlice';
import {
  Group, GroupInput, ParentGroup, GroupFamilyEnum,
  GroupTypeEnum,
  GroupTemplateInput,
} from './types';
import { parentGroupSelectors } from './parentGroupsSlice';
import { createGroup, createGroupFromTemplate, updateGroup } from './groupsSlice';

export type GroupEditDialogProps = {
  onClose: () => void,
  group?: Group,
  groupType: GroupFamilyEnum,
  parentGroupExternalId?: string,
};

type DialogMode = 'edit' | 'create';

const getDefaultInputs = (group?: Group, parentGroupExternalId?: string) => {
  const result: GroupInput = {
    name: '',
    description: '',
    parentGroupExternalId: parentGroupExternalId || '',
    permissions: [],
    groupType: GroupTypeEnum.Custom,
  };

  if (group) {
    result.externalId = group.externalId;
    result.name = group.name;
    result.description = group.description;
    result.parentGroupExternalId = group.parentGroupExternalId;
    result.permissions = group.permissions;
    result.groupType = group.groupType;
  }

  return result;
};

const useStyles = makeStyles((theme) => ({
  content: {
    width: 600,
  },
  inputField: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

const customerGroupTypeOptions = [
  { value: GroupTypeEnum.Custom, label: 'Custom' },
  { value: GroupTypeEnum.AcademyBasic, label: '[Academy] Basic' },
  { value: GroupTypeEnum.AcademyStingrayOnline, label: '[Academy] Stingray Online' },
  { value: GroupTypeEnum.AcademyNavigator, label: '[Academy] Navigator' },
  { value: GroupTypeEnum.AcademyFishHealth, label: '[Academy] Fish health' },
  { value: GroupTypeEnum.StingrayOnlineBasic, label: '[Stingray Online] Basic' },
  { value: GroupTypeEnum.StingrayOnlineFishHealth, label: '[Stingray Online] Fish health' },
  { value: GroupTypeEnum.Navigator, label: '[Stingray Online] Navigator' },
];

const GroupEditDialog = (props: GroupEditDialogProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { getAccessTokenSilently } = useAuth0();

  const {
    group, groupType, parentGroupExternalId, onClose,
  } = props;

  const customers = useSelector(customerSelectors.selectAll);
  const parentGroups = useSelector(parentGroupSelectors.selectAll);
  const relevantParentGroups = useMemo(() => {
    let result = parentGroups;
    if (groupType === GroupFamilyEnum.NonCustomerGroup) {
      result = result.filter((pg) => !pg.companyCode);
    } else if (groupType === GroupFamilyEnum.CustomerGroup) {
      result = result.filter((pg) => pg.externalId === parentGroupExternalId);
    }

    return result;
  }, [parentGroupExternalId, parentGroups, groupType]);

  const customerLocations = useMemo(() => {
    if (!parentGroupExternalId) {
      return [];
    }

    const parentGroup = relevantParentGroups.find((pg) => pg.externalId === parentGroupExternalId);
    const customer = customers.find((c) => c.name === parentGroup?.name);
    return customer?.locations || [];
  }, [customers, parentGroupExternalId, relevantParentGroups]);

  const mode: DialogMode = group ? 'edit' : 'create';
  const title = useMemo(
    () => (mode === 'edit' ? 'Edit group' : 'Create group'),
    [mode],
  );

  const [templateLocation, setTemplateLocation] = useState<Location | null>(null);
  const [inputs, setInputs] = useState(getDefaultInputs(group, parentGroupExternalId));

  const handleInputChange = (k: keyof (GroupInput), value: string) => {
    setInputs((p) => ({
      ...p,
      [k]: value,
    }));
  };

  const inputsAreSet = useMemo(
    () => {
      if (inputs.groupType !== GroupTypeEnum.Custom) {
        return !!templateLocation?.code && inputs.parentGroupExternalId.length;
      }
      return inputs.name.length && inputs.description.length && inputs.parentGroupExternalId.length;
    },
    [inputs, templateLocation],
  );

  const [saving, setSaving] = useState(false);

  const handleApproved = async () => {
    if (saving || !inputsAreSet) {
      return;
    }

    setSaving(true);

    try {
      const accessToken = await getAccessTokenSilently();
      if (mode === 'create') {
        if (inputs.groupType === GroupTypeEnum.Custom) {
          await dispatch(createGroup(inputs, accessToken));
        } else {
          const templateInputs: GroupTemplateInput = {
            groupType: inputs.groupType,
            locationCode: templateLocation!.code,
            parentGroupExternalId: inputs.parentGroupExternalId,
          };
          await dispatch(createGroupFromTemplate(templateInputs, accessToken));
        }
      } else if (mode === 'edit') {
        await dispatch(updateGroup(inputs, accessToken));
      }
    } finally {
      setSaving(false);
    }

    onClose();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && e.ctrlKey) {
      e.preventDefault();
      handleApproved();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      onClose();
    }
  };

  const autoCompleteValue = useMemo(
    () => relevantParentGroups.find((rg) => rg.externalId === inputs.parentGroupExternalId) || null,
    [inputs.parentGroupExternalId, relevantParentGroups],
  );

  return (
    <Dialog onClose={onClose} open maxWidth="lg" onKeyDown={handleKeyDown}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.content}>
        <Box display="flex" flexDirection="column">
          <Autocomplete
            disabled={!!parentGroupExternalId}
            options={relevantParentGroups}
            getOptionLabel={(option) => option.name}
            fullWidth
            value={autoCompleteValue}
            onChange={
              (_, value: ParentGroup | null) => handleInputChange('parentGroupExternalId', value?.externalId || '')
            }
            // eslint-disable-next-line react/jsx-props-no-spreading
            PaperComponent={(paperProps) => <Paper {...paperProps} elevation={8} />}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                autoFocus={!parentGroupExternalId}
                label="Parent group"
                variant="outlined"
                className={classes.inputField}
              />
            )}
          />
          {!!parentGroupExternalId && (
            <FormControl variant="outlined" disabled={mode !== 'create'} className={classes.inputField}>
              <InputLabel id="group-type-label">Type</InputLabel>
              <Select
                labelId="group-type-label"
                value={inputs.groupType}
                onChange={(e) => handleInputChange('groupType', e.target.value as GroupTypeEnum)}
                label="Type"
              >
                {customerGroupTypeOptions.map((i) => <MenuItem key={i.value} value={i.value}>{i.label}</MenuItem>)}
              </Select>
            </FormControl>
          )}
          {inputs.groupType !== GroupTypeEnum.Custom && (
            <Autocomplete
              fullWidth
              options={customerLocations}
              getOptionLabel={(o) => o.name}
              value={templateLocation}
              onChange={(e, v) => setTemplateLocation(v)}
              // eslint-disable-next-line react/jsx-props-no-spreading
              PaperComponent={(paperProps) => <Paper {...paperProps} elevation={8} />}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="Location"
                  variant="outlined"
                  className={classes.inputField}
                />
              )}
            />
          )}
          {inputs.groupType === GroupTypeEnum.Custom && (
            <>
              <TextField
                className={classes.inputField}
                label="Name"
                fullWidth
                variant="outlined"
                value={inputs.name}
                onChange={(e) => handleInputChange('name', e.target.value)}
              />
              <TextField
                className={classes.inputField}
                label="Description"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                value={inputs.description}
                onChange={(e) => handleInputChange('description', e.target.value)}
              />
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button disabled={!inputsAreSet || saving} onClick={handleApproved} color="primary">
          {mode === 'edit' ? 'Save' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(GroupEditDialog);
