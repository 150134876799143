import {
  get, post, put, del,
} from 'core/communication';
import appSettings from 'app/appSettings';
import {
  Group, ParentGroup, ParentGroupInput, GroupInput,
  GroupTemplateInput,
} from './types';

const BASE_URL = appSettings.apis.authService.url;

export async function getGroups(accessToken: string): Promise<Array<Group>> {
  const url = `${BASE_URL}/groups`;
  const response = await get<Array<Group>>(url, accessToken);
  return response;
}

export async function createGroup(groupIn: GroupInput, accessToken: string): Promise<Group> {
  const url = `${BASE_URL}/groups`;
  const response = await post<GroupInput, Group>(url, groupIn, accessToken);
  return response;
}

export async function createGroupFromTemplate(groupIn: GroupTemplateInput, accessToken: string): Promise<Group> {
  const url = `${BASE_URL}/groups/locationpredefined`;
  const response = await post<GroupTemplateInput, Group>(url, groupIn, accessToken);
  return response;
}

export async function updateGroup(groupIn: GroupInput, accessToken: string): Promise<Group> {
  const url = `${BASE_URL}/groups`;
  const response = await put<GroupInput, Group>(url, groupIn, accessToken);
  return response;
}

export async function deleteGroup(externalId: string, accessToken: string): Promise<void> {
  const url = `${BASE_URL}/groups`;
  await del(url, accessToken, { externalId });
}

export async function getParentGroups(accessToken: string): Promise<Array<ParentGroup>> {
  const url = `${BASE_URL}/parentGroups`;
  const response = await get<Array<ParentGroup>>(url, accessToken);
  return response;
}

export async function createParentGroup(parentGroupIn: ParentGroupInput, accessToken: string): Promise<ParentGroup> {
  const url = `${BASE_URL}/parentGroups`;
  const response = await post<ParentGroupInput, ParentGroup>(url, parentGroupIn, accessToken);
  return response;
}

export async function updateParentGroup(parentGroupIn: ParentGroupInput, accessToken: string): Promise<ParentGroup> {
  const url = `${BASE_URL}/parentGroups`;
  const response = await put<ParentGroupInput, ParentGroup>(url, parentGroupIn, accessToken);
  return response;
}

export async function deleteParentGroup(externalId: string, accessToken: string): Promise<void> {
  const url = `${BASE_URL}/parentGroups`;
  await del(url, accessToken, { externalId });
}
