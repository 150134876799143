/* eslint-disable import/prefer-default-export */
import {
  CountryEnum, LanguageEnum, User, UserInput, UserTypeEnum,
} from 'features/users/types';

export const getDefaultUserInputs = (
  userType: UserTypeEnum,
  user?: User,
  addPermissions: boolean = true,
  companyCode?: number,
) => {
  let result: UserInput = {
    email: '',
    name: '',
    phone: '',
    userType,
    country: CountryEnum.Norway,
    language: LanguageEnum.nb,
    address: '',
    mfaRequired: true,
    enabled: true,
    companyCodes: companyCode ? [companyCode] : [],
    groupExternalIds: [],
    userSpecificPermissions: [],
  };

  // edit mode, user exists already
  // let's copy fields
  if (user) {
    result = {
      externalId: user.externalId,
      email: user.email,
      name: user.name,
      phone: user.phone,
      userType: user.userType,
      country: user.country,
      language: user.language,
      address: user.address,
      mfaRequired: user.mfaRequired,
      enabled: user.enabled,
      companyCodes: user.companyCodes,
      groupExternalIds: user.groups.map((g) => g.externalId),
      userSpecificPermissions: addPermissions ? user.userSpecificPermissions : [],
    };
  }

  return result;
};
