import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Paper, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import appSettings from 'app/appSettings';
import { routeMap } from 'features/layout/Navigation';

import { userSelectors } from './usersSlice';
import { User, UserTypeEnum } from './types';

type UserSearchProps = {
  companyCode?: number;
  searchUserType?: UserTypeEnum;
  selectedUser?: User | null,
  setUser: (user: User | null) => void;
};

// eslint-disable-next-line max-len
const checkIfTestUserEmail = (testUser: User, user: User): boolean => testUser.email === user.email.replace('@', `+${appSettings.testusers.emailSuffix}@`);

const UserSearch = ({
  companyCode, searchUserType, selectedUser, setUser,
}: UserSearchProps) => {
  const users = useSelector(userSelectors.selectAll);
  const testUsers = useMemo(() => users.filter((u) => u.userType === UserTypeEnum.Test), [users]);
  const searchableUsers = useMemo(() => (
    companyCode
      ? users.filter((u) => u.companyCodes.indexOf(companyCode) !== -1)
      : users.filter((u) => (searchUserType ? u.userType === searchUserType : true))
  ), [users, companyCode, searchUserType]);

  return (
    <Autocomplete
      id="search-user"
      fullWidth
      options={searchableUsers}
      getOptionLabel={(option) => `${option.name}`}
      onChange={(event: object, value: User | null) => { setUser(value); }}
      getOptionDisabled={(option) => testUsers.some((tu) => checkIfTestUserEmail(tu, option))}
      // eslint-disable-next-line react/jsx-props-no-spreading
      PaperComponent={(paperProps) => <Paper {...paperProps} elevation={8} />}
      value={selectedUser}
      getOptionSelected={(option: User, value: User) => option.externalId === value?.externalId}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label={`Search ${searchUserType} user`}
          id="search-user-input"
          variant="outlined"
        />
      )}
      renderOption={(option: User) => {
        if (testUsers.some((tu) => checkIfTestUserEmail(tu, option))) {
          return (
            <Typography
              color="secondary"
              variant="subtitle1"
              noWrap
              key={option.externalId}
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <span title="Has test user"><routeMap.testusers.icon /></span>
              <span>{option.name}</span>
            </Typography>
          );
        }
        return (
          <Typography
            noWrap
            style={{ textDecoration: !option.enabled ? 'line-through' : 'none' }}
          >
            {option.name}
          </Typography>
        );
      }}
    />
  );
};

export default UserSearch;
