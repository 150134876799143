import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { uniqueId } from 'lodash';
import { customerSelectors } from './fleetSlice';
import { Customer } from './types';

type CustomerSelectSingleProps = {
  companyCode: number,
  disabled?: boolean
  onChange: (value: number) => void,
};

const CustomerSelectSingle = (props: CustomerSelectSingleProps) => {
  const { companyCode, disabled, onChange } = props;
  const customers = useSelector(customerSelectors.selectAll);
  const customerLookup = useSelector(customerSelectors.selectEntities);
  const [inputId] = useState(() => uniqueId('search-customer-'));

  const handleChange = (_: any, value: Customer | null) => {
    onChange(value?.code ?? 0);
  };

  const selectedOption = useMemo(() => customerLookup[companyCode] ?? null, [customerLookup, companyCode]);

  return (
    <Autocomplete
      options={customers}
      id={`${inputId}`}
      getOptionLabel={(option) => option.name}
      fullWidth
      disabled={disabled}
      value={selectedOption}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label="Customer"
          variant="outlined"
          id={`${inputId}-input`}
        />
      )}
    />
  );
};

export default React.memo(CustomerSelectSingle);
