import React from 'react';
import { Group } from 'features/groups/types';
import { Resource } from 'features/resources/types';
import { Box, Grid } from '@material-ui/core';
import PermissionSwitch from 'features/common/PermissionSwitch';
import { LocalityTypeEnum } from 'features/common/types';

type ResourceGroupPermissionDetailsProps = {
  group: Group,
  resources: Array<Resource>,
};

const ResourceGroupPermissionDetails = (props: ResourceGroupPermissionDetailsProps) => {
  const { group, resources } = props;

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        {resources.map((r) => (
          <Grid item xl={3} key={r.externalId}>
            <PermissionSwitch
              localityType={r.localitySpecific ? LocalityTypeEnum.Global : LocalityTypeEnum.NotApplicable}
              localityCode={null}
              group={group}
              resource={r}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default React.memo(ResourceGroupPermissionDetails);
