import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { LinearProgress } from '@material-ui/core';

type ConfirmDialogProps = {
  open: boolean,
  title: string,
  dialogText: string,
  acceptButtonText: string,
  cancelButtonText: string,
  loading?: boolean,
  onCancel: () => void,
  onAccept: () => void,
};

const ConfirmDialog = (props: ConfirmDialogProps) => {
  const {
    title, dialogText, open, onCancel, onAccept, acceptButtonText, cancelButtonText, loading,
  } = props;
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth="sm"
    >
      {loading && (<LinearProgress />)}
      <DialogTitle color="inherit">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {dialogText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="inherit">
          {cancelButtonText}
        </Button>
        <Button onClick={onAccept} color="primary" autoFocus>
          {acceptButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
