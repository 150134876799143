import AppSettingsTemplate, { EnvironmentEnum } from './appSettings.template';

const productionAppSettings: AppSettingsTemplate = {
  auth0: {
    domain: 'stingray.eu.auth0.com',
    clientId: 'G4JDnm4KemzVmT7iSlGPE0dV5A2xb03X',
    audience: 'https://stingrayapis.skywater.io/',
    scope: `Read:AuthServiceResources
            ReadWrite:AuthServiceGroups
            ReadWrite:AuthServiceUsers
            ReadWrite:AuthServiceLocationUsers
            Read:EquipmentPlacementFleet`,
  },
  apis: {
    authService: {
      url: 'https://clientapi.lc.stingray.no/api/authservice',
    },
    stingrayApps: {
      url: 'https://hq.skywater.io/webapp-registry/api/v1.0',
    },
    hqApi: {
      url: 'https://equipmentplacement-api.skywater.io/api/v1/p1api',
    },
  },
  testusers: {
    emailSuffix: 'auth0test',
  },
  authAdmin2Url: 'https://authadmin2.stingray.local',
  environmnet: EnvironmentEnum.production,
};

export default productionAppSettings;
