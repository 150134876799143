import {
  Box, Button, Card, CardActions, CardContent, CardHeader, Grid, Paper, TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { accessRightSelectors } from 'features/accessRights/accessRightsSlice';
import { enqueueNotification } from 'features/notifications/notificationsSlice';
import { resourceSelectors } from 'features/resources/resourcesSlice';
import React, { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

const TokenGenerator = () => {
  const { getAccessTokenWithPopup } = useAuth0();
  const dispatch = useDispatch();
  const resources = useSelector(resourceSelectors.selectAll);
  const accessRights = useSelector(accessRightSelectors.selectAll);

  const scopeOptions = useMemo(() => {
    const result: Array<string> = [];
    resources.forEach((r) => {
      accessRights.forEach((ar) => {
        result.push(`${ar.name}:${r.name}`);
      });
    });

    return result;
  }, [resources, accessRights]);

  const [scopes, setScopes] = useState<Array<string>>([]);
  const [accessToken, setAccessToken] = useState('');
  const parsedAccessToken = useMemo(() => {
    if (!accessToken.length) {
      return '';
    }

    try {
      const json = JSON.parse(atob(accessToken.split('.')[1]));
      return JSON.stringify(json, null, 2);
    } catch (err) {
      return `Failed to parse access token. Error: ${err}`;
    }
  }, [accessToken]);

  const handleGenerateTokenPushed = async () => {
    try {
      const token = await getAccessTokenWithPopup({
        scope: scopes.join(' '),
      });
      if (token) {
        setAccessToken(token);
      }
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error('Could not load access token', ex);
    }
  };

  const inputRef = useRef<null | HTMLInputElement>(null);

  const handleCopy = () => {
    if (!inputRef.current || !inputRef.current.select) {
      return;
    }

    inputRef.current.select();
    inputRef.current.setSelectionRange(0, 99999); // For mobile devices
    document.execCommand('copy');

    dispatch(enqueueNotification({
      key: `access_token_copy_${Date.now()}`,
      title: '',
      text: 'Access token has been copied to clipboard',
      variant: 'success',
    }));
  };

  return (
    <Box display="flex" flexDirection="column">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Scopes" />
            <CardContent>
              <Autocomplete
                options={scopeOptions}
                value={scopes}
                onChange={(_, value) => setScopes(value)}
                multiple
                disableCloseOnSelect
                // eslint-disable-next-line react/jsx-props-no-spreading
                PaperComponent={(paperProps) => <Paper {...paperProps} elevation={8} />}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    variant="outlined"
                  />
                )}
              />
            </CardContent>
            <CardActions>
              <Button variant="contained" color="primary" onClick={handleGenerateTokenPushed}>
                Generate token
              </Button>
            </CardActions>
          </Card>
        </Grid>
        {accessToken.length > 0 && (
          <>
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Access token" />
                <CardContent>
                  <TextField
                    inputRef={inputRef}
                    value={accessToken}
                    InputProps={{
                      readOnly: true,
                    }}
                    multiline
                    variant="outlined"
                    fullWidth
                  />
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCopy}
                    disabled={!accessToken.length}
                  >
                    Copy
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Decoded" />
                <CardContent>
                  <pre>{parsedAccessToken}</pre>
                </CardContent>
              </Card>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default React.memo(TokenGenerator);
