import React, { useMemo, useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, TextField, Select,
  Grid, makeStyles, InputLabel, FormControl, MenuItem, FormControlLabel, Checkbox, LinearProgress,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import GroupSelect from 'features/groups/GroupSelect';
import CustomerSelect from 'features/fleet/CustomerSelect';
import { groupSelectors } from 'features/groups/groupsSlice';
import { parentGroupSelectors } from 'features/groups/parentGroupsSlice';
import {
  User, UserInput, CountryEnum, LanguageEnum, UserTypeEnum,
} from './types';
import { createUser, updateUser } from './usersSlice';

export type UserEditDialogProps = {
  onClose: () => void,
  user?: User,
  userType: UserTypeEnum,
  companyCode?: number,
  hideGroups?: boolean,
  hideCustomers?: boolean,
  hideProfile?: boolean,
};

const emailRegex = new RegExp(/\S+@\S+\.\S+/);

type DialogMode = 'edit' | 'create';

const getDefaultInputs = (userType: UserTypeEnum, companyCode?: number, user?: User) => {
  let result: UserInput = {
    email: '',
    name: '',
    phone: '',
    userType,
    country: CountryEnum.Norway,
    language: LanguageEnum.nb,
    address: '',
    mfaRequired: true,
    enabled: true,
    companyCodes: companyCode ? [companyCode] : [],
    groupExternalIds: [],
  };

  // edit mode, user exists already
  // let's copy fields
  if (user) {
    result = {
      externalId: user.externalId,
      email: user.email,
      name: user.name,
      phone: user.phone,
      userType: user.userType,
      country: user.country,
      language: user.language,
      address: user.address,
      mfaRequired: user.mfaRequired,
      enabled: user.enabled,
      companyCodes: user.companyCodes,
      groupExternalIds: user.groups.map((g) => g.externalId),
    };
  }

  return result;
};

const useStyles = makeStyles((theme) => ({
  content: {
    width: theme.breakpoints.values.sm,
  },
}));

const UserEditDialog = (props: UserEditDialogProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const groupLookup = useSelector(groupSelectors.selectEntities);
  const parentGroupLookup = useSelector(parentGroupSelectors.selectEntities);

  const { getAccessTokenSilently } = useAuth0();

  const {
    user, onClose, userType, hideCustomers, hideGroups, hideProfile, companyCode,
  } = props;

  const mode: DialogMode = user ? 'edit' : 'create';
  const title = useMemo(
    () => (mode === 'edit' ? 'Edit user' : 'Create user'),
    [mode],
  );

  const [inputs, setInputs] = useState(getDefaultInputs(userType, companyCode, user));
  const handleInputChange = (
    k: keyof (UserInput),
    value: string | Array<number> | Array<string> | boolean | UserTypeEnum,
  ) => {
    setInputs((p) => ({
      ...p,
      [k]: value,
    }));
  };

  const inputsAreSet = useMemo(() => {
    const emailValid = emailRegex.test(inputs.email);

    if (!inputs.name.length) {
      return false;
    }

    // email must be valid (exception: MachineUsers)
    if (userType !== UserTypeEnum.Machine && !emailValid) {
      return false;
    }

    // customer users must be linked to a customer...
    if (inputs.userType === UserTypeEnum.Customer && inputs.companyCodes.length < 1) {
      return false;
    }

    return true;
  }, [inputs, userType]);

  const [saving, setSaving] = useState(false);

  const handleApproved = async () => {
    if (saving || !inputsAreSet) {
      return;
    }

    // removing customer groups when customer is removed from user
    const validGroupsExternalIds = inputs.groupExternalIds.filter((gid) => {
      const group = groupLookup[gid];
      if (!group) {
        return false;
      }

      const parentGroup = parentGroupLookup[group?.parentGroupExternalId];
      return !parentGroup?.companyCode || inputs.companyCodes.includes(parentGroup.companyCode);
    });

    const updatedInputs: UserInput = {
      ...inputs,
      groupExternalIds: validGroupsExternalIds,
    };

    setSaving(true);

    try {
      const accessToken = await getAccessTokenSilently();
      if (mode === 'create') {
        await dispatch(createUser(updatedInputs, accessToken));
      } else if (mode === 'edit') {
        await dispatch(updateUser(updatedInputs, accessToken));
      }
    } finally {
      setSaving(false);
    }

    onClose();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && e.ctrlKey) {
      e.preventDefault();
      handleApproved();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      onClose();
    }
  };

  return (
    <Dialog onClose={onClose} open maxWidth="sm" onKeyDown={handleKeyDown}>
      {saving && (<LinearProgress />)}
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container spacing={1}>
          {!hideProfile && (
            <>
              <Grid item sm={userType === UserTypeEnum.Machine ? 12 : 6}>
                <TextField
                  autoFocus
                  autoComplete="new-password"
                  label="Name"
                  fullWidth
                  variant="outlined"
                  value={inputs.name}
                  onChange={(e) => handleInputChange('name', e.target.value)}
                />
              </Grid>
              {userType !== UserTypeEnum.Machine && (
                <>
                  <Grid item sm={6}>
                    <TextField
                      autoComplete="new-password"
                      label="Email"
                      fullWidth
                      variant="outlined"
                      value={inputs.email}
                      onChange={(e) => handleInputChange('email', e.target.value)}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      autoComplete="new-password"
                      label="Phone"
                      fullWidth
                      variant="outlined"
                      value={inputs.phone}
                      onChange={(e) => handleInputChange('phone', e.target.value)}
                    />
                  </Grid>
                  <Grid item sm={8}>
                    <TextField
                      label="Address"
                      autoComplete="new-password"
                      fullWidth
                      variant="outlined"
                      value={inputs.address}
                      onChange={(e) => handleInputChange('address', e.target.value)}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Country</InputLabel>
                      <Select
                        label="Country"
                        value={inputs.country}
                        onChange={(e) => handleInputChange('country', e.target.value as string)}
                      >
                        {Object.values(CountryEnum).map((country) => (
                          <MenuItem key={country} value={country}>{country}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Language</InputLabel>
                      <Select
                        label="Language"
                        value={inputs.language}
                        onChange={(e) => handleInputChange('language', e.target.value as string)}
                      >
                        {Object.values(LanguageEnum).map((lang) => <MenuItem key={lang} value={lang}>{lang}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
            </>
          )}
          {userType !== UserTypeEnum.Stingray && !hideCustomers && (
            <Grid item sm={12}>
              <CustomerSelect
                companyCodes={inputs.companyCodes}
                onChange={(v) => handleInputChange('companyCodes', v)}
              />
            </Grid>
          )}
          {!hideGroups && (
            <Grid item sm={12}>
              <GroupSelect
                user={inputs}
                externalIds={inputs.groupExternalIds}
                onChange={(v) => handleInputChange('groupExternalIds', v)}
              />
            </Grid>
          )}
          {!hideProfile && (
            <>
              <Grid item sm={3}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="primary"
                      checked={inputs.enabled}
                      onChange={(_, v) => handleInputChange('enabled', v)}
                    />
                  )}
                  label="Enabled"
                />
              </Grid>
              <Grid item sm={9}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="primary"
                      checked={inputs.mfaRequired}
                      onChange={(_, v) => handleInputChange('mfaRequired', v)}
                    />
                  )}
                  label="Multi-factor authentication"
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button disabled={!inputsAreSet || saving} onClick={handleApproved} color="primary">
          {mode === 'edit' ? 'Save' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(UserEditDialog);
