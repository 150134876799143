/* eslint-disable import/no-cycle */
import {
  configureStore, ThunkAction, Action, getDefaultMiddleware,
} from '@reduxjs/toolkit';
import fleetReducer from 'features/fleet/fleetSlice';
import accessRightsReducer from 'features/accessRights/accessRightsSlice';
import usersReducer from 'features/users/usersSlice';
import groupsReducer from 'features/groups/groupsSlice';
import parentGroupsReducer from 'features/groups/parentGroupsSlice';
import resourcesReducer from 'features/resources/resourcesSlice';
import resourceGroupsReducer from 'features/resources/resourceGroupsSlice';
import notificationReducer from 'features/notifications/notificationsSlice';
import stingrayAppsReducer from 'features/stingrayApps/stingrayAppsSlice';

const checkInReduxToolkit = ['production', 'test'].indexOf(process.env.NODE_ENV) === -1;

const store = configureStore({
  reducer: {
    accessRights: accessRightsReducer,
    users: usersReducer,
    groups: groupsReducer,
    parentGroups: parentGroupsReducer,
    resources: resourcesReducer,
    resourceGroups: resourceGroupsReducer,
    notifications: notificationReducer,
    stingrayApps: stingrayAppsReducer,
    fleet: fleetReducer,
  },
  middleware: getDefaultMiddleware({ serializableCheck: checkInReduxToolkit, immutableCheck: checkInReduxToolkit }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType, // eslint-disable-line @typescript-eslint/indent
  RootState, // eslint-disable-line @typescript-eslint/indent
  unknown, // eslint-disable-line @typescript-eslint/indent
  Action<string> // eslint-disable-line @typescript-eslint/indent
>;

export default store;
