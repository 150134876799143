/* eslint-disable @typescript-eslint/indent */
import { useDebouncedValue } from '@mantine/hooks';
import {
  Box, Dialog, DialogContent, DialogTitle, List, TextField,
} from '@material-ui/core';
import { customerSelectors } from 'features/fleet/fleetSlice';
import { User, UserTypeEnum } from 'features/users/types';
import { userSelectors } from 'features/users/usersSlice';
import { sortBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CustomerLink from './CustomerLink';
import { CustomerHit, UserHit } from './types';
import UserLink from './UserLink';

type Props = {
  onClose: () => void,
};

const GlobalSearchDialog = ({ onClose }: Props) => {
  const location = useLocation();
  const [openedPage] = useState(location.pathname);

  useEffect(() => {
    if (openedPage !== location.pathname) {
      onClose();
    }
  }, [location.pathname, onClose, openedPage]);

  const customers = useSelector(customerSelectors.selectAll);
  const customerLookup = useSelector(customerSelectors.selectEntities);
  const users = useSelector(userSelectors.selectAll);
  const [input, setInput] = useState('');
  const [debouncedLowerCaseSearchInput] = useDebouncedValue(input.toLowerCase(), 200);

  const customerHits = useMemo<Array<CustomerHit>>(() => {
    if (debouncedLowerCaseSearchInput.length < 3) {
      return [];
    }

    let result: Array<CustomerHit> = [];

    customers.forEach((c) => {
      if (c.name.toLowerCase().includes(debouncedLowerCaseSearchInput)) {
        result.push({
          customer: c.name,
          href: `/customer/${c.code}`,
        });
      }

      c.locations.forEach((l) => {
        if (l.name.toLowerCase().includes(debouncedLowerCaseSearchInput)) {
          result.push({
            customer: c.name,
            location: l.name,
            href: `/customer/${c.code}`,
          });
        }
      });
    });

    result = sortBy(result, [(c) => c.customer, (c) => c.location || '']);

    return result;
  }, [customers, debouncedLowerCaseSearchInput]);

  const userHits = useMemo<Array<UserHit>>(() => {
    if (debouncedLowerCaseSearchInput.length < 3) {
      return [];
    }

    const createHref = (user: User) => {
      switch (user.userType) {
        case UserTypeEnum.Stingray:
          return `/stingray/users/${user.externalId}`;
        case UserTypeEnum.Customer:
          return `/customer/${user.companyCodes[0]}/users/${user.externalId}`;
        case UserTypeEnum.ThirdParty:
          return `/thirdparty/${user.externalId}`;
        case UserTypeEnum.Machine:
          return `/machines/${user.externalId}`;
        case UserTypeEnum.Test:
          return `/testusers/${user.externalId}`;
        default:
          return '';
      }
    };

    let result = users.filter(
      (u) => u.name.toLowerCase().includes(debouncedLowerCaseSearchInput)
        || u.email.toLowerCase().includes(debouncedLowerCaseSearchInput)
        || u.phone?.toLowerCase()?.includes(debouncedLowerCaseSearchInput),
    ).map<UserHit>((user) => ({
      name: user.name,
      companies: user.companyCodes.map((code) => customerLookup[code]?.name || ''),
      email: user.email,
      href: createHref(user),
      phone: user.phone,
      userType: user.userType,
    }));
    result = sortBy(result, [(c) => c.name]);

    return result;
  }, [customerLookup, debouncedLowerCaseSearchInput, users]);

  return (
    <Dialog open maxWidth="xl" onClose={onClose}>
      <DialogTitle>Search</DialogTitle>
      <DialogContent>
        <Box width={600} flex flexDirection="column">
          <TextField
            helperText="min. 3 characters"
            autoFocus
            variant="outlined"
            size="small"
            fullWidth
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          <Box height={800} overflow="auto">
            <List>
              {customerHits.map((customer) => (
                <CustomerLink
                  customer={customer}
                  key={`${customer.customer}_${customer.location}`}
                />
              ))}
              {userHits.map((user) => <UserLink user={user} key={user.href} />)}
            </List>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(GlobalSearchDialog);
