import React, { useMemo } from 'react';
import {
  useRouteMatch, Route, Switch, useHistory, matchPath,
} from 'react-router-dom';
import UserList from 'features/users/UserList';
import {
  makeStyles, Container, Paper, Tabs, Tab,
} from '@material-ui/core';
import StingrayGroupList from 'features/stingray/StingrayGroupList';
import StingrayParentGroupList from 'features/stingray/StingrayParentGroupList';
import GroupDetails from 'features/groups/GroupDetails';
import { UserTypeEnum } from 'features/users/types';
import UserDetails from 'features/users/UserDetails';
import { GroupFamilyEnum } from 'features/groups/types';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
}));

export const stingrayPages = {
  users: {
    label: 'Users',
    value: 'users',
  },
  groups: {
    label: 'Groups',
    value: 'groups',
  },
  parentGroups: {
    label: 'Parent groups',
    value: 'parent-groups',
  },
};

const StingraySection = () => {
  const classes = useStyles();

  const history = useHistory();
  const { path } = useRouteMatch();

  const handleTabChanged = (value: string) => {
    history.push({
      pathname: `${path}/${value}`,
    });
  };

  const activePageTab = useMemo(() => {
    const page = Object.values(stingrayPages).find(
      (p) => matchPath(history.location.pathname, { path: `${path}/${p.value}`, exact: false }),
    );
    if (!page) {
      return stingrayPages.users.value;
    }

    return page.value;
  }, [history.location.pathname, path]);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Paper className={classes.tabs} square>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={activePageTab}
          onChange={(_, value) => handleTabChanged(value)}
        >
          <Tab value={stingrayPages.users.value} label={stingrayPages.users.label} />
          <Tab value={stingrayPages.groups.value} label={stingrayPages.groups.label} />
          <Tab value={stingrayPages.parentGroups.value} label={stingrayPages.parentGroups.label} />
        </Tabs>
      </Paper>
      <Switch>
        <Route exact path={`${path}/${stingrayPages.users.value}`}>
          <UserList userType={UserTypeEnum.Stingray} />
        </Route>
        <Route exact path={`${path}/${stingrayPages.users.value}/:userId`}>
          <UserDetails navigateBackContext={UserTypeEnum.Stingray} />
        </Route>
        <Route exact path={`${path}/${stingrayPages.groups.value}`}>
          <StingrayGroupList />
        </Route>
        <Route exact path={`${path}/${stingrayPages.groups.value}/:groupExternalId`}>
          <GroupDetails groupType={GroupFamilyEnum.NonCustomerGroup} />
        </Route>
        <Route exact path={`${path}/${stingrayPages.parentGroups.value}`}>
          <StingrayParentGroupList />
        </Route>
      </Switch>
    </Container>
  );
};

export default StingraySection;
