import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable, { MTableEditField } from 'material-table';
import ListTooltip from 'features/common/ListTooltip';
import groupBy from 'lodash/groupBy';
import { useAuth0 } from '@auth0/auth0-react';
import RelativeTimestamp from 'features/common/RelativeTimestamp';
import { Box } from '@material-ui/core';
import {
  parentGroupSelectors,
  createParentGroup, updateParentGroup, deleteParentGroup,
} from '../groups/parentGroupsSlice';
import { groupSelectors } from '../groups/groupsSlice';

type TableDataEntry = {
  externalId: string,
  name: string,
  description: string,
  groups: Array<string>,
  regTms: Date,
};

const StingrayParentGroupList = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const groups = useSelector(groupSelectors.selectAll);
  const parentGroups = useSelector(parentGroupSelectors.selectAll);

  const groupsByParentGroup = useMemo(
    () => groupBy(groups, (g) => g.parentGroupExternalId),
    [groups],
  );

  const tableData = useMemo(() => {
    const filteredParentGroups = parentGroups.filter((pg) => !pg.companyCode);
    const result = filteredParentGroups.map<TableDataEntry>((rg) => ({
      name: rg.name,
      description: rg.description,
      externalId: rg.externalId,
      groups: (groupsByParentGroup[rg.externalId] || []).map((r) => r.name).sort(),
      regTms: new Date(rg.regTms),
    }));
    return result;
  }, [parentGroups, groupsByParentGroup]);

  const handleRowAdded = async (newData: TableDataEntry) => {
    const accessToken = await getAccessTokenSilently();
    await dispatch(createParentGroup({
      companyCode: null,
      description: newData.description,
      name: newData.name,
    }, accessToken));
  };

  const handleRowUpdated = async (newData: TableDataEntry) => {
    const accessToken = await getAccessTokenSilently();
    await dispatch(updateParentGroup({
      companyCode: null,
      description: newData.description,
      name: newData.name,
      externalId: newData.externalId,
    }, accessToken));
  };

  const handleRowDeleted = async (rowData: TableDataEntry) => {
    const accessToken = await getAccessTokenSilently();
    await dispatch(deleteParentGroup(rowData.externalId, accessToken));
  };

  return (
    <MaterialTable
      data={tableData}
      columns={[
        {
          title: 'Name',
          field: 'name',
          type: 'string',
        },
        {
          title: 'Description',
          field: 'description',
          type: 'string',
          width: '50%',
        },
        {
          title: 'Groups',
          render: (rowData) => (rowData ? (<ListTooltip values={rowData.groups} />) : (null)),
        },
        {
          title: 'Registered',
          render: (rowData) => (rowData ? (<RelativeTimestamp date={rowData.regTms} />) : (null)),
        },
      ]}
      options={{
        showTitle: false,
        searchFieldAlignment: 'left',
        pageSize: 25,
        pageSizeOptions: [5, 10, 25, 50, 100],
        defaultExpanded: true,
        padding: 'dense',
        addRowPosition: 'first',
      }}
      editable={{
        onRowAdd: handleRowAdded,
        onRowUpdate: handleRowUpdated,
        onRowDelete: handleRowDeleted,
        isDeletable: (row) => !row.groups.length,
      }}
      localization={{
        body: {
          editRow: {
            deleteText: 'Are you sure you want to delete this parent group?',
          },
        },
      }}
      components={{
        // stretching input fields
        EditField: (editFieldProps) => (
          <Box
            display="flex"
            flexDirection="column"
            alignItems={editFieldProps?.columnDef?.type === 'boolean' ? 'flex-start' : 'stretch'}
          >
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <MTableEditField {...editFieldProps} />
          </Box>

        ),
      }}
    />
  );
};

export default React.memo(StingrayParentGroupList);
