import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'app/store';
import {
  Typography, Box, Grid, makeStyles,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/KeyboardArrowRight';
import NavigateBackNotFound from 'features/common/NavigateBackNotFound';
import NavigateBackHeader from 'features/common/NavigateBackHeader';
import { routeMap } from 'features/layout/Navigation';
import { stingrayPages } from 'features/stingray/StingraySection';
import { groupSelectors } from './groupsSlice';
import { parentGroupSelectors } from './parentGroupsSlice';
import { Group, ParentGroup, GroupFamilyEnum } from './types';
import GroupMembers from './GroupMembers';
import NonCustomerGroupPermissions from './nonCustomerGroups/NonCustomerGroupPermissions';
import CustomerGroupPermissions from './customerGroups/CustomerGroupPermissions';

type GroupDetailsProps = {
  groupType: GroupFamilyEnum,
};

type GroupDetailsParams = {
  groupExternalId: string,
  companyCode?: string,
};

const useStyles = makeStyles((theme) => ({
  groupName: {
    fontWeight: 'bold',
  },
  arrowIcon: {
    marginTop: theme.spacing(0.5),
  },
}));

const nonCustomerTableHeight = 'calc(100vh - 300px)';

const GroupDetails = (props: GroupDetailsProps) => {
  const classes = useStyles();
  const { groupType } = props;
  const { groupExternalId, companyCode } = useParams<GroupDetailsParams>();

  const { navigateBackUrl, navigateBackText } = useMemo(() => {
    if (groupType === GroupFamilyEnum.NonCustomerGroup) {
      return {
        navigateBackUrl: `${routeMap.stingray.baseUrl}/${stingrayPages.groups.value}`,
        navigateBackText: 'Back to Groups',
      };
    } if (groupType === GroupFamilyEnum.CustomerGroup) {
      return {
        navigateBackUrl: `${routeMap.customer.baseUrl}/${companyCode}`,
        navigateBackText: 'Back to Customer',
      };
    }

    return {
      navigateBackUrl: '',
      navigateBackText: '',
    };
  }, [companyCode, groupType]);

  const group = useSelector<RootState, Group | undefined>((state) => groupSelectors.selectById(state, groupExternalId));
  const parentGroup = useSelector<RootState, ParentGroup | undefined>(
    (state) => parentGroupSelectors.selectById(state, group?.parentGroupExternalId || ''),
  );

  if (!group || !parentGroup) {
    return (
      <NavigateBackNotFound
        navigateBackUrl={navigateBackUrl}
        navigateBackText={navigateBackText}
        notFoundText="Group not found"
      />
    );
  }

  return (
    <div>
      <NavigateBackHeader navigateBackText={navigateBackText} navigateBackUrl={navigateBackUrl}>
        <Box display="flex" alignItems="center">
          <Typography variant="h5">{parentGroup.name}</Typography>
          <ArrowForwardIcon className={classes.arrowIcon} />
          <Typography className={classes.groupName} variant="h5">{group.name}</Typography>
        </Box>
        <Typography variant="body2">{group.description}</Typography>
      </NavigateBackHeader>
      <Grid container spacing={2}>
        {/* non-customer groups: 2 columns with scrolling tables */}
        {
          groupType === GroupFamilyEnum.NonCustomerGroup && (
            <>
              <Grid item sm={6}>
                <GroupMembers
                  group={group}
                  groupType={groupType}
                  companyCode={parentGroup.companyCode}
                  minHeight={nonCustomerTableHeight}
                  maxHeight={nonCustomerTableHeight}
                />
              </Grid>
              <Grid item sm={6}>
                <NonCustomerGroupPermissions
                  minHeight={nonCustomerTableHeight}
                  maxHeight={nonCustomerTableHeight}
                  group={group}
                />
              </Grid>
            </>
          )
        }
        {/* customer groups: full-width table with paginated member list */}
        {
          groupType === GroupFamilyEnum.CustomerGroup && (
            <>
              <Grid item sm={12}>
                <GroupMembers
                  group={group}
                  groupType={groupType}
                  companyCode={parentGroup.companyCode}
                  paging
                />
              </Grid>
              <Grid item sm={12}>
                <CustomerGroupPermissions group={group} companyCode={parentGroup.companyCode || 0} />
              </Grid>
            </>
          )
        }
      </Grid>
    </div>
  );
};

export default React.memo(GroupDetails);
