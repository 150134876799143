import React from 'react';
import ReadIcon from '@material-ui/icons/Visibility';
import WriteIcon from '@material-ui/icons/Edit';
import NoAccessIcon from '@material-ui/icons/NotInterested';
import { makeStyles } from '@material-ui/core';

type PermissionCountProps = {
  read: number,
  readWrite: number,
  noAccess?: number,
  showNoAccess?: boolean,
};

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(0.5),
    fontSize: 20,
    marginBottom: -4,
  },
}));

const PermissionCount = (props: PermissionCountProps) => {
  const classes = useStyles();

  const {
    read, readWrite, noAccess, showNoAccess,
  } = props;

  return (
    <>
      <ReadIcon color={read ? 'primary' : undefined} className={classes.icon} />
      <span>{read}</span>
      <WriteIcon color={readWrite ? 'secondary' : undefined} className={classes.icon} />
      <span>{readWrite}</span>
      {!!showNoAccess && (
        <>
          <NoAccessIcon className={classes.icon} />
          <span>{noAccess}</span>
        </>
      )}
    </>
  );
};

export default React.memo(PermissionCount);
