import appSettings from 'app/appSettings';
import { get } from 'core/communication';
import { Customer } from './types';

const HQ_API_URL = appSettings.apis.hqApi.url;

// eslint-disable-next-line import/prefer-default-export
export async function getFleet(accessToken: string): Promise<Array<Customer>> {
  const query = {
    includePlacesWithoutSoCode: true,
  };
  const url = `${HQ_API_URL}/fleet`;
  const response = await get<Array<Customer>>(url, accessToken, query);
  return response;
}
