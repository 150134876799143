/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import {
  createSlice, PayloadAction, createEntityAdapter, EntityState,
} from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { FailurePayload } from 'core/types';
import * as api from './api';
import { Customer, Location } from './types';

const customersAdapter = createEntityAdapter<Customer>({
  selectId: (customer) => customer.code,
  sortComparer: (a, b) => (a.name > b.name ? 1 : -1),
});

const locationsAdapter = createEntityAdapter<Location>({
  selectId: (location) => location.code,
  sortComparer: (a, b) => (a.name > b.name ? 1 : -1),
});

type FleetState = {
  customers: EntityState<Customer>,
  locations: EntityState<Location>,
  isFetching: boolean,
  error: string | null,
};

const initialState: FleetState = {
  customers: customersAdapter.getInitialState(),
  locations: locationsAdapter.getInitialState(),
  isFetching: false,
  error: null,
};

export const fleetSlice = createSlice({
  name: 'fleet',
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.isFetching = true;
    },
    fetchFailure: (state, action: PayloadAction<FailurePayload>) => {
      state.isFetching = false;
      state.error = action.payload.text;
    },
    fetchSuccess: (state, action: PayloadAction<Array<Customer>>) => {
      const customers = action.payload;
      let locations: Array<Location> = [];
      customers.forEach((c) => {
        locations = [
          ...locations,
          ...c.locations,
        ];
      });

      customersAdapter.setAll(state.customers, customers);
      locationsAdapter.setAll(state.locations, locations);

      state.isFetching = false;
      state.error = null;
    },
  },
});

// actions
const { fetchStart, fetchFailure, fetchSuccess } = fleetSlice.actions;

// selectors
export const customerSelectors = customersAdapter.getSelectors((state: RootState) => state.fleet.customers);
export const locationSelectors = locationsAdapter.getSelectors((state: RootState) => state.fleet.locations);
export const selectFleetIsFetching = (state: RootState) => state.fleet.isFetching;
export const selectFleetError = (state: RootState) => state.fleet.error;

// action creators
export const fetchFleet = (accessToken: string): AppThunk => async (dispatch, getState) => {
  // avoid race conditions
  const isFetching = selectFleetIsFetching(getState());
  if (isFetching) {
    return;
  }

  dispatch(fetchStart());

  try {
    const fleet = await api.getFleet(accessToken);
    dispatch(fetchSuccess(fleet));
  } catch (err) {
    // trying to get error message from exception.
    const text = typeof (err as any).message === 'string' ? (err as any).message : 'Unknown error';

    dispatch(fetchFailure({
      notify: true,
      title: 'Failed to load groups',
      text,
    }));
  }
};

export default fleetSlice.reducer;
