import React, { useCallback, useMemo, useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, TextField,
  Grid, makeStyles, FormControlLabel, Checkbox, Tooltip, LinearProgress,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import appSettings from 'app/appSettings';
import { getDefaultUserInputs } from './helpers';
import {
  User, UserTypeEnum, UserInput,
} from '../users/types';
import UserSearch from '../users/UserSearch';
import useSaveUserWithoutGroup from './useSaveUserWithoutGroup';

type TestUserEditDialogProps = {
  onClose: () => void,
  user?: User,
  userType: UserTypeEnum,
  companyCode?: number,
  hideProfile?: boolean,
};

const emailRegex = new RegExp(/\S+@\S+\.\S+/);

type DialogMode = 'edit' | 'create';

const useStyles = makeStyles((theme) => ({
  content: {
    width: theme.breakpoints.values.sm,
  },
}));

const TestUserEditDialog = (props: TestUserEditDialogProps) => {
  const classes = useStyles();
  const {
    user, onClose, userType, hideProfile, companyCode,
  } = props;

  const mode: DialogMode = user ? 'edit' : 'create';
  const title = useMemo(
    () => (mode === 'edit' ? 'Edit test user' : 'Create test user'),
    [mode],
  );

  const [inputs, setInputs] = useState(getDefaultUserInputs(userType, user, true, companyCode));
  const [saving, handleSave] = useSaveUserWithoutGroup(mode, onClose);

  const handleInputChange = (
    k: keyof (UserInput),
    value: string | Array<string> | boolean | UserTypeEnum,
  ) => {
    setInputs((p) => ({
      ...p,
      [k]: value,
    }));
  };

  const inputsAreSet = useMemo(() => {
    const emailValid = emailRegex.test(inputs.email);

    if (!inputs.name.length) {
      return false;
    }

    // email must be valid (exception: MachineUsers)
    if (userType !== UserTypeEnum.Machine && !emailValid) {
      return false;
    }

    // customer users must be linked to a customer...
    if (inputs.userType === UserTypeEnum.Customer && inputs.companyCodes.length < 1) {
      return false;
    }

    return true;
  }, [inputs, userType]);

  const handleStingrayUser = useCallback((handleUser: User | null) => {
    if (handleUser && handleUser.email.indexOf('@') > -1) {
      setInputs((p) => ({
        ...p,
        email: handleUser.email.replace('@', `+${appSettings.testusers.emailSuffix}@`),
        name: `${handleUser.name} (Test)`,
        phone: handleUser.phone,
        address: handleUser.address,
        language: handleUser.language,
        country: handleUser.country,
      }));
    } else {
      setInputs((p) => ({
        ...p,
        email: '',
        name: '',
        phone: '',
        address: '',
        userSpecificPermissions: [],
      }));
    }
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && e.ctrlKey) {
      e.preventDefault();
      handleSave(inputs);
    } else if (e.key === 'Escape') {
      e.preventDefault();
      onClose();
    }
  };

  return (
    <Dialog onClose={onClose} open maxWidth="sm" onKeyDown={handleKeyDown}>
      {saving && (<LinearProgress />)}
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container spacing={1}>
          {mode === 'create' && (
            <>
              <Grid item sm={12}>
                <UserSearch
                  searchUserType={UserTypeEnum.Stingray}
                  setUser={handleStingrayUser}
                />
              </Grid>
              <Grid item sm={11}>
                <TextField
                  autoComplete="testuser select-user"
                  label="Email"
                  fullWidth
                  variant="outlined"
                  placeholder="Select user above"
                  disabled
                  value={inputs.email}
                />
              </Grid>
              <Grid item sm={1}>
                <Tooltip
                  // eslint-disable-next-line max-len
                  title="GMail-accounts supports email-alias with '+' after original e-mail. Please make sure the alias is valid for other email-providers"
                >
                  <InfoIcon />
                </Tooltip>
              </Grid>
            </>
          )}
          {mode === 'edit' && (
            <>
              <Grid item sm={6}>
                <TextField
                  value={inputs.name}
                  disabled
                  label="Name"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  value={inputs.email}
                  disabled
                  label="Email"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  autoComplete="new-password"
                  label="Phone"
                  name="phone"
                  id="phone"
                  fullWidth
                  variant="outlined"
                  value={inputs.phone}
                  onChange={(e) => handleInputChange('phone', e.target.value)}
                />
              </Grid>
              <Grid item sm={8}>
                <TextField
                  label="Address"
                  autoComplete="testuser street-address"
                  fullWidth
                  name="address"
                  variant="outlined"
                  value={inputs.address}
                  onChange={(e) => handleInputChange('address', e.target.value)}
                />
              </Grid>
            </>
          )}
          {!hideProfile && (
            <>
              <Grid item sm={3}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="primary"
                      checked={inputs.enabled}
                      onChange={(_, v) => handleInputChange('enabled', v)}
                    />
                  )}
                  label="Enabled"
                />
              </Grid>
              <Grid item sm={9}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="primary"
                      checked={inputs.mfaRequired}
                      onChange={(_, v) => handleInputChange('mfaRequired', v)}
                    />
                  )}
                  label="Multi-factor authentication"
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button disabled={!inputsAreSet || saving} onClick={() => { handleSave(inputs); }} color="primary">
          {mode === 'edit' ? 'Save' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(TestUserEditDialog);
