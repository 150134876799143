import React, {
  useMemo, useState, useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import { accessRightSelectors } from 'features/accessRights/accessRightsSlice';
import { resourceSelectors } from 'features/resources/resourcesSlice';
import { resourceGroupSelectors } from 'features/resources/resourceGroupsSlice';
import { Group } from 'features/groups/types';
import { Box, makeStyles } from '@material-ui/core';
import ReadIcon from '@material-ui/icons/Visibility';
import WriteIcon from '@material-ui/icons/Edit';
import NoAccessIcon from '@material-ui/icons/NotInterested';
import { groupBy, Dictionary } from 'lodash';
import { AccessRightName } from 'features/accessRights/types';
import { Resource } from 'features/resources/types';
import { grey } from '@material-ui/core/colors';
import { RootState } from 'app/store';
import { groupSelectors } from 'features/groups/groupsSlice';
import ResourceGroupPermissionDetails from './ResourceGroupPermissionDetails';

type TableDataEntry = {
  resourceGroupName: string,
  resourceGroupExternalId: string,
  noAccessCount: number,
  readCount: number,
  readWriteCount: number,
  tableData: any,
};

type NonCustomerGroupPermissionsProps = {
  group: Group,
  minHeight?: string,
  maxHeight?: string,
};

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 16,
    marginRight: theme.spacing(0.5),
  },
  readHighlight: {
    color: theme.palette.primary.light,
    fontWeight: 500,
    fontSize: 16,
  },
  writeHighlight: {
    color: theme.palette.secondary.light,
    fontWeight: 500,
    fontSize: 16,
  },
  noAccessHighlight: {
    color: 'white',
    fontWeight: 500,
    fontSize: 16,
  },
  zeroCount: {
    color: grey[400],
  },
}));

const NonCustomerGroupPermissions = (props: NonCustomerGroupPermissionsProps) => {
  const classes = useStyles();
  const { group, minHeight, maxHeight } = props;

  const resources = useSelector(resourceSelectors.selectAll);
  const resourceGroups = useSelector(resourceGroupSelectors.selectAll);
  const resourcesByResourceGroup = useMemo<Dictionary<Array<Resource> | undefined>>(
    () => groupBy(resources, (r) => r.resourceGroupExternalId),
    [resources],
  );

  const accessRightsLookup = useSelector(accessRightSelectors.selectEntities);
  const groupResourceAccessLookup = useMemo(() => {
    const now = new Date();

    const result: Dictionary<AccessRightName | undefined> = {};
    group.permissions.forEach((p) => {
      if (p.validTo && new Date(p.validTo) <= now) {
        return;
      }

      const accessRight = accessRightsLookup[p.accessRightExternalId];
      if (accessRight) {
        result[p.resourceExternalId] = accessRight.name;
      }
    });
    return result;
  }, [accessRightsLookup, group.permissions]);

  const tableData = useMemo(() => {
    const result = resourceGroups.map((rg) => {
      const groupStats: TableDataEntry = {
        resourceGroupExternalId: rg.externalId,
        resourceGroupName: rg.name,
        readCount: 0,
        readWriteCount: 0,
        noAccessCount: 0,
        tableData: {},
      };

      // update read/readWrite/noAccess fields
      resourcesByResourceGroup[rg.externalId]?.forEach((resource) => {
        const resourceAccess = groupResourceAccessLookup[resource.externalId];
        if (!resourceAccess) {
          groupStats.noAccessCount += 1;
        } else if (resourceAccess === AccessRightName.Read) {
          groupStats.readCount += 1;
        } else if (resourceAccess === AccessRightName.ReadWrite) {
          groupStats.readWriteCount += 1;
        }
      });

      return groupStats;
    });

    return result;
  }, [groupResourceAccessLookup, resourceGroups, resourcesByResourceGroup]);

  // controlling opened rows

  // initially open groups where there's at least permission set
  const [openResourceGroupIds, setOpenResourceGroupIds] = useState(
    tableData.filter((tr) => tr.readCount + tr.readWriteCount > 0).map((tr) => tr.resourceGroupExternalId),
  );

  const detailPanelRenderFunction = useCallback((rowData: TableDataEntry) => (
    <ResourceGroupPermissionDetails
      group={group}
      resources={resourcesByResourceGroup[rowData.resourceGroupExternalId] || []}
    />
  ), [group, resourcesByResourceGroup]);

  // give showDetailPanel render function to the rows marked as 'open' above
  const controlledTableData = useMemo(() => {
    const result = tableData.map((row) => ({
      ...row,
      tableData: {
        ...row.tableData,
        showDetailPanel: openResourceGroupIds.includes(row.resourceGroupExternalId) ? detailPanelRenderFunction : null,
      },
    }));
    return result;
  }, [detailPanelRenderFunction, openResourceGroupIds, tableData]);

  // handling row toggle
  const handleRowToggle = (rowData: TableDataEntry | undefined) => {
    if (!rowData) {
      return;
    }

    if (openResourceGroupIds.includes(rowData.resourceGroupExternalId)) {
      setOpenResourceGroupIds((p) => p.filter((id) => id !== rowData.resourceGroupExternalId));
    } else {
      setOpenResourceGroupIds((p) => ([...p, rowData.resourceGroupExternalId]));
    }
  };

  const isGroupUpdating = useSelector((state: RootState) => groupSelectors.selectUpdatingById(state, group.externalId));

  return (
    <MaterialTable
      isLoading={isGroupUpdating}
      title="Permissions"
      data={controlledTableData}
      columns={[
        {
          title: 'Resource group',
          field: 'resourceGroupName',
        },
        {
          title: 'Read',
          render: (rowData) => (
            <Box
              display="flex"
              alignItems="center"
              className={rowData.readCount ? classes.readHighlight : classes.zeroCount}
            >
              <ReadIcon className={classes.icon} />
              {rowData.readCount}
            </Box>
          ),
        },
        {
          title: 'ReadWrite',
          render: (rowData) => (
            <Box
              display="flex"
              alignItems="center"
              className={rowData.readWriteCount ? classes.writeHighlight : classes.zeroCount}
            >
              <WriteIcon className={classes.icon} />
              {rowData.readWriteCount}
            </Box>
          ),
        },
        {
          title: 'No access',
          render: (rowData) => (
            <Box
              display="flex"
              alignItems="center"
              className={rowData.noAccessCount ? classes.noAccessHighlight : classes.zeroCount}
            >
              <NoAccessIcon className={classes.icon} />
              {rowData.noAccessCount}
            </Box>
          ),
        },
        {
          title: '',
          render: () => <Box width="100%" />,
        },
      ]}
      options={{
        paging: false,
        padding: 'dense',
        maxBodyHeight: maxHeight,
        minBodyHeight: minHeight,
      }}
      localization={{ header: { actions: '' } }}
      actions={[
        (rowData) => ({
          icon: openResourceGroupIds.includes(rowData.resourceGroupExternalId)
            ? 'keyboard_arrow_down'
            : 'keyboard_arrow_right',
          onClick: () => handleRowToggle(rowData),
        }),
      ]}
      onRowClick={(_, rowData) => handleRowToggle(rowData)}
    />
  );
};

export default React.memo(NonCustomerGroupPermissions);
