import React, { useMemo, useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Box, Button, TextField, Paper,
  makeStyles,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { userSelectors, addUserToCustomer } from 'features/users/usersSlice';
import { UserTypeEnum, User } from 'features/users/types';

export type ImportUserDialogProps = {
  onClose: () => void,
  companyCode: number,
};

const useStyles = makeStyles(() => ({
  content: {
    width: 600,
  },
}));

const getOptionLabel = (user: User) => {
  const {
    name, email, userType, enabled,
  } = user;
  const parts = [name, `(${email})`];

  if (userType === UserTypeEnum.Machine || userType === UserTypeEnum.ThirdParty) {
    parts.push(`[${userType}]`);
  }

  if (!enabled) {
    parts.push('[DISABLED]');
  }

  return parts.join(' ');
};

// dialog to add user to a customer user
const ImportUserDialog = (props: ImportUserDialogProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { getAccessTokenSilently } = useAuth0();

  const { companyCode, onClose } = props;

  const users = useSelector(userSelectors.selectAll);

  // relevant users are Customer / 3rd party / Machine users that are not member of the customer already
  const relevantUsers = useMemo(
    () => users.filter((u) => u.userType !== UserTypeEnum.Stingray && !u.companyCodes.includes(companyCode)),
    [companyCode, users],
  );

  const [usersToAdd, setUsersToAdd] = useState<Array<User>>([]);

  const [saving, setSaving] = useState(false);

  const handleApproved = async () => {
    if (saving || !usersToAdd.length) {
      return;
    }

    setSaving(true);

    try {
      const accessToken = await getAccessTokenSilently();
      const tasks = usersToAdd.map((u) => dispatch(addUserToCustomer(u.externalId, companyCode, accessToken)));
      await Promise.all(tasks);
    } finally {
      setSaving(false);
    }

    onClose();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && e.ctrlKey) {
      e.preventDefault();
      handleApproved();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      onClose();
    }
  };

  return (
    <Dialog onClose={onClose} open maxWidth="lg" onKeyDown={handleKeyDown}>
      <DialogTitle>Import users</DialogTitle>
      <DialogContent className={classes.content}>
        <Box display="flex" flexDirection="column">
          <Autocomplete
            multiple
            options={relevantUsers}
            getOptionLabel={getOptionLabel}
            fullWidth
            value={usersToAdd}
            onChange={
              (_, value: Array<User>) => setUsersToAdd(value)
            }
            // eslint-disable-next-line react/jsx-props-no-spreading
            PaperComponent={(paperProps) => <Paper {...paperProps} elevation={8} />}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                autoFocus
                label="Users"
                variant="outlined"
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button disabled={usersToAdd.length <= 0 || saving} onClick={handleApproved} color="primary">
          Add users to customer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(ImportUserDialog);
